import React, {createContext} from 'react';
import {GoogleService} from '../services/GoogleService';
import {ServiceProvider} from '../services/ServiceProvider';

export const ServiceContext = createContext({})

const MapServices = ({children}) => {
  const services = {
    google: new GoogleService()
  };
  ServiceProvider.setServices(services);
  return (
    <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>
  );
};

export default MapServices;
