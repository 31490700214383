import {ConfirmationDialog, FormDialog} from '@management-ui/core';
import {Add, Delete, Edit} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import RoutineMessageForm from '../../forms/RoutineMessageForm';
import useTableStyles from '../../../../components/tableStyles';

const Messages = ({routine = null, onUpdateRoutine}) => {
  const classes = useTableStyles();
  const services = useContext(ServiceContext);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (routine) {
      setMessages(routine.messages)
    }
  }, [routine]);

  const handleDelete = useCallback((confirmed) => {
    setShowDelete(false);
    if (confirmed) {
      setLoading(true);
      services.routine.deleteMessage(routine, selected).then(updated => {
        setLoading(false);
        onUpdateRoutine(updated);
      }).catch(() => setLoading(false));
    }
  }, [services, routine, selected, onUpdateRoutine]);

  return (
    <div className={[classes.container, loading ? classes.loading : ''].join(' ')}>
      <div className={classes.actions}>
        <Tooltip title="Add New Message">
          <IconButton onClick={() => setSelected(null) || setShowEdit(true)}>
            <Add/>
          </IconButton>
        </Tooltip>
      </div>
      <div className={classes.row}>
        <div className={classes.header}>Template</div>
        <div className={classes.header}>Delay</div>
        <div className={classes.action}/>
        <div className={classes.action}/>
      </div>
      {(messages.length > 0 ? (
        messages.map((message, index) => (
          <div key={index} className={classes.row}>
            <div className={classes.column}>{message.template.title}</div>
            <div className={classes.column}>{message.delay}</div>
            <div className={classes.action}>
              <Tooltip title="Edit Message">
                <IconButton onClick={() => setSelected(message) || setShowEdit(true)}>
                  <Edit/>
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.action}>
              <Tooltip title="Delete Message">
                <IconButton onClick={() => setSelected(message) || setShowDelete(true)}>
                  <Delete/>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        ))
      ) : (
        <div className={classes.noRecords}>There are no messages associated with this routine</div>
      ))}
      <FormDialog
        title={selected ? 'Edit Message' : 'Add New Message'}
        open={showEdit}
        maxWidth="sm"
        onClose={() => setShowEdit(false)}
        render={(props) => (
          <RoutineMessageForm
            {...props}
            message={selected ?? {}}
            routine={routine}
            onSaved={(updated) => setShowEdit(false) || onUpdateRoutine(updated)}
          />
        )}
      />
      <ConfirmationDialog
        title="Delete Message"
        message="Are you sure you want to delete this message?"
        open={showDelete}
        onClose={handleDelete}
      />
    </div>
  );
};

export default Messages;
