import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const TemplateContentForm = forwardRef(({template, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);
  /** @type {({current: Fields})} */
  const fieldsRef = useRef(null);

  useImperativeHandle(ref, () => ({
    insertVariable(variable) {
      fieldsRef.current.insertVariable(variable);
    },

    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={template}
      type="template"
      fieldsComponent={(props) => <Fields ref={fieldsRef} {...props} />}
      onSave={({id, subject, body, raw}) => services.template.saveTemplate({id, subject, body, raw})}
      onSaved={onSaved}
    />
  );
});

export default TemplateContentForm;
