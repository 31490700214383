import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import Table from './Table';

const Users = ({isAdmin}) => (
  <div>
    <Breadcrumbs crumbs={[{title: isAdmin ? 'Administrators' : 'Users'}]}/>
    <Paper>
      <Table isAdmin={isAdmin}/>
    </Paper>
  </div>
);

export default Users;
