import * as qs from 'qs';
import {API} from './API';

export class LicenceService {
  async getTableLicences(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getLicences(query, page, length);
  }

  async getLicences(query, page, length) {
    return (await API.getConnection('id'))
      .get('licences', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(API.handleError);
  }

  async getLicence(id) {
    return (await API.getConnection('id'))
      .get(`licences/${id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveLicence(data) {
    let method = 'post';
    let url = 'licences';
    if (data.id) {
      method = 'put';
      url = `licences/${data.id}`;
    }
    return (await API.getConnection('id'))
      [method](url, data)
      .then(response => response.data.data)
      .catch(API.handleError);
  }

  async revokeLicence(licence) {
    return (await API.getConnection('id'))
      .put(`licences/${licence.id}/revoke`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteLicence(licence) {
    return (await API.getConnection('id'))
      .delete(`licences/${licence.id}`)
      .then(() => true)
      .catch(error => API.handleError(error));
  }
}
