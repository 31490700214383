import {ColouredLabel, DataTable} from '@management-ui/core';
import * as React from 'react';
import {useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import CategoryForm from '../../forms/CategoryForm';

export default function Categories() {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Categories"
      columns={[
        {
          title: 'Title',
          field: 'title',
          render: (category) => <ColouredLabel label={category.label} colour={category.colour}/>
        },
        {title: 'Description', field: 'description'},
      ]}
      loadData={query => new Promise((resolve, reject) => {
        services.category.getTableCategories(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New Category',
        render: (props) => <CategoryForm category={{}} {...props}/>
      }}
      editForm={{
        render: (category, props) => <CategoryForm category={category} {...props}/>
      }}
    />
  );
}
