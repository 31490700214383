import {Checkbox, Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useOptions} from '../../../../hooks';

const Fields = ({product = {}, prefix = '', multiplePanes = true, excludeProducts = [], children}) => {
  const {/** @var {function(string): string} */watch, setValue} = useFormContext();

  const products = useOptions('products', useCallback((list) => {
    return list.filter(p => p.id === product.product_id || excludeProducts.indexOf(p.id) < 0).map(p => ({
      value: p.id,
      title: p.title,
      unit_price: p.unit_price
    }));
  }, [product, excludeProducts]));

  const selectedProduct = watch(useMemo(() => `${prefixWithSeparator(prefix)}product_id`, [prefix]));
  const productTitleField = useMemo(() => `${prefixWithSeparator(prefix)}product_title`, [prefix]);
  const unitPriceField = useMemo(() => `${prefixWithSeparator(prefix)}unit_price`, [prefix]);
  const quantity = watch(useMemo(() => `${prefixWithSeparator(prefix)}quantity`, [prefix]));
  const unitPrice = watch(unitPriceField);
  const subTotalField = useMemo(() => `${prefixWithSeparator(prefix)}sub_total`, [prefix]);

  useEffect(() => {
    if (selectedProduct && `${selectedProduct}` !== `${product.product?.id}`) {
      const fullProduct = products.find(p => `${p.value}` === `${selectedProduct}`);
      if (fullProduct) {
        setValue(productTitleField, fullProduct.title);
        setValue(unitPriceField, fullProduct.unit_price);
      }
    }
  }, [product, products, selectedProduct, productTitleField, unitPriceField, setValue]);

  useEffect(() => {
    if (quantity && unitPrice) {
      setValue(subTotalField, `${parseInt(quantity) * parseFloat(unitPrice)}`);
    }
  }, [quantity, unitPrice, subTotalField, setValue]);

  return (
    <Panes
      entity={product}
      prefix={prefix}
      panes={[
        {
          title: 'Product Details',
          fields: [
            <Select
              name="product_id"
              prefix={prefix}
              options={products}
              label="Product"
              rules={{required: 'Please select a product'}}
            />,
            <TextField
              name="unit_price"
              prefix={prefix}
              label="Unit Price"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <TextField
              name="quantity"
              prefix={prefix}
              label="Quantity"
            />,
            <TextField
              name="sub_total"
              prefix={prefix}
              label="Sub-total"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
          ]
        }, {
          title: 'Payment Details',
          fields: [
            <Checkbox
              name="single"
              prefix={prefix}
              label="Is this a one-off payment?"
            />
          ]
        },
      ]}
      title={multiplePanes ? null : 'Product'}
    >{children}</Panes>
  );
};

export default Fields;
