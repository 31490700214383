import {Panes, prefixWithSeparator, Select, slugify, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useOptions} from '../../../../hooks';

const Fields = ({product = {}, prefix = '', multiplePanes = true, children}) => {
  const {/** @var {function(string): string} */watch, setValue} = useFormContext();
  const title = watch(useMemo(() => `${prefixWithSeparator(prefix)}title`, [prefix]));
  const codeField = useMemo(() => `${prefixWithSeparator(prefix)}code`, [prefix]);

  useEffect(() => {
    if (title && title !== product.title) {
      setValue(codeField, slugify(title ?? ''));
    }
  }, [product, title, codeField, setValue]);

  const licenceTypes = useOptions('licenceTypes', useCallback((list) => list.map(type => ({
    value: type.id,
    title: `${type.name} (${type.code})`
  })), []));

  const productTypes = useMemo(() => ['Direct', 'Reseller'].map(t => ({title: t, value: slugify(t)})), []);

  return (
    <Panes
      entity={product}
      prefix={prefix}
      panes={[
        {
          title: 'Identification',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a name for this product'}}
            />,
            <TextField
              name="code"
              prefix={prefix}
              label="Code"
              rules={{required: 'Please enter a unique code for this product'}}
            />,
          ]
        }, {
          title: 'Product Details',
          fields: [
            <Select
              name="licence_type_id"
              prefix={prefix}
              options={licenceTypes}
              label="Licence Type"
              rules={{required: 'Please select a licence type'}}
            />,
            <Select
              name="product_type"
              prefix={prefix}
              options={productTypes}
              label="Product Type"
              rules={{required: 'Please select a product type'}}
            />,
            <TextField
              name="unit_price"
              prefix={prefix}
              label="Unit Price"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />,
            <TextField
              name="length"
              prefix={prefix}
              label="Length (set to 0 for no end date)"
              fieldProps={{
                InputProps: {
                  endAdornment: <InputAdornment position="end">days</InputAdornment>
                }
              }}
            />,
          ]
        },
      ]}
      title={multiplePanes ? null : 'Product'}
    >{children}</Panes>
  );
};

export default Fields;
