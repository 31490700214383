export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_CONTACT_VARIABLES = 'SET_CONTACT_VARIABLES';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_SOURCES = 'SET_SOURCES';
export const SET_ADMINS = 'SET_ADMINS';
export const SET_CRM_ADMINS = 'SET_CRM_ADMINS';
export const SET_LICENCE_TYPES = 'SET_LICENCE_TYPES';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_LOADING = 'SET_LOADING';

const options = (
  state = {
    categories: [],
    contactVariables: [],
    countries: [],
    sources: [],
    admins: [],
    crmAdmins: [],
    licenceTypes: [],
    products: [],
    loadingCount: 0,
    loading: false
  },
  action,
) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.categories
      };
    case SET_CONTACT_VARIABLES:
      return {
        ...state,
        contactVariables: action.variables
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.countries
      };
    case SET_SOURCES:
      return {
        ...state,
        sources: action.sources
      };
    case SET_ADMINS:
      return {
        ...state,
        admins: action.admins
      };
    case SET_CRM_ADMINS:
      return {
        ...state,
        crmAdmins: action.crmAdmins
      };
    case SET_LICENCE_TYPES:
      return {
        ...state,
        licenceTypes: action.types
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.products
      };
    case SET_LOADING:
      const count = state.loadingCount + (action.loading ? 1 : -1);
      return {
        ...state,
        loadingCount: count,
        loading: count > 0
      };

    default:
  }
  return state;
};

export default options;
