import {Breadcrumbs, ErrorPopup, Title} from '@management-ui/core';
import {reverse} from 'named-urls';
import React from 'react';
import routes from '../../../../routes';


const Wrapper = ({title, loading, crumbs, errors, children}) => {
  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={title} loading={loading}/>
      {children}
      <ErrorPopup errors={['company']} messages={errors}/>
    </>
  );
}

Wrapper.standardCrumbs = [
  {title: 'Companies', link: routes.companies.index}
];
Wrapper.archiveCrumbs = [
  {title: 'Archive', link: reverse(routes.archive.index)},
  {title: 'Companies', link: reverse(routes.archive.index, {tab: 'companies'})}
];

export default Wrapper;
