import {DataTable, dateTime} from '@management-ui/core';
import PaymentIcon from '@mui/icons-material/Payment';
import {useStripe} from '@stripe/react-stripe-js';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import CalculateIcon from '@mui/icons-material/Calculate';

const Charges = ({user, tableRef, onRefresh, onRecalculated}) => {
  const services = useContext(ServiceContext);
  const stripe = useStripe();
  const [loading, setLoading] = useState(false);
  const [canCheckout, setCanCheckout] = useState(false);
  const recalculate = useRef(false);

  const handleCheckout = useCallback(() => {
    setLoading(true);
    const url = `${window.location.origin}${window.location.pathname}`;
    services.user.id.checkout(user, url, `${url}?cancelled=1`).then((response) => {
      setLoading(false);
      stripe.redirectToCheckout({
        sessionId: response.session
      }).then((result) => {
        return Promise.reject(result.error.message);
      }).catch((error) => console.log(error));
    }).catch(() => setLoading(false));
  }, [services, stripe, user]);

  const handleRecalculate = useCallback(() => {
    recalculate.current = true;
    onRefresh();
  }, [onRefresh]);

  return (
    <DataTable
      title="Outstanding Charges"
      ref={tableRef}
      tableProps={{isLoading: loading}}
      actions={{
        after: [
          ...(canCheckout ? [
            {
              icon: PaymentIcon,
              tooltip: 'Checkout',
              isFreeAction: true,
              onClick: handleCheckout
            }
          ] : []),
          {
            icon: CalculateIcon,
            tooltip: 'Recalculate',
            isFreeAction: true,
            onClick: handleRecalculate
          }
        ]
      }}
      columns={useMemo(() => [
        {title: 'Product', field: 'product', render: data => data.product.product.title, sorting: false},
        {title: 'One-off?', field: 'single', lookup: {true: 'Yes', false: 'No'}, sorting: false},
        {title: 'Amount', field: 'amount', render: data => <Currency amount={data.amount}/>, sorting: false},
        {title: 'Paid', field: 'paid', render: data => <Currency amount={data.paid}/>, sorting: false},
        {
          title: 'Outstanding',
          field: 'outstanding',
          render: data => <Currency amount={data.outstanding}/>,
          sorting: false
        },
        {title: 'Date/Time Raised', field: 'created_at', render: data => dateTime(data.created_at)},
      ], [])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.user.id.getTableCharges(user, query, {}, recalculate.current)
          .then(response => {
            if (recalculate.current) {
              recalculate.current = false;
              onRecalculated();
            }
            setCanCheckout(
              (response.data.filter(c => !c.single).length > 0 && !user.subscription)
              ||
              response.data.filter(c => c.single).length > 0
            );
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, user, onRecalculated])}
    />
  );
};

export default Charges;
