import {DataTable} from '@management-ui/core';
import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import Currency from '../../../../components/Currency';
import AffiliateOfferForm from '../../forms/AffiliateOfferForm';

const Offers = ({user, onUpdateUser, loading}) => {
  const [existing, setExisting] = useState([]);

  useEffect(() => {
    if (user) {
      setExisting(user.offers.map(o => o.product.id));
    }
  }, [user]);

  return (
    <DataTable
      title="Offers"
      options={{
        paging: false,
        search: false
      }}
      canRefresh={false}
      tableProps={{isLoading: loading}}
      columns={useMemo(() => [
        {title: 'Product', field: 'product', render: data => data.product.title, sorting: false},
        {title: 'Trial Period (Months)', field: 'trial', sorting: false},
        {title: 'Price', field: 'price', render: data => <Currency amount={data.price}/>, sorting: false},
      ], [])}
      data={user.offers}
      newForm={{
        title: 'Add New Offer',
        render: (props) => <AffiliateOfferForm user={user} offer={{}} excludeProducts={existing} {...props}/>,
        onSaved: useCallback(saved => onUpdateUser(saved), [onUpdateUser])
      }}
      editForm={{
        render: (token, props) => <AffiliateOfferForm user={user} offer={token} excludeProducts={existing} {...props}/>,
        onSaved: useCallback(saved => onUpdateUser(saved), [onUpdateUser])
      }}
    />
  );
};

export default Offers;
