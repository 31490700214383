import {Panes, prefixWithSeparator, TextField} from '@management-ui/core';
import {MarkdownField} from '@management-ui/richtext';
import {EditorState, Modifier, SelectionState} from 'draft-js';
import React, {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import {useFormContext} from 'react-hook-form';

const Fields = forwardRef(({template = {}, prefix = '', multiplePanes = true, children}, ref) => {
  const {watch, setValue} = useFormContext();

  const subjectName = `${prefixWithSeparator(prefix)}subject`;
  // noinspection JSCheckFunctionSignatures
  const subject = watch(subjectName, '');

  const focusRef = useRef('subject');
  /** @type {({current: HTMLInputElement})} */
  const subjectRef = useRef();
  /** @type {({current: Editor})} */
  const editorRef = useRef();

  const handleVariable = useCallback((variable) => {
    if (focusRef.current === 'subject') {
      const current = subjectRef.current.selectionStart;
      let updated = subject ? `${subject}` : '';
      if (current < updated.length) {
        updated = `${updated.substr(0, current)}${variable.label}${updated.substr(current)}`;
      } else {
        updated = `${updated}${variable.label}`;
      }
      setValue(subjectName, updated);
    } else {
      const editorState = editorRef.current.getState();
      const currentSelection = editorState.getSelection();
      const updatedState = EditorState.createWithContent(Modifier.replaceText(
        editorState.getCurrentContent(),
        currentSelection,
        variable.label
      ));
      // noinspection JSCheckFunctionSignatures
      const updatedSelection = new SelectionState({
        anchorKey: currentSelection.getAnchorKey(),
        anchorOffset: currentSelection.getAnchorOffset() + variable.label.length,
        focusKey: currentSelection.getFocusKey(),
        focusOffset: currentSelection.getFocusOffset() + variable.label.length,
      });
      editorRef.current.setState(EditorState.forceSelection(updatedState, updatedSelection));
    }
  }, [subject, subjectName, setValue]);

  useImperativeHandle(ref, () => ({
    insertVariable(variable) {
      handleVariable(variable);
    }
  }));

  return (
    <Panes
      entity={template}
      prefix={prefix}
      panes={[
        {
          title: '',
          fields: [
            <TextField
              name="subject"
              prefix={prefix}
              label="Subject"
              fieldProps={{
                inputRef: subjectRef,
                onFocus: () => focusRef.current = 'subject'
              }}
            />,
            <MarkdownField
              ref={editorRef}
              markdownName="body"
              rawName="raw"
              prefix={prefix}
              label="Message Body"
              fieldProps={{
                onFocus: () => focusRef.current = 'content',
                readOnly: !!template.archived
              }}
            />
          ]
        }
      ]}
      title={multiplePanes ? null : ''}
    >{children}</Panes>
  );
});

export default Fields;
