import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(theme => ({
  attributes: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  attribute: {
    display: 'flex',

    '& > *': {

      '&:first-child': {
        flex: 1,
        fontWeight: 700,
        paddingRight: theme.spacing(1),
      },

      '&:last-child': {
        flex: 3,
      }
    }
  }
}));

export default function MessageSent({action}) {
  const classes = useStyles();
  return action.data ? (
    <ul className={classes.attributes}>
      <li className={classes.attribute}><span>Template:</span><span>{action.data.template}</span></li>
      <li className={classes.attribute}><span>Subject:</span><span>{action.data.subject}</span></li>
    </ul>
  ) : null;
}
