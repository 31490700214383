import {Button, Dialog, DialogActions, DialogContent, DialogTitle, useTheme} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  title: {
    alignItems: 'center',
    display: 'flex',
  }
}));

const DetailDialog = ({title, maxWidth = 'sm', actions = [], open, onClose, children}) => {
  const classes = useStyles();
  const theme = useTheme();

  return children ? (
    <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        fullWidth={true}
        maxWidth={maxWidth}
        PaperProps={{style: {backgroundColor: theme.palette.background.default}}}
      >
        <DialogTitle classes={{root: classes.title}}>{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          {actions.map(({label, onClick, colour = 'secondary', variant = 'contained'}, index) => (
            <Button key={index} onClick={onClick} color={colour} variant={variant}>{label}</Button>
          ))}
        </DialogActions>
      </Dialog>
    </>
  ) : null;
}

export default DetailDialog;
