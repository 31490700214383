import {ConfirmationDialog, DataTable, date, dateTime, name, SquareButton} from '@management-ui/core';
import {Delete} from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import {Grid} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import LicenceForm from '../../forms/LicenceForm';

const Licences = forwardRef(({user, reseller}, ref) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showRevokeRemove, setShowRevokeRemove] = useState(false);
  const [selected, setSelected] = useState(null);

  useImperativeHandle(ref, () => ({
    refresh() {
      tableRef.current.refresh();
    }
  }));

  const handleSelect = useCallback((licence) => {
    if (!user) {
      history.push(reverse(routes.users.detail, {id: licence.user.id}));
    }
  }, [history, user]);

  const handleRevokeRemove = useCallback((confirmed) => {
    if (confirmed) {
      setLoading(true);
      services.licence[selected['revoked_at'] ? 'deleteLicence' : 'revokeLicence'](selected).then(() => {
        setLoading(false);
        tableRef.current.refresh();
      }).catch(() => setLoading(false));
    }
    setShowRevokeRemove(false);
  }, [services, selected]);

  return (
    <>
      <DataTable
        title="Licences"
        ref={tableRef}
        tableProps={{isLoading: loading}}
        columns={useMemo(() => [
          ...(user ? [] : [{title: 'User', field: 'user', render: data => data.user ? name(data.user) : '-'}]),
          {title: 'Type', field: 'type', render: data => data.type.name},
          {
            title: 'Expiry Date',
            field: 'expires_at',
            render: data => data['expires_at'] ? date(data['expires_at']) : '-'
          },
          {
            title: 'Status',
            field: 'revoked_at',
            render: data => data['revoked_at'] ? `Revoked: ${dateTime(data['revoked_at'])}` : 'Active'
          },
          {
            title: 'Revoke/Remove',
            field: 'Revoke',
            sorting: false,
            width: 200,
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
            render: (item) => (
              <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
                <SquareButton
                  small={true}
                  tooltip={item['revoked_at'] ? 'Remove this Licence' : 'Revoke this Licence'}
                  icon={item['revoked_at'] ? <Delete/> : <CancelIcon/>}
                  onClick={() => setSelected(item) || setShowRevokeRemove(true)}
                />
              </Grid>
            )
          }
        ], [user])}
        loadData={useCallback(query => new Promise((resolve, reject) => {
          services.licence.getTableLicences(query, user ? {user: user.id} : reseller ? {reseller: reseller.id} : {})
            .then(response => {
              resolve({
                data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
              });
            }).catch(() => {
            reject();
          });
        }), [services, user, reseller])}
        newForm={reseller ? null : {
          title: 'Add New Licence',
          render: (props) => <LicenceForm licence={user ? {user} : {}} {...props}/>
        }}
        onRowClick={handleSelect}
      />
      <ConfirmationDialog
        open={showRevokeRemove}
        onClose={handleRevokeRemove}
        title={`${selected && selected['revoked_at'] ? 'Remove' : 'Revoke'} this licence?`}
        message={`Are you sure you want to ${selected && selected['revoked_at'] ? 'remove' : 'revoke'} this licence?`}
      />
    </>
  );
});

export default Licences;
