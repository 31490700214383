import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const RoutineMessageForm = forwardRef(({routine, message, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={{
        ...message,
        ...(message.template ? {template_id: message.template.id, template_title: message.template.title} : {})
      }}
      type="message"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({id, template_id, delay}) => services.routine.saveMessage(routine, {id, template_id, delay})}
      onSaved={onSaved}
    />
  );
});

export default RoutineMessageForm;
