import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import * as React from 'react';
import routes from '../../../../routes';
import Licences from './Licences';
import LicenceTypes from './LicenceTypes';
import Products from './Products';

const TABS = [
  {
    id: 'licences',
    slug: 'licences',
    title: 'Licences',
    icon: <VpnKeyIcon/>,
    render: (props) => <Licences {...props} />
  },
  {
    id: 'products',
    slug: 'products',
    title: 'Products',
    icon: <VideoLabelIcon/>,
    render: (props) => <Products {...props} />
  },
  {
    id: 'licenceTypes',
    slug: 'licence-types',
    title: 'Licence Types',
    icon: <FormatListNumberedIcon/>,
    render: (props) => <LicenceTypes {...props} />
  }
];

const Licencing = () => {
  const {tab, handleTab} = useTab(TABS, routes.licencing.index);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Licencing'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
};

export default Licencing;
