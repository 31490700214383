import {ColouredLabel, DataTable} from '@management-ui/core';
import * as React from 'react';
import {useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import SourceForm from '../../forms/SourceForm';

export default function Sources() {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Sources"
      columns={[
        {
          title: 'Title',
          field: 'title',
          render: (source) => <ColouredLabel label={source.title} colour={source.colour}/>
        },
        {title: 'Description', field: 'description'},
      ]}
      loadData={query => new Promise((resolve, reject) => {
        services.source.getTableSources(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      newForm={{
        title: 'Add New Source',
        render: (props) => <SourceForm source={{}} {...props}/>
      }}
      editForm={{
        render: (source, props) => <SourceForm source={source} {...props}/>
      }}
    />
  );
}
