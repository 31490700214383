import * as qs from 'qs';
import {API} from './API';

export class UserService {

  constructor(system = 'id') {
    this.system = system;
  }

  async getTableUsers(params, additional = {}, resource = 'user') {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getUsers(query, page, length, resource);
  }

  async getUsers(query, page, length, resource = 'user') {
    return (await API.getConnection(this.system))
      .get('users', {
        params: {...query, ...{page, length, resource}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async getUser(id) {
    return (await API.getConnection(this.system))
      .get(`users/${id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveUser(data, resource = 'user') {
    let method = 'post';
    let url = 'users';
    if (data.id) {
      method = 'put';
      url = `users/${data.id}`;
    }
    return (await API.getConnection(this.system))
      [method](`${url}?resource=${resource}`, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async updateProducts(user, products) {
    return (await API.getConnection(this.system))
      .put(`users/${user.id}/products`, {products})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getTableCharges(user, params, additional = {}, recalculate = false) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getCharges(user, query, page, length, recalculate);
  }

  async getCharges(user, query, page, length, recalculate = false) {
    return (await API.getConnection(this.system))
      [recalculate ? 'post' : 'get'](`users/${user.id}/charges`, {
      params: {...query, ...{page, length}},
      paramsSerializer: params => qs.stringify(params),
    })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async checkout(user, success, cancelled) {
    return (await API.getConnection(this.system))
      .post(`users/${user.id}/checkout`, {success, cancelled})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async enableAffiliate(user) {
    return (await API.getConnection(this.system))
      .post(`users/${user.id}/affiliate`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveToken(user, data) {
    let method = 'post';
    let url = `users/${user.id}/tokens`;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection(this.system))
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveOffer(user, data) {
    let method = 'post';
    let url = `users/${user.id}/offers`;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection(this.system))
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async updateStripeAccount(user, success) {
    return (await API.getConnection(this.system))
      .post(`users/${user.id}/stripe/account`, {success})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async verifyStripeAccount(code, state) {
    return (await API.getConnection(this.system))
      .post(`users/stripe/verify`, {code, state})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getStripeLogin(user, redirect) {
    return (await API.getConnection(this.system))
      .get(`users/${user.id}/stripe/login`, {
        params: {redirect},
        paramsSerializer: params => qs.stringify(params)
      }).then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getTableAffiliations(user, params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getAffiliations(user, query, page, length);
  }

  async getAffiliations(user, query, page, length) {
    return (await API.getConnection(this.system))
      .get(`users/${user.id}/affiliations`, {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getTableAffiliatePayments(user, params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getAffiliatePayments(user, query, page, length);
  }

  async getAffiliatePayments(user, query, page, length) {
    return (await API.getConnection(this.system))
      .get(`users/${user.id}/affiliate/payments`, {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }
}
