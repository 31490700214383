import {Panes, TextField} from '@management-ui/core';
import React from 'react';

export default function Fields({company = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={company}
      prefix={prefix}
      panes={[
        {
          title: 'Company Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />
          ]
        },
        {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Company Details'}
    >{children}</Panes>
  );
}
