import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

const useStyles = makeStyles(() => ({
  container: {
    display: 'none',
    width: '100%'
  },
  open: {
    display: 'block',
  }
}));

/**
 * A component to display a tabs without re-rendering the contents (no tab list).
 *
 * @module CachedBlocks
 *
 * @param {ITab[]} tabs An array of tabs
 * @param {string} tab The id of the currently selected tab
 * @param {object} renderProps An object of props passed to the tabs when they render
 *
 * @example
 * <CachedBlocks
 *   tabs={[
 *     {id: 'tabA', slug: 'tab-a', title: 'Tab A', icon: <RecentActorsIcon/>, render: () => <p>Tab A</p>},
 *     {id: 'tabB', slug: 'tab-b', title: 'Tab B', icon: <RecentActorsIcon/>, render: () => <p>Tab B</p>}
 *   ]}
 *   tab="tabA"
 * />
 *
 */
const CachedBlocks = ({tabs, tab, renderProps = {}}) => {
  const classes = useStyles();

  const [availableTabs, setAvailableTabs] = useState({});
  useEffect(() => {
    if (!availableTabs[tab]) {
      const selected = tabs.find(t => t.id === tab);
      const update = {...availableTabs};
      update[tab] = selected.render(renderProps);
      setAvailableTabs(update);
    }
  }, [tabs, tab, availableTabs, renderProps]);

  return (
    <>
      {tabs.map(t => (
        <div key={t.id} className={[classes.container, tab === t.id ? classes.open : ''].join(' ')}>
          {availableTabs[t.id]}
        </div>
      ))}
    </>
  );
};

CachedBlocks.propTypes = {
  tabs: PropTypes.array,
  tab: PropTypes.string,
  renderProps: PropTypes.object
};

export default CachedBlocks;
