import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import * as React from 'react';
import routes from '../../../routes';
import ContactTable from './Contacts/Table';
import TemplateTable from './Templates/Table';

const TABS = [
  {
    id: 'contacts',
    slug: 'contacts',
    title: 'Contacts',
    icon: <RecentActorsIcon/>,
    render: () => <ContactTable archive={true}/>
  },
  {
    id: 'templates',
    slug: 'templates',
    title: 'Templates',
    icon: <RateReviewIcon/>,
    render: () => <TemplateTable archive={true}/>
  },
];

export default function Archive() {
  const {tab, handleTab} = useTab(TABS, routes.archive.index);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Archive'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}
