import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import SettingsIcon from '@mui/icons-material/Settings';
import * as React from 'react';
import routes from '../../../routes';
import AccessKeys from '../components/AccessKeys';
import Settings from '../components/Settings';

const TABS = [
  {
    id: 'settings',
    slug: 'settings',
    title: 'Settings',
    icon: <SettingsIcon/>,
    render: (props) => <Settings system="id" {...props} />
  },
  {
    id: 'accessKeys',
    slug: 'access-keys',
    title: 'Access Keys',
    icon: <DeveloperBoardIcon/>,
    render: (props) => <AccessKeys system="id" {...props} />
  },
];

export default function Identity() {
  const {tab, handleTab} = useTab(TABS, routes.identity.tab);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Identity'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}
