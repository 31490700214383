import {styled, TextField} from '@mui/material';
import React, {useCallback} from 'react';
import slugify from 'slugify';

const Fields = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0, 0, 0, 2),
  width: '100%',
}));

const Variable = (
  {
    index,
    variable: {title = '', key = '', description = '', default_value: defaultValue = '', errors = {}},
    onUpdate
  }
) => {

  const handleTitle = useCallback((value) => {
    if (value && value !== title) {
      onUpdate(index, 'key', slugify(value, {replacement: '_', lower: true}));
    }
    onUpdate(index, 'title', value);
  }, [index, title, onUpdate]);

  return (
    <Fields>
      <TextField
        name={`title_${index}`}
        id={`title_${index}`}
        label="Title"
        error={!!errors.title}
        helperText={errors.title ?? ''}
        value={title}
        onChange={event => handleTitle(event.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <TextField
        name={`key_${index}`}
        id={`key_${index}`}
        label="Key"
        error={!!errors.key}
        helperText={errors.key ?? ''}
        value={key}
        onChange={event => onUpdate(index, 'key', event.target.value)}
        variant="outlined"
        margin="normal"
        required
        fullWidth
      />
      <TextField
        name={`description_${index}`}
        id={`description_${index}`}
        label="Description"
        error={!!errors.description}
        helperText={errors.description ?? ''}
        value={description}
        onChange={event => onUpdate(index, 'description', event.target.value)}
        variant="outlined"
        margin="normal"
        fullWidth
        multiline
        rows={4}
      />
      <TextField
        name={`default_value_${index}`}
        id={`default_value_${index}`}
        label="Default Value"
        error={!!errors.default_value}
        helperText={errors.default_value ?? ''}
        value={defaultValue}
        onChange={event => onUpdate(index, 'default_value', event.target.value)}
        variant="outlined"
        margin="normal"
        fullWidth
      />
    </Fields>

  );
}

export default Variable;
