import * as qs from 'qs';
import {API} from './API';

export class PaymentService {
  async getTablePayments(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getPayments(query, page, length);
  }

  async getPayments(query, page, length) {
    return (await API.getConnection('id'))
      .get('payments', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(API.handleError);
  }

  async downloadInvoice(payment) {
    return (await API.getConnection('id'))
      .get(`payments/${payment.id}/download`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['x-filename']);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }
}
