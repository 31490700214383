import * as qs from 'qs';
import {API} from './API';

export class TemplateService {
  async getTableTemplates(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getTemplates(query, page, length);
  }

  async getTemplates(query, page, length) {
    return (await API.getConnection())
      .get('templates', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async getTemplate(id, archive = false) {
    return (await API.getConnection())
      .get(`templates/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveTemplate(data) {
    let method = 'post';
    let url = 'templates';
    if (data.id) {
      method = 'put';
      url = `templates/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }

  async getPreview(id) {
    return (await API.getConnection())
      .get(`templates/${id}/preview`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async sendPreview(id) {
    return (await API.getConnection())
      .post(`templates/${id}/preview`)
      .then(() => {
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async deleteTemplate(id) {
    return (await API.getConnection())
      .delete(`templates/${id}`)
      .then(response => {
        return response;
      })
      .catch(error => API.handleError(error));
  }

  async restoreTemplate(id) {
    return (await API.getConnection())
      .put(`templates/${id}/restore`)
      .then(response => {
        return response;
      })
      .catch(error => API.handleError(error));
  }

  async saveAttachment(template, name, file) {
    const body = new FormData();
    body.append('name', name);
    if (file) {
      body.append('file', file);
    }
    return (await API.getConnection())
      .post(`templates/${template.id}/attachments`, body, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(response => {
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }

  async downloadAttachment(templateID, id) {
    return (await API.getConnection())
      .get(`templates/${templateID}/attachments/${id}`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', response.headers['x-attachment-name']);
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => API.handleError(error));
  }

  async deleteAttachment(template, attachment) {
    return (await API.getConnection())
      .delete(`templates/${template.id}/attachments/${attachment.id}`, {})
      .then(response => {
        return response.data.data;
      })
      .catch(error => API.handleError(error));
  }
}
