import {ColouredLabel, ConfirmationDialog, DetailColumns, DetailPane, fullDateTime, name} from '@management-ui/core';
import {Box} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import DetailDialog from './DetailDialog';

const PENDING = {label: 'Pending', colour: '#EFCF3D'};
const APPROVED = {label: 'Approved', colour: '#30B046'};
const REJECTED = {label: 'Rejected', colour: '#CC0000'};

const AffiliateRegistrationDialog = ({registration, open, onClose, onApprove, onReject}) => {
  const [canApprove, setCanApprove] = useState(false);
  const [canReject, setCanReject] = useState(false);
  const [status, setStatus] = useState({...PENDING});

  useEffect(() => {
    setCanApprove(!registration.approved_at);
    setCanReject(!registration.rejected_at);
    let status = {...PENDING};
    if (registration.approved_at) {
      status = {...APPROVED};
    } else if (registration.rejected_at) {
      status = {...REJECTED};
    }
    setStatus(status);
  }, [registration]);

  const [showApprove, setShowApprove] = useState(false);
  const [showReject, setShowReject] = useState(false);

  const handleApprove = useCallback(confirmed => {
    setShowApprove(false);
    onApprove(registration, confirmed);
  }, [registration, onApprove]);

  const handleReject = useCallback(confirmed => {
    setShowReject(false);
    onReject(registration, confirmed);
  }, [registration, onReject]);

  return registration ? (
    <>
      <DetailDialog
        title={name(registration)}
        maxWidth="md"
        open={open}
        onClose={onClose}
        actions={[
          ...(canApprove ? [{label: 'Approve', onClick: () => setShowApprove(true)}] : []),
          ...(canReject ? [{label: 'Reject', colour: 'primary', onClick: () => setShowReject(true)}] : []),
          {label: 'Cancel', colour: 'secondary', variant: 'outlined', onClick: () => onClose()}
        ]}
      >
        <DetailColumns columns={[
          <DetailPane
            title="Registration"
            minHeight={400}
            details={[
              {title: 'Name', value: name(registration)},
              {title: 'Email', value: registration.email ?? '-'},
              {title: 'Company Name', value: registration.company_name ?? '-'},
              {title: 'VAT Number', value: registration.vat_number ?? '-'},
            ]}
          />,
          <DetailPane
            title="Admin Details"
            details={[
              {title: 'Status', value: <Box marginBottom={1}><ColouredLabel {...status}/></Box>},
              {title: 'Approved', value: registration.approved_at ? fullDateTime(registration.created_at) : '-'},
              {title: 'Rejected', value: registration.rejected_at ? fullDateTime(registration.rejected_at) : '-'},
              {title: 'Created', value: fullDateTime(registration.created_at)},
              {title: 'Last Updated', value: fullDateTime(registration.updated_at)},
            ]}
          />,
        ]}/>
      </DetailDialog>
      <ConfirmationDialog
        open={showApprove}
        title="Approve"
        message={'Would you like to notify the user that their registration has been approved?'}
        onClose={handleApprove}
      />
      <ConfirmationDialog
        open={showReject}
        title="Reject"
        message={'Would you like to notify the user that their registration has been rejected?'}
        onClose={handleReject}
      />
    </>
  ) : null;
}

export default AffiliateRegistrationDialog;
