import {Paper, Stack, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import Licences from '../Licencing/Licences';

const Reseller = ({user}) => {
  return (
    <>
      {user.reseller_since ? (
        <Paper marginTop={2} component={Box}>
          <Licences
            title="Licences"
            reseller={user}
          />
        </Paper>
      ) : (
        <Stack spacing={4} direction="column" alignItems="center" paddingTop={2}>
          <Typography variant="h6">
            This user is not a reseller. Edit their details and assign them a space name to enable this.
          </Typography>
        </Stack>
      )}
    </>
  );
}

export default Reseller;
