import {RoutedTabs, name, useDetail, useErrors} from '@management-ui/core';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Affiliate from './Affiliate';
import Details from './Details';
import Licencing from './Licencing';
import Reseller from './Reseller';
import Wrapper from './Wrapper';
import {reverse} from 'named-urls';
import StorefrontIcon from '@mui/icons-material/Storefront';

const User = () => {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    user: 'Sorry the user could not be accessed'
  }), []));

  const {
    entity: user,
    setEntity: setUser,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    async (id) => services.user.id.getUser(id),
    false,
    useCallback((user) => [{title: name(user)}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('user', displayError), [toggleError])
  );

  const tabs = useMemo(() => user ? [
    {
      title: 'Licencing',
      link: reverse(routes.users.detail, {id: user?.id}),
      icon: VpnKeyIcon,
      render: (props) => (
        <Licencing {...props} user={user} setUser={setUser}/>
      )
    },
    {
      title: 'Reseller',
      link: reverse(routes.users.reseller, {id: user?.id}),
      icon: StorefrontIcon,
      render: (props) => (
        <Reseller {...props} user={user} setUser={setUser} loading={loading} setLoading={setLoading}/>
      )
    },
    {
      title: 'Affiliate',
      link: reverse(routes.users.affiliate, {id: user?.id}),
      icon: AccountBalanceWalletIcon,
      render: (props) => (
        <Affiliate {...props} user={user} setUser={setUser} loading={loading} setLoading={setLoading}/>
      )
    }
  ] : [], [user, setUser, loading, setLoading]);

  return (
    <Wrapper title="User" loading={loading} user={user} crumbs={crumbs} errors={errors}>
      {user?.id ? (
        <>
          <Details user={user} setUser={setUser}/>
          <RoutedTabs tabs={tabs}/>
        </>
      ) : null}
    </Wrapper>
  );
}

export default User;
