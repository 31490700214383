import {Checkbox, Panes, prefixWithSeparator, TextField} from '@management-ui/core';
import validator from 'email-validator';
import React from 'react';
import {useFormContext} from 'react-hook-form';

export default function Fields({user = {}, prefix = '', multiplePanes = true, children}) {
  const {/** @var {function(string): string} */getValues} = useFormContext();
  return (
    <Panes
      entity={user}
      prefix={prefix}
      panes={[
        {
          title: 'Personal Details',
          fields: [
            <TextField
              name="first_name"
              prefix={prefix}
              label="First Name"
              rules={{required: 'Please enter a first name'}}
            />,
            <TextField
              name="last_name"
              prefix={prefix}
              label="Surname"
              rules={{required: 'Please enter a surname'}}
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
              rules={{
                required: 'Please enter a email address',
                validate: value => {
                  if (value) {
                    return validator.validate(value) ? true : 'Please enter a valid email address';
                  }
                  return true;
                }
              }}
              fieldProps={{
                autoComplete: 'email'
              }}
            />,
          ]
        }, {
          title: 'Access Details',
          fields: [
            <TextField
              name="password"
              prefix={prefix}
              label="Password"
              rules={{
                ...(!user.id ? {required: 'Please enter a password'} : {}),
                validate: value => {
                  if (value) {
                    return value === getValues(`${prefixWithSeparator(prefix)}confirmPassword`) ? true : 'Please make sure the passwords match';
                  }
                  return true;
                }
              }}
              fieldProps={{
                autoComplete: 'password',
                type: 'password'
              }}
            />,
            <TextField
              name="confirmPassword"
              prefix={prefix}
              label="Confirm Password"
              rules={{
                validate: value => {
                  const password = getValues(`${prefixWithSeparator(prefix)}password`);
                  if (password) {
                    return value ? true : 'Please confirm the password';
                  }
                  return true;
                }
              }}
              fieldProps={{
                autoComplete: 'password',
                type: 'password'
              }}
            />,
            <Checkbox
              name="is_active"
              prefix={prefix}
              label="Active User?"
            />
          ]
        },
        {
          title: 'Reseller Details',
          fields: [
            <TextField
              name="space"
              prefix={prefix}
              label="Space"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'User Details'}
    >{children}</Panes>
  );
}
