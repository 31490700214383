import {ActionList, FormDialog, name} from '@management-ui/core';
import React, {useCallback, useEffect, useState} from 'react';
import NoteForm from '../../forms/NoteForm';
import Comment from './Comment';
import ContactUpdate from './ContactUpdate';
import MessageSent from './MessageSent';

export default function Actions(
  {
    loadActivity,
    saveNote,
    setLoading,
    openDialogs,
    toggleDialog,
    toggleError,
    dismissAction = () => new Promise(resolve => resolve()),
    page = 1,
    length = 5,
    updatePageCount
  }
) {
  const [activity, setActivity] = useState([]);

  const formatActivity = useCallback((activity) => {
    setActivity(activity.map(action => ({
      ...action,
      who: name(action.user),
      when: action.created_at
    })));
  }, []);

  const populateActivity = useCallback(() => {
    setLoading(true);
    loadActivity(page, length).then((response) => {
      setLoading(false);
      formatActivity(response.data);
      if (updatePageCount) {
        updatePageCount(response.meta.last_page);
      }
    }).catch(() => {
      toggleError('activity', true);
      setLoading(false);
    });
  }, [loadActivity, page, length, setLoading, toggleError, updatePageCount, formatActivity]);

  useEffect(() => {
    populateActivity();
  }, [populateActivity]);

  const dismissFollowUp = useCallback((action) => {
    setLoading(true);
    dismissAction(action).then(() => {
      setLoading(false);
      populateActivity();
    }).catch(() => {
      toggleError('activity', true);
      setLoading(false);
    });
  }, [dismissAction, setLoading, toggleError, populateActivity]);

  return (
    <>
      <ActionList
        actions={activity}
        renderAction={(action) => {
          let body;
          switch (action.type) {
            case 'contact-created':
            case 'contact-updated':
            case 'contact-deleted':
            case 'contact-restored':
              body = <ContactUpdate action={action}/>;
              break;
            case 'comment':
              body = <Comment action={action} onDismiss={dismissFollowUp}/>;
              break;
            case 'message-sent':
              body = <MessageSent action={action}/>;
              break;
            default:
              break;
          }
          return body;
        }}
      />
      <FormDialog
        title="Add New Comment"
        open={openDialogs.note ?? false}
        onClose={() => toggleDialog('note', false)}
        render={(props) => (
          <NoteForm
            {...props}
            note={{}}
            onSave={(note, followUp, userID) => saveNote(note, followUp, userID, page, length)}
            onSaved={(response) => toggleDialog('note', false) || formatActivity(response.data)}
          />
        )}
      />
    </>
  );
}
