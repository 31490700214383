import {ColourSelector} from '@management-ui/colour';
import {Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';

export default function Fields({category = {}, prefix = '', multiplePanes = true, children}) {
  const parents = useOptions('categories', useCallback((list) => {
    return list.filter(c => !c.parent).map(parent => ({
      value: parent.id,
      title: parent.title
    }));
  }, []));

  const handleReset = useCallback((subject) => {
    if (subject.parent) {
      subject.parent_id = subject.parent.id;
      delete subject.parent;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={category}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Core Details',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title'}}
            />,
            <TextField
              name="description"
              prefix={prefix}
              label="Description"
              multiline
              rows={4}
            />,
          ]
        },
        {
          title: 'Organisation',
          fields: [
            <ColourSelector
              name="colour"
              prefix={prefix}
              label="Colour"
              rules={{required: 'Please select a colour'}}
            />,
            <Select
              name="parent_id"
              prefix={prefix}
              options={parents}
              label="Parent Category"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Category Details'}
    >{children}</Panes>
  );
}
