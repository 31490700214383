import {Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';

const Fields = ({routine = {}, prefix = '', multiplePanes = true, children}) => {

  const licences = useOptions('licenceTypes', useCallback((list) => {
    return list.map(type => ({
      value: type.code,
      title: `${type.name} (${type.code})`
    }));
  }, []));

  return (
    <Panes
      entity={routine}
      prefix={prefix}
      panes={[
        {
          title: 'Routine Details',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title'}}
            />,
            ...(routine.id ? [] : [
              <TextField
                name="alias"
                prefix={prefix}
                label="Alias"
                rules={{required: 'Please enter an alias'}}
              />
            ]),
            <Select
              name="licence_type"
              prefix={prefix}
              options={licences}
              label="Licence Type"
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Routine Details'}
    >{children}</Panes>
  );
};

export default Fields;
