import {makeStyles, useTheme} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

const useStyles = makeStyles(theme => ({
  label: {
    alignItems: 'center',
    display: 'flex'
  },
  colour: {
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    height: 16,
    marginRight: theme.spacing(1),
    width: 16,
  }
}));

/**
 * A label with a colour indicator on the left.
 *
 * @module ColouredLabel
 *
 * @param {string} label The label text to display
 * @param {string} colour The associated colour
 * @param {string} borderColour A border colour for the indicator (if different)
 *
 * @example
 * <ColouredLabel label="Red" colour="#ff0000"/>
 *
 */
const ColouredLabel = ({label, colour, borderColour = ''}) => {
  const classes = useStyles();
  const [border, setBorder] = useState('');
  const theme = useTheme();

  useEffect(() => {
    if (borderColour) {
      setBorder(borderColour);
    } else {
      setBorder(
        ['#fff', '#ffffff', 'white'].indexOf(colour.toLowerCase()) >= 0
          ? theme.palette.divider : colour
      );
    }
  }, [colour, borderColour, theme]);

  return (
    <div className={classes.label}>
      <div className={classes.colour} style={{backgroundColor: colour, borderColor: border}}/>
      {label}
    </div>
  )
};

ColouredLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
  colour: PropTypes.string
};

export default ColouredLabel;
