import {BubblesField, Panes} from '@management-ui/core';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';

export default function Fields({contact = {}, prefix = '', multiplePanes = true, children}) {
  const categories = useOptions('categories', useCallback((list) => list.map(category => ({
    label: category.label,
    colour: category.colour,
    id: category.id
  })), []));
  const sources = useOptions('sources', useCallback((list) => list.map(source => ({
    label: source.title,
    colour: source.colour,
    id: source.id
  })), []));

  const handleReset = useCallback((subject) => {
    if (subject.categories) {
      subject.categories = subject.categories.map(c => c.id);
    } else {
      subject.categories = [];
    }
    if (subject.source) {
      subject.source_id = subject.source.id;
      delete subject.source;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={contact}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Categories',
          fields: [
            <BubblesField
              name="categories"
              prefix={prefix}
              bubbles={categories}
              multiSelect={true}
              label="Select 1 or more categories"
            />,
          ]
        },
        {
          title: 'Sources',
          fields: [
            <BubblesField
              name="source_id"
              prefix={prefix}
              bubbles={sources}
              label="Select a source"
            />
          ]
        },
      ]}
      title={multiplePanes ? null : 'Source Details'}
    >{children}</Panes>
  );
}
