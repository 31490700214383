import {Title} from '@management-ui/core';
import {Paper} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import {useSelector} from 'react-redux';
import ContactsTable from '../Contacts/Table';
import Appointments from './Appointments';
import FollowUps from './FollowUps';

export default function Dashboard() {
  const user = useSelector(state => state['auth'].user);
  return (
    <>
      <Title title="Dashboard"/>
      <Paper marginTop={2} component={Box}>
        <Appointments agent={user}/>
      </Paper>
      <Paper marginTop={2} component={Box}>
        <FollowUps agent={user}/>
      </Paper>
      <Paper marginTop={2} component={Box}>
        <ContactsTable title="My Contacts" showFilter={false} agent={user}/>
      </Paper>
    </>
  );
}
