import {DataTable, dateTime} from '@management-ui/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import {lengthInDays, productType} from '../../../../formatters';
import routes from '../../../../routes';
import ProductForm from '../../forms/ProductForm';

const Products = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((product) => {
    history.push(reverse(routes.licencing.products.detail, {id: product.id}));
  }, [history]);

  return (
    <DataTable
      title="Products"
      columns={[
        {title: 'Title', field: 'title'},
        {title: 'Code', field: 'code'},
        {title: 'Licence Type', field: 'licence_type', render: data => data.licence_type.name},
        {title: 'Product Type', field: 'product_type', render: data => productType(data.product_type)},
        {title: 'Unit Price', field: 'unit_price', render: data => <Currency amount={data.unit_price}/>},
        {
          title: 'Length',
          field: 'length',
          render: data => lengthInDays(data.length)
        },
        {title: 'Date Created', field: 'created_at', render: data => dateTime(data.created_at)},
        {title: 'Date Modified', field: 'updated_at', render: data => dateTime(data.updated_at)}
      ]}
      loadData={query => new Promise((resolve, reject) => {
        services.product.getTableProducts(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(reject);
      })}
      onRowClick={goToDetail}
      newForm={{
        title: 'Add New Product',
        render: (props) => <ProductForm product={{}} {...props}/>,
        onSaved: goToDetail
      }}
    />
  );
};

export default Products;
