import {Settings as UISettings} from '@management-ui/core';
import * as React from 'react';
import {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../components/Services';
import SettingsForm from '../forms/SettingsForm';

export default function Settings({system}) {
  const services = useContext(ServiceContext);
  return (
    <UISettings
      getForm={useCallback((ref, settings, handleSaved, handleError) => (
        <SettingsForm ref={ref} system={system} settings={settings} onSaved={handleSaved} onError={handleError}/>
      ), [system])}
      loadSettings={useCallback(() => {
        return services.settings[system].getSettings();
      }, [services, system])}
    />
  );
}
