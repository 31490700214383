import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const ProductForm = forwardRef(({product, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={{...product, ...(product['licence_type'] ? {licence_type_id: product['licence_type'].id} : {})}}
      type="product"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({id, title, code, licence_type_id, product_type, unit_price, length}) => (
        services.product.saveProduct({id, title, code, licence_type_id, product_type, unit_price, length})
      )}
      onSaved={onSaved}
    />
  );
});

export default ProductForm;
