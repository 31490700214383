import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

const NoteForm = forwardRef(({note, onSave, onSaved}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={note}
      type="note"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({comments, followUp, userID}) => onSave(comments, followUp, userID)}
      onSaved={onSaved}
    />
  );
});

export default NoteForm;
