import * as qs from 'qs';
import {API} from './API';

export class AccessKeyService {

  constructor(system = 'crm') {
    this.system = system;
  }

  async getTableKeys(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getKeys(query, page, length);
  }

  async getKeys(query, page, length) {
    return (await API.getConnection(this.system))
      .get('keys', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async getKey(id) {
    return (await API.getConnection(this.system))
      .get(`keys/${id}`)
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveKey(data) {
    let method = 'post';
    let url = 'keys';
    if (data.id) {
      method = 'put';
      url = `keys/${data.id}`;
    }
    return (await API.getConnection(this.system))
      [method](url, data)
      .then(response => {
        return response.data.data
      })
      .catch(error => API.handleError(error));
  }
}
