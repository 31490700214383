import {DateField, name, Panes, Select, TextField} from '@management-ui/core';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import {prefixWithSeparator} from '../../../../@management-ui/core';
import {useOptions} from '../../../../hooks';

export default function Fields({note = {}, prefix = '', multiplePanes = true, children}) {
  const {user} = useSelector(store => store['auth']);
  const {setValue} = useFormContext();

  const admins = useOptions('crmAdmins', useCallback((list) => {
    return list.map(admin => ({
      value: admin.id,
      title: name(admin),
      syncID: admin.sync_id
    }));
  }, []));

  const userIDName = useMemo(() => `${prefixWithSeparator(prefix)}userID`, [prefix]);

  useEffect(() => {
    if (user && admins.length) {
      const current = admins.find(a => a.syncID === user.id);
      if (current) {
        setValue(userIDName, current.value);
      }
    }
  }, [user, admins, setValue, userIDName])

  return (
    <Panes
      entity={note}
      prefix={prefix}
      panes={[
        {
          title: 'Note',
          fields: [
            <TextField
              name="comments"
              prefix={prefix}
              label="Comments"
              fieldProps={{
                multiline: true,
                rows: 6
              }}
            />,
            <DateField
              name="followUp"
              prefix={prefix}
              label="Follow-up Date"
            />,
            <Select
              name="userID"
              prefix={prefix}
              label="Follow-up By"
              options={admins}
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Note'}
    >{children}</Panes>
  );
}
