import {ConfirmationDialog, DataTable, FormDialog, SquareButton} from '@management-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {Grid} from '@mui/material';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import {useOptions} from '../../../../hooks';
import UserProductForm from '../../forms/UserProductForm';

const DEFAULT_SELECTED = {index: -1, product: null};

const Products = ({user, onUpdateUser}) => {
  const services = useContext(ServiceContext);

  const [editMode, setEditMode] = useState(false);
  const [products, setProducts] = useState([]);

  const availableProducts = useOptions('products');

  const [canAdd, setCanAdd] = useState(false);

  useEffect(() => {
    setCanAdd(
      editMode
      &&
      availableProducts.filter(a => products.findIndex(p => `${a.id}` === `${p.product_id}`) < 0).length > 0
    );
  }, [editMode, availableProducts, products]);

  const handleUserProducts = useCallback((list) => {
    setProducts(list.map(({id, product, unit_price, quantity, sub_total, single, accumulated}) => ({
      id: id, product_id: product.id, product_title: product.title, unit_price, quantity, sub_total, single, accumulated
    })))
  }, []);

  useEffect(() => {
    handleUserProducts(user.products);
  }, [user, handleUserProducts]);

  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selected, setSelected] = useState({...DEFAULT_SELECTED});
  const [showDiscard, setShowDiscard] = useState(false);
  const [showSave, setShowSave] = useState(false);

  const handleSelect = useCallback((product) => {
    const index = products.findIndex(p => p.id === product.id);
    setSelected({product, index: index < 0 ? products.length : index});
  }, [products]);

  const handleEdit = useCallback((edited) => {
    const list = [...products];
    if (edited.index < 0) {
      edited.index = list.length;
    }
    list[edited.index] = edited.product;
    setProducts(list);
    setShowEdit(false);
  }, [products]);

  const handleDelete = useCallback((confirmed) => {
    if (confirmed) {
      const list = [...products];
      list.splice(selected.index, 1);
      setProducts([...list]);
    }
    setShowDelete(false)
  }, [products, selected]);

  const handleDiscard = useCallback((confirmed) => {
    if (confirmed) {
      handleUserProducts(user.products);
      setEditMode(false);
    }
    setShowDiscard(false);
  }, [user, handleUserProducts]);

  const handleSave = useCallback((confirmed) => {
    if (confirmed) {
      setLoading(true);
      services.user.id.updateProducts(user, products).then((updated) => {
        setLoading(false);
        setEditMode(false);
        onUpdateUser(updated);
      }).catch(() => setLoading(false));
    }
    setShowSave(false);
  }, [services, user, onUpdateUser, products]);

  return (
    <>
      <DataTable
        ref={tableRef}
        title="Products"
        options={{
          search: false,
          paging: false
        }}
        actions={{
          after: editMode ? [
            {
              icon: ClearIcon,
              tooltip: 'Discard Changes',
              isFreeAction: true,
              onClick: () => setShowDiscard(true)
            },
            {
              icon: SaveIcon,
              tooltip: 'Save Changes',
              isFreeAction: true,
              onClick: () => setShowSave(true)
            }
          ] : [
            {
              icon: EditIcon,
              tooltip: 'Edit Products',
              isFreeAction: true,
              onClick: () => setEditMode(true)
            }
          ]
        }}
        tableProps={{isLoading: loading}}
        columns={useMemo(() => [
          {title: 'Product', field: 'product_title', sorting: false},
          {
            title: 'Unit Price',
            field: 'unit_price',
            render: data => <Currency amount={data.unit_price}/>,
            sorting: false
          },
          {title: 'Quantity', field: 'quantity', sorting: false},
          {title: 'Sub-total', field: 'sub_total', render: data => <Currency amount={data.sub_total}/>, sorting: false},
          {title: 'One-off?', field: 'single', lookup: {true: 'Yes', false: 'No'}, sorting: false},
          {
            title: 'Accumulated',
            field: 'accumulated',
            render: data => <Currency amount={data.accumulated}/>,
            sorting: false
          },
          ...(editMode ? [
            {
              title: 'Edit',
              shouldDisplay: (product) => !product.accumulated || !product.single,
              icon: <EditIcon/>,
              handler: (selected) => handleSelect(selected) || setShowEdit(true)
            },
            {
              title: 'Delete',
              shouldDisplay: (product) => !product.accumulated || !product.single,
              icon: <DeleteIcon/>,
              handler: (selected) => handleSelect(selected) || setShowDelete(true)
            }
          ] : []).map(column => ({
            title: column.title,
            field: column.title,
            sorting: false,
            width: 120,
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
            render: (item) => column.shouldDisplay(item) ? (
              <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
                <SquareButton
                  tooltip={column.title}
                  icon={column.icon}
                  onClick={() => column.handler(item)}
                />
              </Grid>
            ) : null
          }))
        ], [editMode, handleSelect])}
        canRefresh={false}
        data={products}
        newForm={canAdd ? {
          title: 'Add New Product',
          render: (props) => (
            <UserProductForm
              product={{}}
              excludeProducts={products.map(p => p.product_id)}
              {...props}
            />
          ),
          onSaved: handleEdit
        } : null}
      />
      <FormDialog
        title="Edit Product"
        open={showEdit}
        onClose={() => setShowEdit(false)}
        render={useCallback((props) => (
          <UserProductForm
            {...props}
            index={selected.index}
            product={selected.product}
            onSaved={handleEdit}
            excludeProducts={products.map(p => p.product_id)}
          />
        ), [products, selected, handleEdit])}
      />
      <ConfirmationDialog
        open={showDelete}
        title="Delete Product?"
        message="Are you sure you want to delete this product?"
        onClose={handleDelete}
      />
      <ConfirmationDialog
        open={showSave}
        title="Save Products?"
        message="Are you sure you want to save your changes?"
        onClose={handleSave}
      />
      <ConfirmationDialog
        open={showDiscard}
        title="Discard Changes?"
        message="Are you sure you want to discard your changes?"
        onClose={handleDiscard}
      />
    </>
  );
};

export default Products;
