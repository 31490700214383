import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  name,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {Close} from '@mui/icons-material';
import {Box, Paper, Typography} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Messages from './Messages';
import Wrapper from './Wrapper';

const ContactRoutine = ({archive = false}) => {
  const services = useContext(ServiceContext);

  const {openDialogs, toggleDialog} = useDialogs(['cancel']);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    routine: 'Sorry the routine could not be accessed'
  }), []));

  const {
    entity: routine,
    setEntity: setRoutine,
    loadEntity: loadRoutine,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.contactRoutine.getRoutine,
    archive,
    useCallback((routine) => [{title: `${routine.routine.title} (${name(routine.contact)})`}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('routine', displayError), [toggleError])
  );

  useCallback((updated) => {
    if (updated) {
      setRoutine(updated);
    } else if (routine?.id) {
      loadRoutine(routine.id);
    }
  }, [routine, loadRoutine, setRoutine]);

  return (
    <Wrapper title="Routine" loading={loading} routine={routine} crumbs={crumbs} errors={errors}>
      {routine?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Routine Details"
              actions={routine.active ? [
                {title: 'Cancel Routine', icon: <Close/>, onClick: () => toggleDialog('cancel', true)}
              ] : []}
              details={[
                {title: 'Routine', value: routine.routine.title},
                {title: 'Contact', value: name(routine.contact)},
                {title: 'Active?', value: routine.active ? 'Yes' : 'No'}
              ]}
              dialogs={[
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.cancel ?? false}
                    title="Cancel"
                    message={routine ? 'Are you sure you want to this routine?' : ''}
                    onClose={confirmed => {
                      toggleDialog('cancel', false);
                      if (confirmed) {
                        setLoading(true);
                        services.contactRoutine.cancelRoutine(routine.id).then((cancelled) => {
                          setLoading(false);
                          setRoutine(cancelled);
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,
            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(routine.created_at)},
                {title: 'Last Updated', value: dateTime(routine.updated_at)}
              ]}
            />,
          ]}/>

          <Paper component={Box} marginTop={2} overflow="hidden">
            <Box paddingX={2} paddingTop={2}>
              <Typography variant="h6">Messages</Typography>
            </Box>
            <Messages routine={routine} onUpdateRoutine={setRoutine}/>
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
};

export default ContactRoutine;
