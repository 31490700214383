import {Bubbles, name} from '@management-ui/core';
import {makeStyles} from '@mui/styles';
import numeral from 'numeral';
import React, {useEffect, useState} from 'react';
import {resellerName} from '../../../../formatters';

const ATTRS = {
  title: {title: 'Title', render: null},
  first_name: {title: 'First Name', render: null},
  middle_name: {title: 'Middle Name', render: null},
  last_name: {title: 'Last Name', render: null},
  phone: {title: 'Phone', render: null},
  mobile: {title: 'Mobile', render: null},
  email: {title: 'Email', render: null},
  website: {title: 'Website', render: null},
  company: {
    title: 'Company',
    render: (action) => action.company ? (typeof action.company === 'string' ? action.company : action.company.name) : ''
  },
  address1: {title: 'Address Line 1', render: null},
  address2: {title: 'Address Line 2', render: null},
  address3: {title: 'Address Line 3', render: null},
  town: {title: 'Town', render: null},
  region: {title: 'Region', render: null},
  postcode: {title: 'Postcode', render: null},
  country: {title: 'Country', render: (action) => action.country ? action.country.name : ''},
  latitude: {title: 'Latitude', render: (action) => action.latitude ? numeral(action.latitude).format('0,0.00000') : 0},
  longitude: {
    title: 'Longitude',
    render: (action) => action.longitude ? numeral(action.longitude).format('0,0.00000') : 0
  },
  source: {
    title: 'Source',
    render: (action) => action.source ?
      <Bubbles bubbles={[{label: action.source.title, colour: action.source.colour}]}/> : ''
  },
  categories: {
    title: 'Categories', render: (action) => action.categories.length ?
      <Bubbles
        bubbles={action.categories.map(category => ({label: category.label, colour: category.colour}))}/> : 'None'
  },
  reseller: {title: 'Reseller', render: (action) => action.reseller ? resellerName(action.reseller) : ''},
  agent: {title: 'Agent', render: (action) => action.agent ? name(action.agent) : ''},
};

const useStyles = makeStyles(theme => ({
  attributes: {
    display: 'flex',
    flexDirection: 'column',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  attribute: {
    display: 'flex',

    '& span': {

      '&:first-child': {
        flex: 1,
        fontWeight: 700,
        paddingRight: theme.spacing(1),
      },

      '&:last-child': {
        flex: 3,
      }
    }
  }
}));

export default function ContactUpdate({action}) {
  const classes = useStyles();

  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    if (action && action.data) {
      setAttributes(Object.keys(ATTRS).map(key => {
        const attr = ATTRS[key];
        let display = false;
        let value;
        if (action.data.hasOwnProperty(key)) {
          display = true;
          value = attr.render ? attr.render(action.data) : action.data[key];
        }
        return {title: attr.title, value, display};
      }).filter(a => a.display));
    }
  }, [action]);

  return attributes.length > 0 ? (
    <ul className={classes.attributes}>
      {attributes.map((a, index) => (
        <li className={classes.attribute} key={index}><span>{a.title}:</span><span>{a.value}</span></li>
      ))}
    </ul>
  ) : null;
}
