import {Breadcrumbs, ErrorPopup, Title} from '@management-ui/core';
import {reverse} from 'named-urls';
import React from 'react';
import routes from '../../../../routes';

const Wrapper = ({title, loading, titleControls, crumbs, errors, children}) => {
  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={title} loading={loading} controls={titleControls}/>
      {children}
      <ErrorPopup errors={['product']} messages={errors}/>
    </>
  );
}

Wrapper.standardCrumbs = [
  {title: 'Licencing', link: routes.licencing.index},
  {title: 'Products', link: reverse(routes.licencing.index, {tab: 'products'})}
];

export default Wrapper;
