import {Map as GoogleMap, Marker} from 'google-maps-react';
import PropTypes from 'prop-types';
import React from 'react';

/**
 * An interactive map loaded with Google Maps. In order for this component to work make sure you have loaded the Google
 * Maps JS SDK (`<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=API_KEY"></script>`).
 *
 * @module Map
 *
 * @param {float} latitude The latitude used to centre the map and add a marker
 * @param {float} longitude The longitude used to centre the map and add a marker
 * @param {int} zoom The default zoom level for the map
 * @param {int} height The default height of the map (pixels)
 * @param {string} title The title for the marker
 *
 * @example
 * <Map latitude={53.76845} longitude={-1.73937} zoom={15} height={400} title="Hope Park Business Centre"/>
 *
 */
const Map = ({latitude, longitude, zoom = 15, height = 400, title}) => {
  const coords = {lat: latitude, lng: longitude}
  return (
    <GoogleMap
      google={window.google}
      initialCenter={coords}
      center={coords}
      zoom={zoom}
      containerStyle={{position: 'relative', width: '100%', height}}>
      <Marker name={title} position={coords}/>
    </GoogleMap>
  );
}

Map.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  zoom: PropTypes.number,
  height: PropTypes.number,
  title: PropTypes.string
};

export default Map;
