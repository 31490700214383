import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const AssociateLicenceTypeForm = forwardRef(({type, existing, upgrade, association, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={association}
      type="association"
      fieldsComponent={(props) => <Fields {...props} type={type} existing={existing} upgrade={upgrade}/>}
      onSave={useCallback(({associate, quantity}) => (
        services.licenceType.associate(type, associate, !upgrade ? quantity : null)
      ), [services, type, upgrade])}
      onSaved={onSaved}
    />
  );
});

export default AssociateLicenceTypeForm;
