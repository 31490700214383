import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import CategoryIcon from '@mui/icons-material/Category';
import HearingIcon from '@mui/icons-material/Hearing';
import PublicIcon from '@mui/icons-material/Public';
import * as React from 'react';
import routes from '../../../../routes';
import Categories from './Categories';
import Countries from './Countries';
import Sources from './Sources';

const TABS = [
  {
    id: 'categories',
    slug: 'categories',
    title: 'Categories',
    icon: <CategoryIcon/>,
    render: (props) => <Categories {...props} />
  },
  {
    id: 'sources',
    slug: 'sources',
    title: 'Sources',
    icon: <HearingIcon/>,
    render: (props) => <Sources {...props} />
  },
  {
    id: 'countries',
    slug: 'countries',
    title: 'Countries',
    icon: <PublicIcon/>,
    render: (props) => <Countries {...props} />
  }
];

export default function Management() {
  const {tab, handleTab} = useTab(TABS, routes.management.tab);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Management'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}
