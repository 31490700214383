import {Authenticated} from '@management-ui/core';
import {Timeline} from '@mui/icons-material';
import ApprovalIcon from '@mui/icons-material/Approval';
import ArchiveIcon from '@mui/icons-material/Archive';
import BusinessIcon from '@mui/icons-material/Business';

import DashboardIcon from '@mui/icons-material/Dashboard';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ForumIcon from '@mui/icons-material/Forum';
import HttpsIcon from '@mui/icons-material/Https';
import PeopleIcon from '@mui/icons-material/People';
import RateReviewIcon from '@mui/icons-material/RateReview';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import logo from '../../assets/bubul-expert.svg';
import routes from '../../routes';
import {logout} from '../../store/actions/auth';
import AffiliateRegistrations from './screens/AffiliateRegistrations';
import Appointments from './screens/Appointments';
import Archive from './screens/Archive';
import Companies from './screens/Companies';
import Company from './screens/Company';
import Contact from './screens/Contact';
import ContactActivity from './screens/Contact/Activity';
import ContactRoutine from './screens/ContactRoutine';
import Contacts from './screens/Contacts';
import CRM from './screens/CRM';

import Dashboard from './screens/Dashboard';
import Identity from './screens/Identity';
import LicenceType from './screens/LicenceType';
import Licencing from './screens/Licencing';
import Management from './screens/Management';
import Product from './screens/Product';
import Routine from './screens/Routine';
import Routines from './screens/Routines';
import Activated from './screens/Routines/Activated';
import StripeVerification from './screens/StripeVerification';
import Template from './screens/Template';
import Templates from './screens/Templates';
import User from './screens/User';
import Users from './screens/Users';

const Admin = (props) => {
  const user = useSelector(state => state['auth'].user);
  const dispatch = useDispatch();
  return (
    <Authenticated
      title="BuBul Expert Manager"
      logo={logo}
      logoWidth="60%"
      userName={user ? user.first_name : ''}
      nav={[
        {title: 'Dashboard', route: routes.dashboard, icon: <DashboardIcon/>},
        {title: 'Contacts', route: routes.contacts.index, icon: <RecentActorsIcon/>},
        {title: 'Companies', route: routes.companies.index, icon: <BusinessIcon/>},
        {title: 'Templates', route: routes.templates.index, icon: <RateReviewIcon/>},
        {title: 'Routines', route: routes.routines.index, icon: <Timeline/>},
        {title: 'Appointments', route: routes.appointments, icon: <DateRangeIcon/>},
        {title: 'Licencing', route: routes.licencing.index, icon: <VpnKeyIcon/>},
        {title: 'Users', route: routes.users.index, icon: <PeopleIcon/>},
        {title: 'Affiliate Registrations', route: routes.affiliateRegistrations.index, icon: <ApprovalIcon/>},
        {title: 'Administrators', route: routes.administrators, icon: <SecurityIcon/>},
        {title: 'Management', route: routes.management.index, icon: <StorageIcon/>},
        {title: 'CRM', route: routes.crm.index, icon: <ForumIcon/>},
        {title: 'Identity', route: routes.identity.index, icon: <HttpsIcon/>},
        {title: 'Archive', route: routes.archive.index, icon: <ArchiveIcon/>},
      ]}
      onLogout={() => dispatch(logout())}
    >
      <Switch>
        <Route
          exact
          path={routes.dashboard}
          render={routeProps => <Dashboard {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.contacts.index}
          render={routeProps => <Contacts {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.contacts.detail}
          render={routeProps => <Contact {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.contacts.activity}
          render={routeProps => <ContactActivity {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.companies.index}
          render={routeProps => <Companies {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.companies.detail}
          render={routeProps => <Company {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.templates.index}
          render={routeProps => <Templates {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.templates.detail}
          render={routeProps => <Template {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.routines.index}
          render={routeProps => <Routines {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.routines.activated.index}
          render={routeProps => <Activated {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.routines.activated.detail}
          render={routeProps => <ContactRoutine {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.routines.detail}
          render={routeProps => <Routine {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.appointments}
          render={routeProps => <Appointments {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.licencing.index}
          render={routeProps => <Licencing {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.licencing.products.detail}
          render={routeProps => <Product {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.licencing.licenceTypes.detail}
          render={routeProps => <LicenceType {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.users.index}
          render={routeProps => <Users {...props} {...routeProps} isAdmin={false}/>}/>
        <Route
          exact
          path={routes.users.verify}
          render={routeProps => <StripeVerification {...props} {...routeProps} />}/>
        <Route
          path={routes.users.detail}
          render={routeProps => <User {...props} {...routeProps} isAdmin={false}/>}/>
        <Route
          exact
          path={routes.affiliateRegistrations.tab}
          render={routeProps => <AffiliateRegistrations {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.administrators}
          render={routeProps => <Users {...props} {...routeProps} isAdmin={true}/>}/>
        <Route
          exact
          path={routes.management.tab}
          render={routeProps => <Management {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.crm.tab}
          render={routeProps => <CRM {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.identity.tab}
          render={routeProps => <Identity {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.archive.index}
          render={routeProps => <Archive {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.archive.contacts.detail}
          render={routeProps => <Contact {...props} {...routeProps} archive={true}/>}/>
        <Route
          exact
          path={routes.archive.contacts.activity}
          render={routeProps => <ContactActivity {...props} {...routeProps} archive={true}/>}/>
        <Route
          exact
          path={routes.archive.templates.detail}
          render={routeProps => <Template {...props} {...routeProps} archive={true}/>}/>
      </Switch>
    </Authenticated>
  );
};

export default Admin;
