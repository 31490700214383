import {ConfirmationDialog, date, name} from '@management-ui/core';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useState} from 'react';
import Text from '../../../../components/Text';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  followUp: {
    margin: theme.spacing(2, 0, 0),
  },

  dismiss: {
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    borderRadius: 4,
    color: theme.palette.background.paper,
    cursor: 'pointer',
    fontWeight: '600',
    margin: `0 0 0 ${theme.spacing(2)}`,
    padding: theme.spacing(1),
  },

  completed: {
    color: theme.palette.success.main,
  },
}));

const Comment = ({action, onDismiss}) => {
  const classes = useStyles();
  const [showDismiss, setShowDismiss] = useState(false);

  const handleClose = useCallback((dismiss) => {
    if (dismiss) {
      onDismiss(action);
    }
    setShowDismiss(false);
  }, [onDismiss, action]);

  return (
    <div className={classes.container}>
      {action.data.content ? <Text element="p" text={action.data.content}/> : null}
      {action.follow_up_on ? (
        <span className={classes.followUp}>
          <strong>Follow-up by {name(action.follow_up_by)} on {date(action.follow_up_on)}</strong>
          &nbsp;&nbsp;&nbsp;
          {action.dismissed_at ? (
            <strong className={classes.completed}>Completed: {date(action.dismissed_at)}</strong>
          ) : onDismiss ? (
            <>
              <button className={classes.dismiss} onClick={() => setShowDismiss(true)}>Dismiss</button>
              <ConfirmationDialog
                open={showDismiss}
                onClose={handleClose}
                title="Dismiss Follow-up?"
                message="Are you sure you want to dismiss this follow-up?"
              />
            </>
          ) : null}
      </span>
      ) : null}
    </div>
  );
}

export default Comment;
