import {DetailPane, FormDialog, TooltipButtonMenu, useDialogs} from '@management-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';
import TemplateVariablesForm from '../../forms/TemplateVariablesForm';

export default function Variables({template, onSelectVariable, onTemplateUpdate}) {
  const {openDialogs, toggleDialog} = useDialogs(['variables']);

  const formatVariables = useCallback((variables) => {
    return variables.map(({title, description, key}) => ({title, description, label: `{{${key}}}`}));
  }, []);

  const contactVariables = useOptions('contactVariables', formatVariables);

  const handleVariablesUpdate = useCallback((updated) => {
    toggleDialog('variables', false);
    onTemplateUpdate(updated);
  }, [onTemplateUpdate, toggleDialog]);

  return (
    <DetailPane
      title="Variables"
      collapseChildrenHeight={false}
      actions={!template.archived ? [
        {title: 'Manage Variables', icon: <EditIcon/>, onClick: () => toggleDialog('variables', true)}
      ] : []}
      dialogs={[
        (props) => (
          <FormDialog
            {...props}
            title="Manage Variables"
            open={openDialogs.variables ?? false}
            onClose={() => toggleDialog('variables', false)}
            render={(props) => (
              <TemplateVariablesForm
                {...props}
                template={template}
                onSaved={handleVariablesUpdate}/>
            )}
          />
        )
      ]}
    >
      <TooltipButtonMenu
        title="Template"
        buttons={formatVariables(template.variables)}
        onClick={onSelectVariable}
      />
      <TooltipButtonMenu
        title="Contact"
        buttons={contactVariables}
        onClick={onSelectVariable}
      />
    </DetailPane>
  );
}
