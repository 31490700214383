import {Breadcrumbs, ErrorPopup, Notice, Title} from '@management-ui/core';
import {reverse} from 'named-urls';
import React from 'react';
import routes from '../../../../routes';


const Wrapper = ({title, loading, titleControls, routine, crumbs, errors, children}) => {
  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={title} loading={loading} controls={titleControls}/>
      <Notice
        display={routine && routine.archived}
        message={[
          'This routine has been archived and is therefore no longer available.',
          'You won\'t be able to modify it unless it is restored.'
        ].join(' ')}
      />
      {children}
      <ErrorPopup errors={['routine']} messages={errors}/>
    </>
  );
}

Wrapper.standardCrumbs = [
  {title: 'Routines', link: routes.routines.index}
];
Wrapper.archiveCrumbs = [
  {title: 'Archive', link: reverse(routes.archive.index)},
  {title: 'Routines', link: reverse(routes.archive.index, {tab: 'routines'})}
];

export default Wrapper;
