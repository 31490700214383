import {
  address,
  Address,
  Bubbles,
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  name,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import {Map} from '@management-ui/maps';
import AddCommentIcon from '@mui/icons-material/AddComment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import Box from '@mui/material/Box';
import {reverse} from 'named-urls';
import numeral from 'numeral';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {resellerName} from '../../../../formatters';
import routes from '../../../../routes';
import ContactCategorisationForm from '../../forms/ContactCategorisationForm';
import ContactForm from '../../forms/ContactForm';
import ContactLocationForm from '../../forms/ContactLocationForm';
import Actions from './Actions';
import Wrapper from './Wrapper';

export default function Contact({history, archive = false}) {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    contact: 'Sorry the contact could not be accessed',
    activity: 'Sorry the contact activity could not be loaded'
  }), []));

  const {
    entity: contact,
    setEntity: setContact,
    loadEntity: loadContact,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.contact.getContact,
    archive,
    useCallback((contact) => [{title: name(contact)}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('contact', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit', 'delete', 'categorisation', 'location', 'note']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setContact(updated);
    } else if (contact?.id) {
      loadContact(contact.id)
    }
  }, [toggleDialog, setContact, loadContact, contact]);

  const handleCategorisation = useCallback(() => {
    toggleDialog('categorisation', false);
    handleSaved();
  }, [toggleDialog, handleSaved]);

  const handleLocation = useCallback(() => {
    toggleDialog('location', false);
    handleSaved();
  }, [toggleDialog, handleSaved]);

  return (
    <Wrapper title="Contact" loading={loading} contact={contact} crumbs={crumbs} errors={errors}>
      {contact?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Contact Details"
              actions={!contact.archived ? [
                {title: 'Edit Contact', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {title: 'Delete Contact', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : [
                {
                  title: 'Restore Contact', icon: <RestoreFromTrashIcon/>, onClick: () => {
                    setLoading(true);
                    services.contact.restoreContact(contact.id).then(() => {
                      setLoading(false);
                      history.push(reverse(`${routes.contacts.detail}`, {id: contact.id}))
                    }).catch(() => setLoading(false));
                  }
                },
              ]}
              details={[
                {title: 'Name', value: name(contact)},
                {title: 'Email Address', value: contact.email ?? '-', link: !!contact.email},
                {title: 'Telephone Number', value: contact.phone ?? '-'},
                {title: 'Mobile Number', value: contact.mobile ?? '-'},
                {
                  title: 'Company',
                  value: contact.company ? contact.company.name : '-',
                  route: contact.company ? reverse(routes.companies.detail, {id: contact.company.id}) : ''
                },
                {title: 'Website Address', value: contact.website ?? '-', link: !!contact.website},
                {
                  title: 'Reseller',
                  value: contact.reseller ? resellerName(contact.reseller) : '-',
                  route: contact.reseller ? reverse(routes.contacts.detail, {id: contact.reseller.id}) : ''
                },
                {
                  title: 'Agent',
                  value: contact.agent ? name(contact.agent) : '-'
                }
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Contact"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <ContactForm
                        {...props}
                        contact={contact}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={contact ? `Are you sure you want to delete ${name(contact)}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.contact.deleteContact(contact.id).then(() => {
                          setLoading(false);
                          history.push(reverse(`${routes.contacts.index}`))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,
            <DetailPane
              title="Recent Activity"
              minHeight={400}
              actions={[
                ...(contact.archived ? [] : [{
                  title: 'Add Comment',
                  icon: <AddCommentIcon/>,
                  onClick: () => toggleDialog('note', true)
                }]),
                {
                  title: 'View all Activity', icon: <QuestionAnswerIcon/>, onClick: () => {
                    history.push(reverse(contact.archived ? routes.archive.contacts.activity : routes.contacts.activity, {id: contact.id}))
                  }
                }
              ]}
            ><Actions
              contact={contact}
              setLoading={setLoading}
              openDialogs={openDialogs}
              toggleDialog={toggleDialog}
              toggleError={toggleError}
            /></DetailPane>
          ]}/>

          <DetailColumns columns={[
            <DetailPane
              title="Categorisation"
              actions={!contact.archived ? [
                {title: 'Edit Categorisation', icon: <EditIcon/>, onClick: () => toggleDialog('categorisation', true)}
              ] : []}
              details={[
                {
                  title: 'Categories', value: (
                    contact.categories.length
                      ? <Bubbles bubbles={contact.categories.map(c => ({label: c.label, colour: c.colour, id: c.id}))}/>
                      : '-'
                  )
                },
                {
                  title: 'Source', value: (
                    contact.source
                      ? <Bubbles bubbles={[{label: contact.source.title, colour: contact.source.colour}]}/>
                      : '-'
                  )
                },
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Contact Categorisation"
                    open={openDialogs['categorisation'] ?? false}
                    onClose={() => toggleDialog('categorisation', false)}
                    render={(props) => (
                      <ContactCategorisationForm
                        {...props}
                        contact={contact}
                        onSaved={handleCategorisation}
                      />
                    )}
                  />
                )
              ]}
            />,
            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(contact.created_at)},
                {title: 'Last Updated', value: dateTime(contact.updated_at)},
                ...(contact.archived ? [{title: 'Archived', value: dateTime(contact.deleted_at)}] : [])
              ]}
            />,
          ]}/>

          <DetailColumns columns={[
            <DetailPane
              title="Location Details"
              actions={!contact.archived ? [
                {title: 'Edit Location', icon: <EditIcon/>, onClick: () => toggleDialog('location', true)}
              ] : []}
              details={[
                {
                  title: 'Address',
                  value: <Address
                    address={address(
                      contact,
                      ['address1', 'address2', 'address3', 'town', 'region', 'postcode', 'country']
                    )}/>
                },
                {title: 'Latitude', value: contact.latitude ? numeral(contact.latitude).format('0,0.00000') : '-'},
                {title: 'Longitude', value: contact.longitude ? numeral(contact.longitude).format('0,0.00000') : '-'},
              ]}
              wrapChildren={false}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Contact Location"
                    open={openDialogs['location'] ?? false}
                    onClose={() => toggleDialog('location', false)}
                    render={(props) => (
                      <ContactLocationForm
                        {...props}
                        contact={contact}
                        onSaved={handleLocation}
                      />
                    )}
                  />
                )
              ]}
            >
              {contact.latitude && contact.longitude ? (
                <Box flex={1}>
                  <Map
                    mapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
                    latitude={contact.latitude}
                    longitude={contact.longitude}
                    title={name(contact)}
                  />
                </Box>
              ) : null}
            </DetailPane>,
          ]}/>
        </>
      ) : null}
    </Wrapper>
  );
}
