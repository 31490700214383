import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const AffiliateOfferForm = forwardRef(({excludeProducts = [], index = -1, user, offer, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={{...offer, ...(offer.product ? {product_id: offer.product.id} : {quantity: 1})}}
      type="offer"
      fieldsComponent={(props) => <Fields excludeProducts={excludeProducts} {...props} />}
      onSave={useCallback((update) => services.user.id.saveOffer(user, update), [services, user])}
      onSaved={onSaved}
    />
  );
});

export default AffiliateOfferForm;
