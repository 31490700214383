import * as qs from 'qs';
import {API} from './API';

export class ContactRoutineService {
  async getTableRoutines(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getRoutines(query, page, length);
  }

  async getRoutines(query, page, length) {
    return (await API.getConnection())
      .get('contact-routines', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getRoutine(id, archive = false) {
    return (await API.getConnection())
      .get(`contact-routines/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveRoutine(data) {
    return (await API.getConnection())
      .post('contact-routines', data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async cancelRoutine(id) {
    return (await API.getConnection())
      .delete(`contact-routines/${id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async cancelMessage(routine, id) {
    return (await API.getConnection())
      .delete(`contact-routines/${routine.id}/messages/${id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
