import {DetailPane, FloatingButtons, useDialogs, useFloatingButtons} from '@management-ui/core';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Grid} from '@mui/material';
import Box from '@mui/material/Box';
import React, {useCallback, useMemo, useRef} from 'react';
import TemplateContentForm from '../../forms/TemplateContentForm';
import Preview from './Preview';
import Variables from './Variables';

export default function Editor({template, onSaved}) {
  const {openDialogs, toggleDialog} = useDialogs(['preview']);

  const {buttons, setButtonState} = useFloatingButtons(useMemo(() => ({
    preview: {
      colour: 'primary',
      states: {
        default: {
          label: 'Preview',
          icon: VisibilityIcon,
          spinning: false
        }
      }
    },
    save: {
      colour: 'secondary',
      states: {
        default: {
          label: 'Save',
          icon: SaveIcon,
          spinning: false
        },
        saving: {
          label: 'Saving...',
          spinning: true
        }
      }
    },
  }), []));

  const contentFormRef = useRef(null);

  const handleVariable = useCallback((variable) => {
    if (!template.archived) {
      contentFormRef.current.insertVariable(variable)
    }
  }, [template]);

  const handleButton = useCallback((button) => {
    switch (button) {
      case 'save':
        setButtonState('save', 'saving');
        contentFormRef.current.save();
        break;
      case 'preview':
        toggleDialog('preview', true);
        break;
      default:
        break;
    }
  }, [setButtonState, toggleDialog]);

  const handleSaved = useCallback((updated) => {
    setButtonState('save', 'default');
    toggleDialog('edit', false);
    onSaved(updated);
  }, [toggleDialog, onSaved, setButtonState]);

  return (
    <Box marginTop={2}>
      <Grid container spacing={2}>
        <Grid item xs={8} display="flex" component={Box}>
          <DetailPane
            title="Content"
            collapseChildrenHeight={false}
            dialogs={[
              (props) => (
                <Preview
                  {...props}
                  title="Edit Template"
                  open={openDialogs.preview ?? false}
                  onClose={() => toggleDialog('preview', false)}
                  template={template}
                />
              )
            ]}
          >
            <Box paddingX={1}>
              <TemplateContentForm
                ref={contentFormRef}
                template={template}
                onSaved={handleSaved}
              />
            </Box>
          </DetailPane>
        </Grid>
        <Grid item xs={4} display="flex" component={Box}>
          <Variables
            template={template}
            onSelectVariable={handleVariable}
            onTemplateUpdate={handleSaved}
          />
        </Grid>
      </Grid>
      {!template.archived ? <FloatingButtons buttons={buttons} onClick={handleButton}/> : null}
    </Box>
  );
}
