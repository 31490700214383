import * as qs from 'qs';
import {clearSources} from '../store/actions/options';
import {API} from './API';

export class SourceService {
  async getTableSources(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getSources(query, page, length);
  }

  async getSources(query, page, length) {
    return (await API.getConnection())
      .get('sources', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveSource(data) {
    let method = 'post';
    let url = 'sources';
    if (data.id) {
      method = 'put';
      url = `sources/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearSources());
        return response.data.data
      })
      .catch(error => API.handleError(error));
  }
}
