import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import TemplateForm from '../../forms/TemplateForm';
import Attachments from './Attachments';
import Editor from './Editor';
import Wrapper from './Wrapper';

export default function Template({history, archive = false}) {
  const services = useContext(ServiceContext);

  const {openDialogs, toggleDialog} = useDialogs(['edit']);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    template: 'Sorry the template could not be accessed'
  }), []));

  const {
    entity: template,
    setEntity: setTemplate,
    loadEntity: loadTemplate,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.template.getTemplate,
    archive,
    useCallback((template) => [{title: template.title}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('template', displayError), [toggleError])
  );

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setTemplate(updated);
    } else if (template?.id) {
      loadTemplate(template.id);
    }
  }, [template, loadTemplate, setTemplate, toggleDialog]);

  return (
    <Wrapper title="Template" loading={loading} template={template} crumbs={crumbs} errors={errors}>
      {template?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Template Details"
              actions={!template.archived ? [
                {title: 'Edit Template', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {title: 'Delete Template', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : [
                {
                  title: 'Restore Template', icon: <RestoreFromTrashIcon/>, onClick: () => {
                    setLoading(true);
                    services.template.restoreTemplate(template.id).then(() => {
                      setLoading(false);
                      history.push(reverse(`${routes.templates.detail}`, {id: template.id}))
                    }).catch(() => setLoading(false));
                  }
                },
              ]}
              details={[
                {title: 'Title', value: template.title},
                {title: 'Alias', value: template.alias}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Template"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <TemplateForm
                        {...props}
                        template={template}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={template ? `Are you sure you want to delete ${template.title}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.template.deleteTemplate(template.id).then(() => {
                          setLoading(false);
                          history.push(reverse(`${routes.templates.index}`))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,
            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(template.created_at)},
                {title: 'Last Updated', value: dateTime(template.updated_at)},
                ...(template.archived ? [{title: 'Archived', value: dateTime(template.deleted_at)}] : [])
              ]}
            />,
          ]}/>

          <Editor template={template} onSaved={handleSaved}/>
          <Attachments
            template={template}
            onTemplateUpdate={handleSaved}
          />
        </>
      ) : null}
    </Wrapper>
  );
}
