import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {Paper, Tab} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import CachedBlocks from './CachedBlocks';

const useStyles = makeStyles(() => ({
  tabs: {
    '& .MuiTabPanel-root': {
      padding: 0
    }
  }
}));

/**
 * A component to display a tab list with a <CachedBlocks/>.
 *
 * @module CachedTabs
 *
 * @param {ITab[]} tabs An array of tabs
 * @param {string} tab The id of the currently selected tab
 * @param {function} onChange A function to call when the selected tab is changed.
 * @param {object} renderProps An object of props passed to the tabs when they render
 *
 * @example
 * <CachedTabs
 *   tabs={[
 *     {id: 'tabA', slug: 'tab-a', title: 'Tab A', icon: <RecentActorsIcon/>, render: () => <p>Tab A</p>},
 *     {id: 'tabB', slug: 'tab-b', title: 'Tab B', icon: <RecentActorsIcon/>, render: () => <p>Tab B</p>}
 *   ]}
 *   tab="tabA"
 *   onChange={(selected) => console.log(selected)}
 * />
 *
 */
const CachedTabs = ({tabs, tab, onChange, renderProps = {}}) => {
  const classes = useStyles();
  return (
    <Paper className={classes.tabs}>
      <TabContext value={tab}>
        <TabList
          variant="scrollable"
          indicatorColor="primary"
          textColor="primary"
          onChange={(_e, selected) => onChange(tabs.find(t => t.id === selected))}>
          {tabs.map(t => <Tab key={t.id} label={t.title} icon={t.icon} value={t.id}/>)}
        </TabList>
      </TabContext>
      <CachedBlocks tabs={tabs} tab={tab} renderProps={renderProps}/>
    </Paper>
  );
};

CachedTabs.propTypes = {
  tabs: PropTypes.array,
  tab: PropTypes.string,
  onChange: PropTypes.func,
  renderProps: PropTypes.object
};

export default CachedTabs;
