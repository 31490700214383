import {DataTable} from '@management-ui/core';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Affiliations = ({user}) => {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Affiliations"
      columns={useMemo(() => [
        {title: 'Name', field: 'name', render: ({user}) => `${user.first_name} ${user.last_name}`},
        {title: 'Email', field: 'email', render: ({user}) => user.email},
        {title: 'Share', field: 'share'},
      ], [])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.user.id.getTableAffiliations(user, query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, user])}
    />
  );
};

export default Affiliations;
