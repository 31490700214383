import {prefixWithSeparator} from '@management-ui/core';
import {FormHelperText, Typography} from '@mui/material';
import {makeStyles, useTheme} from '@mui/styles';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import {draftToMarkdown} from 'markdown-draft-js';
import PropTypes from 'prop-types';
import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
// noinspection ES6CheckImport
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Controller, useFormContext} from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  label: {
    display: 'inline-block',
    color: theme.palette.grey['600'],
    margin: `${theme.spacing(2)} 0 ${theme.spacing(1)}`,
    padding: `0 ${theme.spacing(1)}`
  }
}));

/**
 * A rich text field using `draft-js` for use within the `BaseForm` component.
 *
 * **Methods:**
 *
 * `getState() : EditorState` - Retrieve the current editor state
 *
 * `setState(state: EditorState) : void` - Update the current editor state
 *
 * @module MarkdownField
 *
 * @param {string} markdownName The name of the field which will contain markdown
 * @param {string} rawName The name of the field which will contain raw data
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {object} fieldProps Any additional props for the [Editor](https://draftjs.org/docs/api-reference-editor)
 *
 * @example
 * <MarkdownField
 *   markdownName="body"
 *   rawName="raw"
 *   prefix={prefix}
 *   label="Body"
 *   id="body"
 *   rules={{required: 'Please enter some body content'}}
 * />
 *
 */
const MarkdownField = forwardRef((
  {
    markdownName,
    rawName,
    prefix = '',
    label,
    id = null,
    rules = {},
    fieldProps
  }, ref
) => {
  const classes = useStyles();
  const theme = useTheme();
  const {control, formState: {errors}, watch, setValue} = useFormContext();

  const prefixedMarkdownName = `${prefixWithSeparator(prefix)}${markdownName}`
  const prefixedRawName = `${prefixWithSeparator(prefix)}${rawName}`
  // noinspection JSCheckFunctionSignatures
  const rawValue = watch(prefixedRawName, '{}');
  const rawValueRef = useRef('{}');

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  useEffect(() => {
    if (rawValue && rawValue !== rawValueRef.current) {
      rawValueRef.current = rawValue;
      let newState;
      try {
        newState = EditorState.createWithContent(convertFromRaw(JSON.parse(rawValue)));
      } catch (e) {
        newState = EditorState.createEmpty();
      }
      if (editorState.getCurrentContent() !== newState.getCurrentContent()) {
        setEditorState(newState);
      }
    }
  }, [rawValue, editorState]);

  const handleChange = useCallback((state) => {
    const newContent = state.getCurrentContent();
    if (newContent !== editorState.getCurrentContent()) {
      const raw = convertToRaw(newContent);
      const serialisedRaw = JSON.stringify(raw);
      rawValueRef.current = serialisedRaw;
      setValue(prefixedRawName, serialisedRaw);
      setValue(prefixedMarkdownName, draftToMarkdown(raw, {}));
    }
    setEditorState(state);
  }, [editorState, prefixedMarkdownName, prefixedRawName, setValue]);

  useImperativeHandle(ref, () => ({
    getState() {
      return editorState;
    },
    setState(state) {
      setEditorState(state);
    }
  }));

  // noinspection JSUnresolvedFunction,JSUnresolvedVariable
  return (
    <Controller
      name={prefixedMarkdownName}
      control={control}
      rules={rules}
      render={() => (
        <>
          {label ? <Typography className={classes.label} variant="caption">{label}</Typography> : null}
          <Editor
            {...fieldProps}
            id={id ?? prefixedRawName}
            editorState={editorState}
            onEditorStateChange={handleChange}
            toolbar={{options: ['inline', 'list', 'textAlign', 'link', 'remove', 'history']}}
            wrapperStyle={{
              border: `1px solid ${theme.palette.action.disabled}`,
              borderRadius: theme.shape.borderRadius,
            }}
            toolbarStyle={{
              border: 0,
            }}
            editorStyle={{
              padding: `0 ${theme.spacing(2)}`,
            }}
          />
          {!!errors[prefixedMarkdownName] ?
            <FormHelperText>{errors[prefixedMarkdownName].message}</FormHelperText> : null}
        </>
      )}
    />
  )
});

MarkdownField.propTypes = {
  markdownName: PropTypes.string,
  rawName: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object
};

export default MarkdownField;
