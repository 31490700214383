import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar} from '@mui/material';
import Box from '@mui/material/Box';
import {makeStyles} from '@mui/styles';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {ServiceContext} from '../../../../components/Services';

const useStyles = makeStyles(theme => ({
  header: {
    color: theme.palette.primary.main,
    fontSize: '1em',
    marginBottom: 0,
    textTransform: 'uppercase',
  },

  frame: {
    border: 0,
  }
}));

export default function Preview({open, onClose, template}) {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const user = useSelector(state => state['auth'].user);
  const previewLoaded = useRef('');
  const [preview, setPreview] = useState(null);
  const [height, setHeight] = useState(0);
  const [sending, setSending] = useState(false);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (template && open) {
      const key = `${template.id}`;
      if (previewLoaded.current !== key) {
        previewLoaded.current = key;
        services.template.getPreview(template.id)
          .then(setPreview)
          .catch(() => setPreview(null));
      }
    }
  }, [services, template, open]);

  const handleLoad = (e) => {
    setHeight(e.target.contentWindow.document.body.scrollHeight);
  }

  const handleSendPreview = useCallback(() => {
    setSending(true);
    services.template.sendPreview(template.id).then(() => {
      setSent(true);
      setSending(false);
    }).catch(() => setSending(false));
  }, [services, template]);

  return (
    <>
      <Dialog open={open} onClose={() => onClose(false)} fullWidth={true} maxWidth="md">
        <DialogTitle id="form-dialog-title">Preview</DialogTitle>
        <DialogContent>
          {template ? (
            <Box display="flex" flexDirection="column">
              {preview ? (
                <>
                  <h4 className={classes.header}>Subject</h4>
                  <p>{preview.subject}</p>
                  <h4 className={classes.header}>Body</h4>
                  <iframe
                    title="Template Preview"
                    className={classes.frame}
                    onLoad={handleLoad}
                    srcDoc={preview.body}
                    height={`${height}px`}
                    sandbox="allow-same-origin"/>
                </>
              ) : null}
            </Box>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendPreview} color="primary" variant="contained">
            {sending ? 'Sending...' : `Send Preview to ${user.email}`}
          </Button>
          <Button onClick={() => onClose(false)} color="secondary" variant="outlined">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={sent} autoHideDuration={4000} onClose={() => setSent(false)}>
        <Alert onClose={() => setSent(false)} severity="success">
          The preview was successfully sent to {user.email}.
        </Alert>
      </Snackbar>
    </>
  );
}
