import {Bubbles} from '@management-ui/core';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SearchIcon from '@mui/icons-material/Search';
import {Typography} from '@mui/material';
import {makeStyles, useTheme} from '@mui/styles';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useOptions} from '../../../../hooks';

const useStyles = makeStyles(theme => ({
  holder: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
  },

  toggle: {
    alignItems: 'center',
    background: 'none',
    border: 'none',
    display: 'flex',
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

    '&:focus': {
      outline: 0,
    },
  },

  toggleIcon: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 18,
    display: 'flex',
    flex: '0 0 36px',
    height: 36,
    justifyContent: 'center',
    transition: '0.5s transform ease-in-out',
    width: 36,
  },

  toggleIconOpen: {
    transform: 'rotate(90deg)',
  },

  toggleLabel: {
    flex: 1,
    fontWeight: 700,
    paddingLeft: theme.spacing(2),
    textAlign: 'left',
  },

  container: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: '0.5s max-height ease-in-out',
  },

  inner: {
    display: 'flex',

    '& > div': {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  },

  label: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },

  query: {
    background: theme.palette.background.paper,
    borderRadius: 5,
    display: 'flex',
    padding: `0 ${theme.spacing(1)}`,

    '& input': {
      background: 'none',
      border: 0,
      flex: 1,
      padding: `${theme.spacing(1)} 0`,

      '&:focus': {
        outline: 0,
      }
    },

    '& > div': {
      alignItems: 'center',
      display: 'flex',
      flex: 0,
      justifyContent: 'center',
    }
  }
}));

export default function Filter({onChange}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [height, setHeight] = useState(0);
  /** @type {({current: HTMLElement})} */
  const innerRef = useRef();
  const theme = useTheme();

  const fieldOptions = useMemo(() => [
    {id: 'name', label: 'Name', colour: theme.palette.primary.main},
    {id: 'company', label: 'Company', colour: theme.palette.secondary.main},
    {id: 'phone', label: 'Phone Number', colour: theme.palette.primary.main},
    {id: 'mobile', label: 'Mobile Number', colour: theme.palette.secondary.main},
    {id: 'email', label: 'Email Address', colour: theme.palette.primary.main},
    {id: 'website', label: 'Website', colour: theme.palette.secondary.main},
    {id: 'address', label: 'Address', colour: theme.palette.primary.main},
    {id: 'agent', label: 'Agent', colour: theme.palette.secondary.main}
  ], [theme]);

  const [query, setQuery] = useState('');
  const [fields, setFields] = useState(fieldOptions.map(o => o.id));

  const categories = useOptions('categories');
  const sources = useOptions('sources');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [source, setSource] = useState(null);

  const updateFields = useCallback((field) => {
    let updated = [...fields];
    const index = fields.indexOf(field.id);
    if (index >= 0) {
      if (updated.length === fieldOptions.length) {
        updated = updated.splice(index, 1);
      } else {
        updated.splice(index, 1);
      }
      if (updated.length === 0) {
        updated = fieldOptions.map(o => o.id);
      }
    } else {
      updated.push(field.id);
    }
    setFields(updated);
  }, [fields, fieldOptions]);

  const updateCategories = useCallback((category) => {
    const updated = [...selectedCategories];
    const index = selectedCategories.indexOf(category.id);
    if (index >= 0) {
      updated.splice(index, 1);
    } else {
      updated.push(category.id);
    }
    setSelectedCategories(updated);
  }, [selectedCategories]);

  const calculateInnerHeight = () => {
    if (innerRef.current) {
      setHeight(innerRef.current.clientHeight);
    }
  }

  useEffect(() => {
    window.addEventListener('resize', calculateInnerHeight);
    return () => {
      window.removeEventListener('resize', calculateInnerHeight);
    }
  }, []);

  useEffect(() => {
    if (open) {
      calculateInnerHeight();
    }
  }, [open]);

  useEffect(() => {
    const filters = {};
    if (query && fields.length) {
      filters.fields = `${fields.join(',')}|${query.replace('|', '')}`
    }
    if (source) {
      filters.source = source;
    }
    if (selectedCategories.length) {
      filters.categories = selectedCategories;
    }
    onChange(filters);
  }, [query, fields, source, selectedCategories, onChange]);

  return (
    <div className={classes.holder}>
      <button className={classes.toggle} onClick={() => setOpen(!open)}>
        <span className={`${classes.toggleIcon} ${open ? classes.toggleIconOpen : ''}`}><ArrowForwardIosIcon/></span>
        <span className={classes.toggleLabel}>Filter</span>
      </button>
      <div className={classes.container} style={open ? {maxHeight: height} : {}}>
        <div ref={innerRef} className={classes.inner}>
          <div>
            <Typography className={classes.label} variant="body2">Query</Typography>
            <div className={classes.query}>
              <input value={query} onChange={(e) => setQuery(e.target.value)}/>
              <div>
                <SearchIcon/>
              </div>
            </div>
            <Typography className={classes.label} variant="body2">Search Fields</Typography>
            <Bubbles
              bubbles={fieldOptions}
              onSelect={(bubble) => updateFields(bubble)}
              selected={fields}
            />
          </div>
          <div>
            <Typography className={classes.label} variant="body2">Categories</Typography>
            <Bubbles
              bubbles={categories.map(c => ({label: c.label, colour: c.colour, id: c.id}))}
              onSelect={(bubble) => updateCategories(bubble)}
              selected={selectedCategories}
            />
            <Typography className={classes.label} variant="body2">Sources</Typography>
            <Bubbles
              bubbles={sources.map(s => ({label: s.title, colour: s.colour, id: s.id}))}
              onSelect={(bubble) => setSource((bubble.id !== source) ? bubble.id : null)}
              selected={source ? [source] : []}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
