import {ConfirmationDialog, DataTable, name, SquareButton} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {Close} from '@mui/icons-material';
import {Grid} from '@mui/material';
import moment from 'moment';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Text from '../../../../components/Text';
import routes from '../../../../routes';
import FollowUp from '../../components/FollowUp';
import Filter from './Filter';

const FollowUps = ({agent}) => {
  const history = useHistory();
  const services = useContext(ServiceContext);
  const [showDismiss, setShowDismiss] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);
  const [owner, setOwner] = useState('');
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const handleClose = useCallback((dismiss) => {
    if (dismiss && selectedAction) {
      services.contact.dismissAction(selectedAction)
        .then(() => tableRef.current.refresh())
        .catch(() => {
        });
    }
    setShowDismiss(false);
  }, [services, selectedAction]);

  const handleOwner = useCallback((changed) => {
    setOwner(changed);
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  }, []);

  return (
    <>
      <DataTable
        ref={tableRef}
        title="Pending Follow-ups"
        tableProps={{
          components: {
            Toolbar: useCallback((props) => (
              <>
                <MTableToolbar {...props}/>
                <Filter
                  owner={owner}
                  onChangeOwner={handleOwner}
                />
              </>
            ), [owner, handleOwner])
          }
        }}
        options={{
          paging: false,
          search: false
        }}
        columns={useMemo(() => [
          {title: 'Admin', field: 'follow_up_by', sorting: false, render: action => name(action.follow_up_by)},
          {title: 'Contact', field: 'contact', render: action => name(action.contact)},
          {title: 'Notes', field: 'data', render: action => <Text text={action.data.content ?? ''}/>},
          {title: 'Follow up', field: 'date', render: action => <FollowUp date={action.follow_up_on}/>},
          {
            title: 'Dismiss',
            field: 'Dismiss',
            sorting: false,
            width: 120,
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
            render: (item) => (
              <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
                <SquareButton
                  tooltip="Dismiss Follow-up"
                  icon={<Close/>}
                  onClick={() => {
                    setSelectedAction(item);
                    setShowDismiss(true);
                  }}
                />
              </Grid>
            )
          }
        ], [])}
        loadData={useCallback(query => new Promise((resolve, reject) => {
          services.contact.getActivity({
            ...query,
            ...(owner === 'mine' ? {user: agent.id} : {}),
            followUp: moment().add(2, 'weeks').startOf('day').format('YYYY-MM-DD')
          }, 1, 1000)
            .then(response => {
              resolve({
                data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
              });
            }).catch(reject);
        }), [services, agent, owner])}
        onRowClick={(action) => history.push(reverse(`${routes.contacts.activity}`, {id: action.contact.id}))}
      />
      <ConfirmationDialog
        open={showDismiss}
        onClose={handleClose}
        title="Dismiss Follow-up?"
        message="Are you sure you want to dismiss this follow-up?"
      />
    </>
  );
};

export default FollowUps;
