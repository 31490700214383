import * as qs from 'qs';
import {API} from './API';

export class BookingService {

  async getBookings(date, user) {
    return (await API.getConnection())
      .get('bookings', {
        params: {filter: {week: date.format('YYYY-MM-DD'), user: user.id}, ...{page: 1, length: 50}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async getBooking(id) {
    return (await API.getConnection())
      .get(`bookings/${id}`)
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveBooking(data) {
    let method = 'post';
    let url = 'bookings';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        return response.data.data
      })
      .catch(error => API.handleError(error));
  }

  async deleteBooking(booking) {
    return (await API.getConnection())
      .delete(`bookings/${booking.id}`)
      .then(() => {
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async getUpcoming(filters = {}) {
    return (await API.getConnection())
      .get('bookings', {
        params: {filter: {upcoming: 1, ...filters}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }
}
