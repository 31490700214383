import {DataTable, dateTime} from '@management-ui/core';
import {Link} from '@mui/material';
import * as React from 'react';
import {useCallback, useMemo} from 'react';
import AffiliateTokenForm from '../../forms/AffiliateTokenForm';

const URLS = [
  {title: 'Homepage', url: 'https://www.bubulexpert.com/'},
  {title: 'SMEs', url: 'https://www.bubulexpert.com/sme/'},
  {title: 'Accountants', url: 'https://www.bubulexpert.com/accountants/'}
];

const Tokens = ({user, onUpdateUser, loading}) => {
  return (
    <DataTable
      title="Links"
      options={{
        paging: false,
        search: false
      }}
      canRefresh={false}
      tableProps={{isLoading: loading}}
      columns={useMemo(() => [
        {title: 'Name', field: 'name'},
        {title: 'Unique URL Token', field: 'token'},
        {
          title: 'Example Links', field: 'links', render: data => (
            <ul>{URLS.map(({title, url}, index) => <li key={index}>
              <Link href={`${url}?affiliate=${data.token}`} target="_blank">{title}</Link>
            </li>)}</ul>
          )
        },
        {title: 'Date/Time Created', field: 'created_at', render: data => dateTime(data.created_at)},
      ], [])}
      data={user.tokens}
      newForm={{
        title: 'Add New Link',
        render: (props) => <AffiliateTokenForm user={user} token={{}} {...props}/>,
        onSaved: useCallback(saved => onUpdateUser(saved), [onUpdateUser])
      }}
      editForm={{
        render: (token, props) => <AffiliateTokenForm user={user} token={token} {...props}/>,
        onSaved: useCallback(saved => onUpdateUser(saved), [onUpdateUser])
      }}
    />
  );
};

export default Tokens;
