import moment from 'moment';
import * as qs from 'qs';
import {API} from './API';

export class ContactService {
  async getTableContacts(params, additional = {}, exportName = null) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getContacts(query, page, length, exportName);
  }

  async getContacts(query, page, length, exportName = null) {
    return (await API.getConnection())
      .get(`contacts${exportName ? '/export' : ''}`, {
        params: {...query, ...{page, length}, ...(exportName ? {name: exportName} : {})},
        paramsSerializer: params => qs.stringify(params),
        ...(exportName ? {responseType: 'blob'} : {})
      })
      .then(response => {
        if (exportName) {
          const url = window.URL.createObjectURL(new Blob([response.data], {type: 'octet/stream'}));
           const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        } else {
          return response.data
        }
      })
      .catch(error => API.handleError(error));
  }

  async getContact(id, archive = false) {
    return (await API.getConnection())
      .get(`contacts/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveContact(data) {
    const remove = ['latitude', 'longitude', 'source', 'country', 'reseller'];
    if (data.categories && data.categories.length && data.categories.filter(c => typeof c === 'object').length > 0) {
      remove.push('categories');
    }
    remove.forEach(field => {
      if (data.hasOwnProperty(field)) {
        delete data[field];
      }
    });
    let method = 'post';
    let url = 'contacts';
    if (data.id) {
      method = 'put';
      url = `contacts/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async getContactActivity(id, query, page, length) {
    return (await API.getConnection())
      .get(`contacts/${id}/activity`, {
        params: {...query, page, length},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveComment(id, comment, followUp, userID, page = 1, length = 5) {
    return (await API.getConnection())
      .post(`contacts/${id}/comment`, {
        comment,
        follow_up: followUp ? moment(followUp).format('YYYY-MM-DD') : null,
        user_id: userID
      }, {
        params: {page, length},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getActivity(query, page, length) {
    return (await API.getConnection())
      .get('contacts/activity', {
        params: {...query, page, length},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async dismissAction(action) {
    return (await API.getConnection())
      .put(`contacts/activity/${action.id}`, {})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getVariables() {
    return (await API.getConnection())
      .get('contacts/variables')
      .then(response => response.data)
      .catch(error => {
        return API.handleError(error);
      });
  }

  async deleteContact(id) {
    return (await API.getConnection())
      .delete(`contacts/${id}`)
      .then(response => {
        return response;
      })
      .catch(error => API.handleError(error));
  }

  async restoreContact(id) {
    return (await API.getConnection())
      .put(`contacts/${id}/restore`)
      .then(response => {
        return response;
      })
      .catch(error => API.handleError(error));
  }
}
