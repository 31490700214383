import {DateField, name, Panes, RelationAutocomplete, Select} from '@management-ui/core';
import React, {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {useOptions} from '../../../../hooks';

const Fields = ({licence = {}, prefix = '', multiplePanes = true, children}) => {
  const services = useContext(ServiceContext);

  const types = useOptions('licenceTypes', useCallback((list) => list.map(type => ({
    value: type.id,
    title: `${type.name} (${type.code})`
  })), []));

  return (
    <Panes
      entity={licence}
      prefix={prefix}
      panes={[
        {
          title: 'Details',
          fields: [
            <Select
              name="type_id"
              prefix={prefix}
              options={types}
              label="Type"
              rules={{required: 'Please select a licence type'}}
            />,
            <RelationAutocomplete
              valueName="user_id"
              titleName="user_name"
              prefix={prefix}
              label="User"
              loadOptions={query => (
                services.user.id.getUsers({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(user => ({title: name(user), value: user.id})))
                  .catch(() => [])
              )}
              rules={{required: 'Please select a user for this licence'}}
            />,
            <DateField
              name="expires_at"
              prefix={prefix}
              label="Expiry Date"
            />,
          ]
        }, {
          title: '',
          fields: []
        },
      ]}
      title={multiplePanes ? null : 'Licence'}
    >{children}</Panes>
  );
};

export default Fields;
