import {DataTable, dateTime} from '@management-ui/core';
import {Checkbox, Grid} from '@mui/material';
import * as React from 'react';
import {useContext, useMemo, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';

export default function Users() {
  const services = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);
  /** @type {({current: DataTable})} */
  let tableRef = useRef();
  return (
    <DataTable
      ref={tableRef}
      tableProps={{isLoading: loading}}
      title="Users"
      columns={useMemo(() => [
        {title: 'Name', field: 'name', render: data => `${data.first_name} ${data.last_name}`},
        {title: 'Email', field: 'email'},
        {title: 'Sync ID', field: 'sync_id'},
        {title: 'Date Created', field: 'created_at', render: data => dateTime(data.created_at)},
        {title: 'Last Updated', field: 'updated_at', render: data => dateTime(data.updated_at)},
        {
          title: 'Follow-ups Reminders?',
          field: 'Follow-ups Reminders?',
          sorting: false,
          width: 120,
          headerStyle: {
            textAlign: 'center',
          },
          cellStyle: {
            textAlign: 'center',
          },
          render: (user) => (
            <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
              <Checkbox
                checked={user.is_reminded}
                onChange={() => {
                  setLoading(true);
                  services.user.crm.saveUser({id: user.id, is_reminded: !user.is_reminded})
                    .then(() => {
                      setLoading(false);
                      tableRef.current.refresh()
                    })
                    .catch(() => setLoading(false));
                }}
              />
            </Grid>
          )
        }
      ], [services])}
      loadData={query => new Promise((resolve, reject) => {
        services.user.crm.getTableUsers(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
    />
  );
}
