import {ColouredLabel, DataTable, date, name, time} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {useTheme} from '@mui/styles';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Filter from './Filter';


const Appointments = ({agent}) => {
  const history = useHistory();
  const services = useContext(ServiceContext);
  const theme = useTheme();
  const [owner, setOwner] = useState('');
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  const handleOwner = useCallback((changed) => {
    setOwner(changed);
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  }, []);

  return (
    <DataTable
      ref={tableRef}
      title="Upcoming Appointments"
      options={{
        paging: false,
        search: false
      }}
      tableProps={{
        components: {
          Toolbar: useCallback((props) => (
            <>
              <MTableToolbar {...props}/>
              <Filter
                owner={owner}
                onChangeOwner={handleOwner}
              />
            </>
          ), [owner, handleOwner])
        }
      }}
      columns={useMemo(() => [
        {title: 'Admin', field: 'user', sorting: false, render: booking => name(booking.user)},
        {
          title: 'Contact', field: 'contact', render: booking => (
            <ColouredLabel
              label={name(booking.contact)}
              colour={booking.external_id ? theme.palette.info.main : theme.palette.warning.main}
            />
          )
        },
        {
          title: 'Date/Time',
          field: 'date',
          render: booking => `${date(booking.date)}: ${time(booking.start_at)} - ${time(booking.end_at)}`
        },
        {title: 'Meeting ID', field: 'meeting', render: booking => booking.external_id ?? '-'},
      ], [theme])}
      loadData={useCallback(() => new Promise((resolve, reject) => {
        services.booking.getUpcoming({
          ...(owner === 'mine' ? {user: agent.id} : {})
        })
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(reject);
      }), [services, agent, owner])}
      onRowClick={(booking) => history.push(reverse(`${routes.contacts.detail}`, {id: booking.contact.id}))}
    />
  );
};

export default Appointments;
