import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import * as React from 'react';
import routes from '../../../../routes';
import Table from './Table';

const TABS = [
  {
    id: 'pending',
    slug: 'pending',
    title: 'Pending',
    icon: <PriorityHighIcon/>,
    render: (props) => <Table mode="pending" {...props} />
  },
  {
    id: 'approved',
    slug: 'approved',
    title: 'Approved',
    icon: <CheckIcon/>,
    render: (props) => <Table mode="approved" {...props} />
  },
  {
    id: 'rejected',
    slug: 'rejected',
    title: 'Rejected',
    icon: <CloseIcon/>,
    render: (props) => <Table mode="rejected" {...props} />
  }
];

const AffiliateRegistrations = () => {
  const {tab, handleTab} = useTab(TABS, routes.affiliateRegistrations.tab);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Affiliate Registrations'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
};

export default AffiliateRegistrations;
