import {BarSelector, Breadcrumbs, Key, name, Title} from '@management-ui/core';
import {useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useOptions} from '../../../../hooks';
import Calendar from './Calendar';

export default function Appointments() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const currentUser = useSelector(state => state['auth'].user);
  const formatAdmin = useCallback((format) => ({...format, title: name(format), value: format.id}), []);
  const admins = useOptions('admins', useCallback((list) => list.map(formatAdmin), [formatAdmin]));

  useEffect(() => {
    if (!user && currentUser) {
      setUser(formatAdmin(currentUser));
    }
  }, [user, currentUser, formatAdmin]);

  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Appointments'}]}/>
      <Title title="Appointments"/>
      {user && admins.length ? (
        <>
          <Box marginTop={1}>
            <Paper>
              <BarSelector list={admins} selected={user} onChange={setUser} loading={loading}/>
            </Paper>
          </Box>
          <Key items={[
            {title: 'Available', colour: theme.palette.success.main},
            {title: 'Booked', colour: theme.palette.warning.main},
            {title: 'Booked (with Zoom Meeting)', colour: theme.palette.info.main}
          ]}/>
          <Calendar users={admins} user={user} onLoading={setLoading}/>
        </>
      ) : null}
    </div>
  );
}
