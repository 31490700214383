import {makeStyles} from '@mui/styles';


const useTableStyles = makeStyles((theme) => {
  const row = {
    display: 'flex',
    borderBottom: `2px solid ${theme.palette.grey['100']}`
  };

  const column = {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    padding: theme.spacing(1),
  };

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      transition: '0.25s opacity ease-in-out',
      width: '100%'
    },
    loading: {
      opacity: 0.4,
      pointerEvents: 'none'
    },
    row,
    actions: {
      ...row,
      justifyContent: 'flex-end',
      padding: `0 0 ${theme.spacing(1)}`
    },
    noRecords: {
      ...row,
      padding: theme.spacing(1),
      justifyContent: 'center',
    },
    column,
    header: {
      ...column,
      fontWeight: '700',
    },
    action: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 50px',
      justifyContent: 'center',
      padding: 5,
      width: 50
    }
  };
});

export default useTableStyles;
