import {DateField, name, Panes, RelationAutocomplete, Select, TimeRangeSelector} from '@management-ui/core';
import React, {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {useOptions} from '../../../../hooks';
import ContactForm from '../ContactForm';

export default function Fields({booking = {}, prefix = '', multiplePanes = true, children}) {
  const services = useContext(ServiceContext);

  const admins = useOptions('admins', useCallback((list) => {
    return list.map(admin => ({
      value: admin.id,
      title: name(admin)
    }));
  }, []));

  const handleReset = useCallback((subject) => {
    if (subject.user) {
      subject.user_id = subject.user.id;
      delete subject.user;
    }
    if (subject.contact) {
      subject.contact_id = subject.contact.id;
      subject.contact_name = name(subject.contact);
      delete subject.contact;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={booking}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'When',
          fields: [
            <DateField
              name="date"
              prefix={prefix}
              label="Select a date"
              rules={{required: 'Please select a date'}}
            />,
            <TimeRangeSelector
              fromName="start_at"
              toName="end_at"
              prefix={prefix}
              label="Select a time range"
            />
          ]
        },
        {
          title: 'Who',
          fields: [
            <Select
              name="user_id"
              prefix={prefix}
              options={admins}
              label="Administrator"
            />,
            <RelationAutocomplete
              valueName="contact_id"
              titleName="contact_name"
              prefix={prefix}
              label="Contact"
              loadOptions={query => (
                services.contact.getContacts({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(contact => ({title: name(contact), value: contact.id})))
                  .catch(() => [])
              )}
              addForm={{
                title: 'Add New Contact',
                onSaved: (contact) => ({title: name(contact), value: contact.id}),
                render: (props) => (
                  <ContactForm
                    {...props}
                    type="bookingContact"
                    contact={{}}
                  />
                )
              }}
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Booking'}
    >{children}</Panes>
  );
}
