import {Breadcrumbs, DataTable, dateTime, name} from '@management-ui/core';
import {Paper} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import ContactRoutineForm from '../../forms/ContactRoutineForm';

const Activated = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((routine) => {
    history.push(reverse(routes.routines.activated.detail, {id: routine.id}));
  }, [history]);

  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Routines', link: routes.routines.index}, {title: 'Activated'}]}/>
      <Paper>
        <DataTable
          title="Activated Routines"
          columns={[
            {title: 'Routine', field: 'routine', render: data => data.routine.title},
            {title: 'Contact', field: 'routine', render: data => name(data.contact)},
            {title: 'Date Created', field: 'created_at', render: data => dateTime(data.created_at)},
            {title: 'Date Modified', field: 'updated_at', render: data => dateTime(data.updated_at)}
          ]}
          loadData={query => new Promise((resolve, reject) => {
            services.contactRoutine.getTableRoutines(query)
              .then(response => {
                resolve({
                  data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
                });
              }).catch(() => {
              reject();
            });
          })}
          onRowClick={goToDetail}
          newForm={{
            title: 'Schedule a New Routine',
            render: (props) => <ContactRoutineForm routine={{}} {...props}/>,
            onSaved: goToDetail
          }}
        />
      </Paper>
    </div>
  );
};

export default Activated;
