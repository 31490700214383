import {name} from '@management-ui/core';

export function resellerName(entity) {
  return `${name(entity)}${entity.company ? ` (${entity.company.name})` : ''}`;
}

export function productType(type) {
  switch (type) {
    case 'direct':
      return 'Direct';
    case 'reseller':
      return 'Reseller';
    default:
      return '';
  }
}

export function lengthInDays(length) {
  return length && length > 0 ? `${length} day${length === 1 ? '' : 's'}` : '-';
}
