import {API} from './API';

export class SettingsService {

  constructor(system = 'id') {
    this.system = system;
  }

  async getSettings() {
    return (await API.getConnection(this.system))
      .get('settings')
      .then(response => response.data instanceof Array ? {} : response.data)
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveSettings(settings) {
    return (await API.getConnection(this.system))
      .put('settings', {settings})
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }
}
