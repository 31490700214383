import {styled, useTheme} from '@mui/material';
import React, {useMemo} from 'react';

const Container = styled('div')(({theme}) => ({
  borderBottom: `2px solid ${theme.palette.grey['100']}`,
  borderTop: `2px solid ${theme.palette.grey['100']}`,
  display: 'flex',
  flex: 1,
  width: '100%',

  '& > span': {
    alignItems: 'center',
    display: 'flex',
    flex: 0,
    padding: `0 ${theme.spacing(2)}`,
  }
}));

const Owners = styled('ul')(({theme}) => ({
  display: 'flex',
  flex: 1,
  listStyle: 'none',
  margin: 0,
  padding: `0 ${theme.spacing(2)} 0 0`,
}));

const Owner = styled('li')(({theme}) => ({
  background: 'var(--owner-background-colour)',
  borderLeft: `2px solid ${theme.palette.grey['100']}`,
  color: 'var(--owner-text-colour)',
  cursor: 'pointer',
  fontWeight: '700',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

  '&:last-of-owner': {
    borderRight: `2px solid ${theme.palette.grey['100']}`,
  },

  '&.selected': {
    background: 'var(--owner-text-colour)',
    color: 'var(--owner-background-colour)',
  }
}));

const Filter = ({owner, onChangeOwner}) => {
  const theme = useTheme();
  const owners = useMemo(() => [
    {
      title: 'All',
      key: '',
      textColour: theme['palette'].primary.main,
      backgroundColour: theme['palette'].common.white,
    },
    {
      title: 'Mine',
      key: 'mine',
      textColour: theme['palette'].secondary.main,
      backgroundColour: theme['palette'].common.white,
    }
  ], [theme]);

  return (
    <Container>
      <span>Displaying:</span>
      <Owners>
        {owners.map(({title, key, textColour, backgroundColour}, index) => (
          <Owner
            key={index}
            className={owner === key ? 'selected' : ''}
            style={{'--owner-text-colour': textColour, '--owner-background-colour': backgroundColour}}
            onClick={() => owner === key ? null : onChangeOwner(key)}
          >
            {title}
          </Owner>
        ))}
      </Owners>
    </Container>
  );
}

export default Filter;
