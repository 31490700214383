import {ConfirmationDialog, date, dateTime} from '@management-ui/core';
import {Close} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import useTableStyles from '../../../../components/tableStyles';

const Messages = ({routine = null, onUpdateRoutine}) => {
  const classes = useTableStyles();
  const services = useContext(ServiceContext);
  const [showCancel, setShowCancel] = useState(false);
  const [selected, setSelected] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    if (routine) {
      setMessages(routine.messages)
    }
  }, [routine]);

  const handleCancel = useCallback((confirmed) => {
    setShowCancel(false);
    if (confirmed) {
      setLoading(true);
      services.contactRoutine.cancelMessage(routine, selected.id).then(updated => {
        setLoading(false);
        onUpdateRoutine(updated);
      }).catch(() => setLoading(false));
    }
  }, [services, routine, selected, onUpdateRoutine]);

  const handleStatus = useCallback((message) => {
    if (message['cancelled_at']) {
      return `Cancelled on ${dateTime(message['cancelled_at'], ' @ ')}`;
    } else if (message['sent_at']) {
      return `Sent on ${dateTime(message['sent_at'], ' @ ')}`;
    } else {
      return `Scheduled to send on ${date(message['send_at'])}`;
    }
  }, []);

  return (
    <div className={[classes.container, loading ? classes.loading : ''].join(' ')}>
      <div className={classes.row}>
        <div className={classes.header}>Template</div>
        <div className={classes.header}>Status</div>
        <div className={classes.action}/>
      </div>
      {(messages.length > 0 ? (
        messages.map((message, index) => (
          <div key={index} className={classes.row}>
            <div className={classes.column}>{message.template.title}</div>
            <div className={classes.column}>
              {handleStatus(message)}
            </div>
            <div className={classes.action}>
              {!message['sent_at'] && !message['cancelled_at'] ? (
                <Tooltip title="Cancel Message">
                  <IconButton onClick={() => setSelected(message) || setShowCancel(true)}>
                    <Close/>
                  </IconButton>
                </Tooltip>
              ) : null}
            </div>
          </div>
        ))
      ) : (
        <div className={classes.noRecords}>There are no messages associated with this routine</div>
      ))}
      <ConfirmationDialog
        title="Cancel Message"
        message="Are you sure you want to cancel this message?"
        open={showCancel}
        onClose={handleCancel}
      />
    </div>
  );
};

export default Messages;
