import {name, WeeklySlotCalendar} from '@management-ui/core';
import {useTheme} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import moment from 'moment';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Booking from './Booking';

export default function Calendar({user, onLoading: setLoading}) {
  const theme = useTheme();
  const services = useContext(ServiceContext);
  const [week, setWeek] = useState(moment().startOf('isoWeek'));
  const [bookings, setBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);

  const getColour = useCallback((booking) => {
    if (booking.external_id) {
      return theme.palette.info.main;
    } else if (booking.contact) {
      return theme.palette.warning.main;
    } else {
      return theme.palette.success.main;
    }
  }, [theme]);

  const formatBooking = useCallback((booking) => ({
    ...booking,
    title: booking.contact ? name(booking.contact) : '',
    when: {
      date: moment(booking.date),
      start: moment(booking.start_at),
      end: moment(booking.end_at),
    },
    user_id: booking.user ? booking.user.id : 0,
    colour: getColour(booking)
  }), [getColour]);

  const loadBookings = useCallback(() => {
    setLoading(true);
    services.booking
      .getBookings(week, user)
      .then(response => {
        setBookings(response.data.map(b => formatBooking(b)));
        setLoading(false);
      })
      .catch(() => {
        setBookings([]);
        setLoading(false);
      });
  }, [week, user, services, formatBooking, setLoading]);

  useEffect(() => {
    if (week && user) {
      loadBookings();
    }
  }, [week, user, loadBookings]);

  const handleBooking = useCallback(async (day, booking, events) => {
    const when = {date: day, start: booking, end: booking.clone().add(1, 'hour')};
    const date = when.date.toISOString();
    const start = when.start.toISOString();
    const end = when.end.toISOString();
    if (user) {
      const overlaps = events.filter(e => e.user_id === user.id);
      if (overlaps.length < 1) {
        setSelectedBooking({
          id: 0,
          user_id: user.id,
          date: date,
          start_at: start,
          end_at: end,
          when
        });
      }
    } else {
      setSelectedBooking({
        id: 0,
        user_id: 0,
        date: date,
        start_at: start,
        end_at: end,
        when
      });
    }
  }, [user]);

  const handleSelectEvent = (event) => {
    setSelectedBooking(event);
  };

  return (
    <Box marginTop={1}>
      <Paper>
        <WeeklySlotCalendar
          week={week}
          events={bookings}
          onSelectWeek={setWeek}
          onSelectSlot={handleBooking}
          onSelectEvent={handleSelectEvent}
        />
        <Booking
          slot={selectedBooking}
          onLoading={setLoading}
          onSaved={loadBookings}
          onDeleted={loadBookings}
        />
      </Paper>
    </Box>
  )
}
