import {DataTable, dateTime, name} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {DownloadForOffline} from '@mui/icons-material';
import {Box, Button, Typography} from '@mui/material';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import NewContactForm from '../../forms/NewContactForm';
import Filter from './Filter';

export default function Table({title = null, agent = null, company = null, showFilter = true, archive = false}) {
  const services = useContext(ServiceContext);
  const history = useHistory();
  /** @type {({current: DataTable})} */
  let tableRef = useRef();
  const lastQuery = useRef(null);
  const filters = useRef({});
  const subject$ = useRef(new BehaviorSubject({}));

  const goToDetail = useCallback((contact) => {
    history.push(reverse(`${archive ? routes.archive.contacts.detail : routes.contacts.detail}`, {id: contact.id}));
  }, [history, archive]);

  const handleFilters = (updated) => {
    subject$.current.next(updated);
  };

  const handleExport = useCallback(() => {
    let title = [];
    if (agent) {
      title.push(name(agent));
    }
    if (company) {
      title.push(company.name);
    }
    if (archive) {
      title.push('Archived');
    }
    title.push('Contacts');
    services.contact.getTableContacts(lastQuery.current, {
      ...filters.current,
      ...(agent ? {agent: agent.id} : {}),
      ...(company ? {company: company.id} : {}),
      ...(archive ? {archive: 'only'} : {})
    }, title.join(' - '))
      .then(() => null)
      .catch(() => null);
  }, [services, agent, company, archive]);

  useEffect(() => {
    const subscription = subject$.current
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((updated) => {
        if (JSON.stringify(updated) !== JSON.stringify(filters.current)) {
          filters.current = updated;
          if (tableRef.current) {
            tableRef.current.refresh();
          }
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <DataTable
      ref={tableRef}
      title={(
        <Box display="flex">
          <Typography marginRight={2} variant="h6">{title ?? 'Contacts'}</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadForOffline/>}
            onClick={handleExport}
            size="small"
          >
            Export
          </Button>
        </Box>
      )}
      options={{search: !showFilter}}
      components={{
        'Toolbar': props => (
          <div>
            <MTableToolbar {...props} />
            {showFilter ? <Filter onChange={handleFilters}/> : null}
          </div>
        )
      }}
      columns={[
        {title: 'Name', field: 'name', render: contact => name(contact)},
        {title: 'Email', field: 'email'},
        ...(agent ? [] : [{title: 'Agent', field: 'agent', render: data => data.agent ? name(data.agent) : '-'}]),
        ...(company ? [] : [{
          title: 'Company',
          field: 'company',
          render: data => data.company ? data.company.name : '-'
        }]),
        {title: 'Date Added', field: 'created_at', render: data => dateTime(data.created_at)},
        ...(archive ? [{title: 'Archived', field: 'deleted_at', render: data => dateTime(data.deleted_at)}] : [])
      ]}
      loadData={query => new Promise((resolve, reject) => {
        lastQuery.current = query;
        services.contact.getTableContacts(
          query,
          {
            ...filters.current,
            ...(agent ? {agent: agent.id} : {}),
            ...(company ? {company: company.id} : {}),
            ...(archive ? {archive: 'only'} : {})
          }
        )
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={goToDetail}
      newForm={archive ? null : {
        title: 'Add New Contact',
        render: (props) => <NewContactForm contact={{}} {...props}/>,
        onSaved: goToDetail
      }}
    />
  );
}
