import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import Table from './Table';

const Routines = () => {
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Routines'}]}/>
      <Paper>
        <Table/>
      </Paper>
    </div>
  );
};

export default Routines;
