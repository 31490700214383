import {BaseForm, name} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const LicenceForm = forwardRef(({licence, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={{
        ...licence,
        ...(licence.user ? {user_id: licence.user.id, user_name: name(licence.user)} : {}),
        ...(licence.type ? {type_id: licence.type.id} : {})
      }}
      type="licence"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({id, user_id, type_id, expires_at}) => services.licence.saveLicence({id, user_id, type_id, expires_at})}
      onSaved={onSaved}
    />
  );
});

export default LicenceForm;
