const routes = {
  // Access
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',

  // Admin
  dashboard: '/',
  contacts: {
    index: '/contacts',
    detail: '/contacts/:id',
    activity: '/contacts/:id/activity'
  },
  companies: {
    index: '/companies',
    detail: '/companies/:id'
  },
  templates: {
    index: '/templates',
    detail: '/templates/:id'
  },
  routines: {
    index: '/routines',
    detail: '/routines/:id',
    activated: {
      index: '/routines/activated',
      detail: '/routines/activated/:id',
    }
  },
  appointments: '/appointments',
  licencing: {
    index: '/licencing/:tab?',
    products: {
      detail: '/licencing/products/:id'
    },
    licenceTypes: {
      detail: '/licencing/licence-types/:id'
    }
  },
  users: {
    index: '/users',
    verify: '/users/verify',
    detail: '/users/:id',
    reseller: '/users/:id/reseller',
    affiliate: '/users/:id/affiliate',
  },
  affiliateRegistrations: {
    index: '/affiliate-registrations',
    tab: '/affiliate-registrations/:tab?',
  },
  administrators: '/administrators',
  management: {
    index: '/management',
    tab: '/management/:tab?'
  },
  crm: {
    index: '/crm',
    tab: '/crm/:tab?'
  },
  identity: {
    index: '/identity',
    tab: '/identity/:tab?'
  },
  archive: {
    index: '/archive/:tab?',
    contacts: {
      detail: '/archive/contacts/:id',
      activity: '/archive/contacts/:id/activity'
    },
    templates: {
      detail: '/archive/templates/:id'
    }
  }
};

export default routes;
