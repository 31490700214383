import {dateTime, DetailColumns, DetailPane, FormDialog, useDetail, useDialogs, useErrors} from '@management-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import {Box} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import LicenceTypeForm from '../../forms/LicenceTypeForm';
import Associates from './Associates';
import Upgrades from './Upgrades';
import Wrapper from './Wrapper';

const LicenceType = () => {
  const services = useContext(ServiceContext);

  const {openDialogs, toggleDialog} = useDialogs(['edit']);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    type: 'Sorry the type could not be accessed'
  }), []));

  const {
    entity: type,
    setEntity: setType,
    loadEntity: loadType,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.licenceType.getType,
    false,
    useCallback((type) => [{title: type.name}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('type', displayError), [toggleError])
  );

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setType(updated);
    } else if (type?.id) {
      loadType(type.id);
    }
  }, [type, loadType, setType, toggleDialog]);

  return (
    <Wrapper title="Licence Type" loading={loading} type={type} crumbs={crumbs} errors={errors}>
      {type?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Type Details"
              actions={[
                {title: 'Edit Licence Type', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)}
              ]}
              details={[
                {title: 'Name', value: type.name},
                {title: 'Code', value: type.code}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Licence Type"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <LicenceTypeForm
                        {...props}
                        type={type}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
              ]}
            />,

            <Box display="flex" flexDirection="column" width="100%">
              <Box width="100%" display="flex" flex={1} marginBottom={1}>
                <Upgrades type={type} onLoading={setLoading} onTypeUpdated={setType}/>
              </Box>
              <Box width="100%" display="flex" flex={1} marginBottom={1}>
                <Associates type={type} onLoading={setLoading} onTypeUpdated={setType}/>
              </Box>
              <Box width="100%" display="flex" flex={1} marginTop={1}>
                <DetailPane
                  title="Admin Details"
                  details={[
                    {title: 'Created', value: dateTime(type.created_at)},
                    {title: 'Last Updated', value: dateTime(type.updated_at)}
                  ]}
                />
              </Box>
            </Box>,
          ]}/>
        </>
      ) : null}
    </Wrapper>
  );
}

export default LicenceType;
