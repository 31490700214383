import {CircularProgress, Paper, Toolbar, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {reverse} from 'named-urls';
import qs from 'qs';
import React, {useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../components/Services';
import routes from '../../../routes';

const useStyles = makeStyles(theme => ({
  title: {
    flexGrow: 1,
  },
  error: {
    color: theme.palette.error.main,
    padding: theme.spacing(3)
  }
}));

const StripeVerification = ({location, history}) => {
  const classes = useStyles();
  const services = useContext(ServiceContext);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const query = qs.parse(location.search.replace('?', ''));
    if (query.code && query.state) {
      setLoading(true);
      services.user.id.verifyStripeAccount(query.code, query.state).then(verified => {
        history.push(reverse(`${routes.users.detail}`, {id: verified.id}))
      }).catch(() => {
        setLoading(false);
        setError('There has been a problem verifying the account. Please try again.');
      })
    } else {
      history.push(`${routes.users.index}`);
    }
  }, [history, services, location.search]);

  let controls = null;
  if (loading) {
    controls = <CircularProgress/>;
  }
  let errorLabel = null;
  if (error) {
    errorLabel = <p className={classes.error}>{error}</p>;
  }

  return (
    <Paper>
      <Toolbar>
        <Typography className={classes.title} component="h2" variant="h5">Completing Account Setup</Typography>
        {controls}
      </Toolbar>
      {errorLabel}
    </Paper>
  );
};

export default StripeVerification;
