import {dateTime, DetailColumns, DetailPane, FormDialog, useDetail, useDialogs, useErrors} from '@management-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import {Paper} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import CompanyForm from '../../forms/CompanyForm';
import ContactsTable from '../Contacts/Table';
import Wrapper from './Wrapper';

export default function Company() {
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    company: 'Sorry the company could not be accessed'
  }), []));

  const {
    entity: company,
    setEntity: setCompany,
    loadEntity: loadCompany,
    crumbs,
    loading
  } = useDetail(
    services.company.getCompany,
    false,
    useCallback((company) => [{title: company.name}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('company', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['edit']);

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setCompany(updated);
    } else if (company?.id) {
      loadCompany(company.id)
    }
  }, [toggleDialog, setCompany, loadCompany, company]);

  return (
    <Wrapper title="Company" loading={loading} crumbs={crumbs} errors={errors}>
      {company?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Company Details"
              actions={[
                {title: 'Edit Company', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)}
              ]}
              details={[
                {title: 'Name', value: company.name}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Company"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <CompanyForm
                        {...props}
                        company={company}
                        onSaved={handleSaved}/>
                    )}
                  />
                )
              ]}
            />,

            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(company.created_at)},
                {title: 'Last Updated', value: dateTime(company.updated_at)}
              ]}
            />,
          ]}/>

          <Paper marginTop={2} component={Box}>
            <ContactsTable
              title="Associated Contacts"
              showFilter={false}
              company={company}
            />
          </Paper>

        </>
      ) : null}
    </Wrapper>
  );
}
