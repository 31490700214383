import {FormDialog, fullDate, useDialogs} from '@management-ui/core';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import BookingForm from '../../forms/BookingForm';

export default function Booking({slot: selectedSlot, onLoading: setLoading, onSaved, onDeleted}) {
  const services = useContext(ServiceContext);
  const [slot, setSlot] = useState(null);
  const {openDialogs, toggleDialog} = useDialogs(['booking']);

  useEffect(() => {
    if (selectedSlot) {
      setSlot(selectedSlot);
      toggleDialog('booking', true);
    } else {
      toggleDialog('booking', false);
      setTimeout(() => setSlot({}), 1000);
    }
  }, [selectedSlot, toggleDialog]);

  const handleDelete = useCallback(() => {
    setLoading(true);
    services.booking.deleteBooking(slot).then(() => {
      setLoading(false);
      toggleDialog('booking', false);
      onDeleted();
    }).catch(() => setLoading(false));
  }, [services, toggleDialog, slot, onDeleted, setLoading]);

  const handleSave = (updated) => {
    if (updated.hasOwnProperty('when')) {
      delete updated.when;
    }
    return updated;
  };

  const handleSaved = useCallback((saved) => {
    toggleDialog('booking', false);
    setSlot({});
    onSaved(saved);
  }, [toggleDialog, onSaved]);

  return (
    <FormDialog
      title={slot?.when?.date ? fullDate(slot.when.date) : ''}
      open={openDialogs.booking ?? false}
      onClose={() => toggleDialog('booking', false)}
      render={(props) => (
        <BookingForm
          {...props}
          booking={slot}
          onSave={handleSave}
          onSaved={handleSaved}
        />
      )}
      additionalButtons={slot?.id ? [{label: 'Delete', onClick: handleDelete}] : []}
    />
  );
}
