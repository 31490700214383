import {Breadcrumbs, ErrorPopup, Title} from '@management-ui/core';
import React from 'react';
import routes from '../../../../routes';


const Wrapper = ({title, loading, titleControls, crumbs, errors, children}) => {
  return (
    <>
      <Breadcrumbs crumbs={crumbs}/>
      <Title title={title} loading={loading} controls={titleControls}/>
      {children}
      <ErrorPopup errors={['routine']} messages={errors}/>
    </>
  );
}

Wrapper.standardCrumbs = [
  {title: 'Routines', link: routes.routines.index},
  {title: 'Activated', link: routes.routines.activated.index}
];

export default Wrapper;
