import {ConfirmationDialog, DataTable, SquareButton, useDialogs} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import GetAppIcon from '@mui/icons-material/GetApp';
import {Grid, Paper} from '@mui/material';
import Box from '@mui/material/Box';
import React, {useCallback, useContext, useMemo, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import TemplateAttachmentForm from '../../forms/TemplateAttachmentForm';

export default function Attachments({template, onTemplateUpdate}) {
  const services = useContext(ServiceContext);
  const [loading, setLoading] = useState(false);
  const [selectedDelete, setSelectedDelete] = useState(null);

  const {openDialogs, toggleDialog} = useDialogs(['delete']);

  const handleDownload = useCallback((attachment) => {
    setLoading(true);
    services.template.downloadAttachment(template.id, attachment.id)
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [services, setLoading, template]);

  const handleDelete = useCallback((attachment) => {
    setSelectedDelete(attachment);
    toggleDialog('delete', true);
  }, [toggleDialog]);

  const handleDeleteConfirmed = useCallback((confirmed, attachment) => {
    setSelectedDelete(null);
    toggleDialog('delete', false);
    if (confirmed) {
      setLoading(true);
      services.template.deleteAttachment(template, attachment).then((updated) => {
        setLoading(false);
        onTemplateUpdate({...template, attachments: updated});
      }).catch(() => setLoading(false));
    }
  }, [services, setLoading, template, onTemplateUpdate, toggleDialog]);

  const handleSaved = useCallback((attachments) => {
    onTemplateUpdate({...template, attachments});
  }, [template, onTemplateUpdate]);

  return (
    <Paper marginTop={2} marginBottom={8} component={Box}>
      <DataTable
        title="Attachments"
        options={{
          search: false,
          paging: false
        }}
        columns={useMemo(() => [
          {title: 'Name', field: 'name', sorting: false},
          ...[
            {title: 'Download', icon: <GetAppIcon/>, handler: handleDownload},
            {title: 'Delete', icon: <DeleteIcon/>, handler: handleDelete}
          ].map(column => ({
            title: column.title,
            field: column.title,
            sorting: false,
            width: 120,
            headerStyle: {
              textAlign: 'center',
            },
            cellStyle: {
              textAlign: 'center',
            },
            render: (item) => (
              <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
                <SquareButton
                  tooltip={`${column.title} Attachment`}
                  icon={column.icon}
                  onClick={() => column.handler(item)}
                />
              </Grid>
            )
          }))
        ], [handleDownload, handleDelete])}
        canRefresh={false}
        data={template.attachments}
        newForm={!template.archived ? {
          title: 'Add New Attachment',
          render: (props) => (
            <TemplateAttachmentForm template={template} attachment={{}} {...props}/>
          ),
          onSaved: handleSaved
        } : null}
        editForm={!template.archived ? {
          render: (attachment, props) => (
            <TemplateAttachmentForm template={template} attachment={attachment} {...props}/>
          ),
          onSaved: handleSaved
        } : null}
        tableProps={{isLoading: loading}}
      />
      <ConfirmationDialog
        open={!!openDialogs.delete}
        title="Delete"
        message="Are you sure you want to delete this attachment"
        onClose={handleDeleteConfirmed}
        data={selectedDelete}
      />
    </Paper>
  );
}
