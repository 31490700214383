import {BaseForm, name} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const ContactRoutineForm = forwardRef(({routine, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={{
        ...routine,
        ...(routine.contact ? {contact_id: routine.contact.id, contact_name: name(routine.contact)} : {})
      }}
      type="message"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={({routine_id, contact_id}) => services.contactRoutine.saveRoutine({routine_id, contact_id})}
      onSaved={onSaved}
    />
  );
});

export default ContactRoutineForm;
