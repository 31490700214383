import {DataTable, dateTime} from '@management-ui/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import CompanyForm from '../../forms/CompanyForm';

export default function Table() {
  const services = useContext(ServiceContext);
  const history = useHistory();
  /** @type {({current: DataTable})} */
  let tableRef = useRef();

  const goToDetail = useCallback((company) => {
    history.push(reverse(routes.companies.detail, {id: company.id}));
  }, [history]);

  return (
    <DataTable
      ref={tableRef}
      title={'Companies'}
      columns={[
        {title: 'Name', field: 'name'},
        {title: 'No. Associated Contacts', field: 'contacts'},
        {title: 'Date Added', field: 'created_at', render: data => dateTime(data.created_at)}
      ]}
      loadData={query => new Promise((resolve, reject) => {
        services.company.getTableCompanies(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={goToDetail}
      newForm={{
        title: 'Add New Company',
        render: (props) => <CompanyForm company={{}} {...props}/>,
        onSaved: goToDetail
      }}
    />
  );
}
