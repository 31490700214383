import {Panes, Select, TextField} from '@management-ui/core';
import {InputAdornment} from '@mui/material';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';

const Fields = ({offer = {}, prefix = '', multiplePanes = true, excludeProducts = [], children}) => {

  const products = useOptions('products', useCallback((list) => {
    return list.filter(p => p.id === offer.product_id || excludeProducts.indexOf(p.id) < 0).map(p => ({
      value: p.id,
      title: p.title
    }));
  }, [offer, excludeProducts]));

  return (
    <Panes
      entity={offer}
      prefix={prefix}
      panes={[
        {
          title: 'Offer Details',
          fields: [
            <Select
              name="product_id"
              prefix={prefix}
              options={products}
              label="Product"
              rules={{required: 'Please select a product'}}
            />,
            <TextField
              name="trial"
              prefix={prefix}
              label="Trial Period (Months)"
            />,
            <TextField
              name="price"
              prefix={prefix}
              label="Monthly Price"
              fieldProps={{
                InputProps: {
                  startAdornment: <InputAdornment position="start">£</InputAdornment>
                }
              }}
            />
          ]
        }, {
          title: '',
          fields: []
        },
      ]}
      title={multiplePanes ? null : 'Offer'}
    >{children}</Panes>
  );
};

export default Fields;
