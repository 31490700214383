import {Box, Paper, Stack} from '@mui/material';
import {makeStyles} from '@mui/styles';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import CacheRoute, {CacheSwitch} from 'react-router-cache-route';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  tab: {
    textDecoration: 'none',

    '&.MuiPaper-root': {
      alignItems: 'center',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1),
      transition: '0.25s background-color, color ease-in-out',

      '&:hover': {
        background: theme.palette.grey['100'],
      },

      '& span': {
        marginLeft: theme.spacing(1),
      }
    },
  },

  current: {
    '&.MuiPaper-root': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,

      '&:hover': {
        background: theme.palette.primary.main,
      },
    }
  }
}));

/**
 * A component to display a tab list with a <CachedBlocks/>.
 *
 * @module RoutedTabs
 *
 * @param {IRoutedTab[]} tabs An array of tabs
 *
 * @example
 * <TabBar
 *   tabs={[
 *     {title: 'Tab A', link: '/tab-a', icon: RecentActorsIcon, render: () => <p>Tab A</p>},
 *     {title: 'Tab B', link: '/tab-b', icon: RecentActorsIcon, render: () => <p>Tab B</p>}
 *   ]}
 * />
 *
 */
const RoutedTabs = ({tabs}) => {
  const classes = useStyles();
  const [current, setCurrent] = useState('');

  return (
    <Stack direction="column">
      <Box display="flex" justifyContent="flex-end" width="100%" py={2}>
        {tabs.map(({title, icon: Icon, link}) => (
          <Paper
            className={[classes.tab, current === link ? classes.current : ''].join(' ')}
            key={link}
            component={Link}
            to={link}
          >
            <Icon/><span>{title}</span>
          </Paper>
        ))}
      </Box>
      <CacheSwitch>
        {tabs.map(({link, render}, index) => (
          <CacheRoute key={index} exact path={link} render={(props) => setCurrent(props.match.path) || render(props)}/>
        ))}
      </CacheSwitch>
    </Stack>
  );
};

RoutedTabs.propTypes = {
  tabs: PropTypes.array
};

export default RoutedTabs;
