import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import CRMFields from './CRMFields';
import IdentityFields from './IdentityFields';

const SettingsForm = forwardRef(({system, settings, onSaved, onError}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={settings}
      type="settings"
      fieldsComponent={(props) => {
        switch (system) {
          case 'crm':
            return <CRMFields {...props} />;
          case 'id':
            return <IdentityFields {...props} />;
          default:
            return null;
        }
      }}
      onSave={(update) => services.settings[system].saveSettings(update).catch(error => {
        onError(error);
        return Promise.reject(error);
      })}
      onSaved={onSaved}
    />
  );
});

export default SettingsForm;
