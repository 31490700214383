import {name, Panes, RelationAutocomplete, Select} from '@management-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Fields = ({message = {}, prefix = ''}) => {
  const services = useContext(ServiceContext);
  const [routines, setRoutines] = useState([]);

  useEffect(() => {
    services.routine.getRoutines({}, 1, 50).then(({data}) => setRoutines(data.map(({id, title}) => ({
      title,
      value: id
    })))).catch(() => null);
  }, [services]);

  return (
    <Panes
      entity={message}
      prefix={prefix}
      panes={[
        {
          title: 'Details',
          fields: [
            <RelationAutocomplete
              valueName="contact_id"
              titleName="contact_name"
              prefix={prefix}
              label="Contact"
              loadOptions={query => (
                services.contact.getContacts({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(contact => ({title: name(contact), value: contact.id})))
                  .catch(() => [])
              )}
              rules={{required: 'Please select a contact'}}
            />,
            <Select
              name="routine_id"
              prefix={prefix}
              options={routines}
              label="Routine"
              rules={{required: 'Please select a routine'}}
            />
          ]
        }
      ]}
      title=""/>
  );
};

export default Fields;
