import {ConfirmationDialog, DetailPane, FormDialog} from '@management-ui/core';
import AddIcon from '@mui/icons-material/Add';
import {Link} from '@mui/material';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import AssociateLicenceTypeForm from '../../forms/AssociateLicenceTypeForm';

const Associates = ({type, onLoading, onTypeUpdated}) => {
  const services = useContext(ServiceContext);
  const [associates, setAssociates] = useState([]);
  const [selected, setSelected] = useState(null);
  const [showNew, setShowNew] = useState(false);
  const [showRemove, setShowRemove] = useState(false);

  useEffect(() => {
    setAssociates(type['associates']);
  }, [type]);

  const handleSaved = useCallback((updated) => {
    setShowNew(false);
    onTypeUpdated(updated);
    setSelected(null);
  }, [onTypeUpdated]);

  const handleRemove = useCallback(confirmed => {
    setShowRemove(false)
    if (confirmed) {
      onLoading(true);
      services.licenceType.associate(type, selected.id, selected.quantity).then((updated) => {
        onLoading(false);
        handleSaved(updated);
      }).catch(() => onLoading(false));
    }
  }, [services, type, onLoading, selected, handleSaved])

  return (
    <DetailPane
      title="Associations"
      details={associates.map(association => ({
        title: '',
        value: (
          <Link
            onClick={() => setSelected(association) || setShowRemove(true)}
            color="secondary"
            underline="hover"
          >{association.name} x {association.quantity}</Link>
        )
      }))}
      actions={[
        {title: 'Add Association', icon: <AddIcon/>, onClick: () => setShowNew(true)},
      ]}
      dialogs={[
        (props) => (
          <FormDialog
            {...props}
            title="Add Association"
            open={showNew}
            onClose={() => setShowNew(false)}
            maxWidth="sm"
            render={(props) => (
              <AssociateLicenceTypeForm
                {...props}
                association={{}}
                type={type}
                existing={associates}
                onSaved={handleSaved}/>
            )}
          />
        ),
        (props) => (
          <ConfirmationDialog
            {...props}
            open={showRemove}
            title="Remove Association"
            message={selected ? `Are you sure you want to remove the association with ${selected.name}?` : ''}
            onClose={handleRemove}
          />
        )
      ]}
    />
  )
};

export default Associates;
