import {DataTable, dateTime} from '@management-ui/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import LicenceTypeForm from '../../forms/LicenceTypeForm';

const LicenceTypes = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((type) => {
    history.push(reverse(routes.licencing.licenceTypes.detail, {id: type.id}));
  }, [history]);

  return (
    <DataTable
      title="Licence Types"
      options={{search: false, sorting: false, draggable: false}}
      columns={[
        {title: 'Name', field: 'name'},
        {title: 'Code', field: 'code'},
        {title: 'Date Created', field: 'created_at', render: data => dateTime(data.created_at)},
        {title: 'Date Modified', field: 'updated_at', render: data => dateTime(data.updated_at)}
      ]}
      loadData={query => new Promise((resolve, reject) => {
        services.licenceType.getTableTypes(query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(reject);
      })}
      onRowClick={goToDetail}
      newForm={{
        title: 'Add New Type',
        render: (props) => <LicenceTypeForm type={{}} {...props}/>,
        onSaved: goToDetail
      }}
    />
  );
};

export default LicenceTypes;
