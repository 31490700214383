import {Panes, RelationAutocomplete} from '@management-ui/core';
import React, {useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';

export default function IdentityFields({settings = {}, prefix = '', multiplePanes = true, children}) {
  const services = useContext(ServiceContext);

  return (
    <Panes
      entity={settings}
      prefix={prefix}
      panes={[
        {
          title: 'Message Settings',
          fields: [
            <RelationAutocomplete
              valueName="resetTemplateAlias"
              titleName="resetTemplateTitle"
              prefix={prefix}
              label="Select a Reset Password Template"
              loadOptions={query => (
                services.template.getTemplates({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(template => ({
                    title: template.title,
                    value: template.alias
                  })))
                  .catch(() => [])
              )}
            />,
            <RelationAutocomplete
              valueName="affiliateTemplateAlias"
              titleName="affiliateTemplateTitle"
              prefix={prefix}
              label="Select a Affiliate Registration Received Template"
              loadOptions={query => (
                services.template.getTemplates({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(template => ({
                    title: template.title,
                    value: template.alias
                  })))
                  .catch(() => [])
              )}
            />,
            <RelationAutocomplete
              valueName="affiliateApprovedTemplateAlias"
              titleName="affiliateApprovedTemplateTitle"
              prefix={prefix}
              label="Select a Affiliate Approved Template"
              loadOptions={query => (
                services.template.getTemplates({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(template => ({
                    title: template.title,
                    value: template.alias
                  })))
                  .catch(() => [])
              )}
            />,
            <RelationAutocomplete
              valueName="affiliateRejectedTemplateAlias"
              titleName="affiliateRejectedTemplateTitle"
              prefix={prefix}
              label="Select a Affiliate Rejected Template"
              loadOptions={query => (
                services.template.getTemplates({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(template => ({
                    title: template.title,
                    value: template.alias
                  })))
                  .catch(() => [])
              )}
            />,
            <RelationAutocomplete
              valueName="paymentReceivedTemplateAlias"
              titleName="paymentReceivedTemplateTitle"
              prefix={prefix}
              label="Select a Payment Received Template"
              loadOptions={query => (
                services.template.getTemplates({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(template => ({
                    title: template.title,
                    value: template.alias
                  })))
                  .catch(() => [])
              )}
            />
          ]
        },
        {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Settings'}
    >{children}</Panes>
  );
}
