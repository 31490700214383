import React, {createContext} from 'react';
import {AccessKeyService} from '../services/AccessKeyService';
import {AffiliateRegistrationService} from '../services/AffiliateRegistrationService';
import {AuthService} from '../services/AuthService';
import {BookingService} from '../services/BookingService';
import {CategoryService} from '../services/CategoryService';
import {CompanyService} from '../services/CompanyService';
import {ContactRoutineService} from '../services/ContactRoutineService';
import {ContactService} from '../services/ContactService';
import {CountryService} from '../services/CountryService';
import {LicenceService} from '../services/LicenceService';
import {LicenceTypeService} from '../services/LicenceTypeService';
import {PaymentService} from '../services/PaymentService';
import {ProductService} from '../services/ProductService';
import {RoutineService} from '../services/RoutineService';
import {ServiceProvider} from '../services/ServiceProvider';
import {SettingsService} from '../services/SettingsService';
import {SourceService} from '../services/SourceService';
import {TemplateService} from '../services/TemplateService';
import {UserService} from '../services/UserService';

export const ServiceContext = createContext({})

export default function Services({children}) {
  const services = {
    accessKey: {
      crm: new AccessKeyService('crm'),
      id: new AccessKeyService('id')
    },
    affiliateRegistration: new AffiliateRegistrationService(),
    auth: new AuthService(),
    booking: new BookingService(),
    category: new CategoryService(),
    company: new CompanyService(),
    contact: new ContactService(),
    contactRoutine: new ContactRoutineService(),
    country: new CountryService(),
    licence: new LicenceService(),
    licenceType: new LicenceTypeService(),
    payment: new PaymentService(),
    product: new ProductService(),
    routine: new RoutineService(),
    settings: {
      crm: new SettingsService('crm'),
      id: new SettingsService('id')
    },
    source: new SourceService(),
    template: new TemplateService(),
    user: {
      crm: new UserService('crm'),
      id: new UserService('id')
    },
  };
  ServiceProvider.setServices(services);
  return (
    <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>
  );
}
