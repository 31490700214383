import {ServiceProvider} from '../../services/ServiceProvider';
import {
  SET_ADMINS,
  SET_CATEGORIES,
  SET_CONTACT_VARIABLES,
  SET_COUNTRIES,
  SET_CRM_ADMINS,
  SET_LICENCE_TYPES,
  SET_LOADING,
  SET_PRODUCTS,
  SET_SOURCES
} from '../reducers/options';

export function setOptionsLoading(loading) {
  return {
    type: SET_LOADING,
    loading
  };
}

function setCategories(categories) {
  return {
    type: SET_CATEGORIES,
    categories
  };
}

export function loadCategories() {
  return (dispatch) => {
    ServiceProvider.getServices().category
      .getCategories({}, 1, 500)
      .then((response) => {
        dispatch(setCategories(response.data));
      }).catch(() => {
    });
  }
}

export function clearCategories() {
  return (dispatch) => {
    dispatch(setCategories([]));
  }
}

function setContactVariables(variables) {
  return {
    type: SET_CONTACT_VARIABLES,
    variables
  };
}

export function loadContactVariables() {
  return (dispatch) => {
    ServiceProvider.getServices().contact
      .getVariables()
      .then((response) => {
        dispatch(setContactVariables(response));
      }).catch(() => {
    });
  }
}

function setCountries(countries) {
  return {
    type: SET_COUNTRIES,
    countries
  };
}

export function loadCountries() {
  return (dispatch) => {
    ServiceProvider.getServices().country
      .getCountries({}, 1, 500)
      .then((response) => {
        dispatch(setCountries(response.data));
      }).catch(() => {
    });
  }
}

export function clearCountries() {
  return (dispatch) => {
    dispatch(setCountries([]));
  }
}

function setSources(sources) {
  return {
    type: SET_SOURCES,
    sources
  };
}

export function loadSources() {
  return (dispatch) => {
    ServiceProvider.getServices().source
      .getSources({}, 1, 500)
      .then((response) => {
        dispatch(setSources(response.data));
      }).catch(() => {
    });
  }
}

export function clearSources() {
  return (dispatch) => {
    dispatch(setSources([]));
  }
}

function setAdmins(admins) {
  return {
    type: SET_ADMINS,
    admins
  };
}

export function loadAdmins() {
  return (dispatch) => {
    ServiceProvider.getServices().user.id
      .getUsers({filter: {is_active: 1, admins: 1}}, 1, 500)
      .then((response) => {
        dispatch(setAdmins(response.data));
      }).catch(() => {
    });
  }
}

export function clearAdmins() {
  return (dispatch) => {
    dispatch(setAdmins([]));
  }
}

function setCRMAdmins(crmAdmins) {
  return {
    type: SET_CRM_ADMINS,
    crmAdmins
  };
}

export function loadCRMAdmins() {
  return (dispatch) => {
    ServiceProvider.getServices().user.crm
      .getUsers({}, 1, 500)
      .then((response) => {
        dispatch(setCRMAdmins(response.data));
      }).catch(() => {
    });
  }
}

export function clearCRMAdmins() {
  return (dispatch) => {
    dispatch(setCRMAdmins([]));
  }
}

function setLicenceTypes(types) {
  return {
    type: SET_LICENCE_TYPES,
    types
  };
}

export function loadLicenceTypes() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().licenceType
      .getTypes()
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setLicenceTypes(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearLicenceTypes() {
  return (dispatch) => {
    dispatch(setLicenceTypes([]));
  }
}

function setProducts(products) {
  return {
    type: SET_PRODUCTS,
    products
  };
}

export function loadProducts() {
  return (dispatch) => {
    dispatch(setOptionsLoading(true));
    ServiceProvider.getServices().product
      .getProducts({}, 1, 500)
      .then((response) => {
        dispatch(setOptionsLoading(false));
        dispatch(setProducts(response.data));
      }).catch(() => dispatch(setOptionsLoading(false)));
  }
}

export function clearProducts() {
  return (dispatch) => {
    dispatch(setProducts([]));
  }
}
