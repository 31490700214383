import {ErrorPopup, SquareButton, useErrors, useFormStyles} from '@management-ui/core';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {CircularProgress, Fab} from '@mui/material';
import {makeStyles} from '@mui/styles';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Variable from './Variable';

const EMPTY = {id: 0, title: '', key: '', description: '', default_value: '', errors: []};

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
    width: '100%',

    '&:before': {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      content: '\' \'',
      left: 0,
      margin: '0 auto',
      position: 'absolute',
      right: 0,
      top: 0,
      width: 4,
    }
  },
  variable: {
    backgroundColor: theme.palette.background.default,
    borderBottom: `4px solid ${theme.palette.background.default}`,
    flex: '0 0 50%',
    width: '50%',
  },
  variableInner: {
    alignItems: 'flex-end',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  addNew: {
    alignItems: 'center',
    border: 0,
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  floatingButtonIcon: {
    marginRight: theme.spacing(1),
  },
}));

const TemplateVariablesForm = forwardRef(({template, onSaved}, ref) => {
  const classes = useStyles();
  const formClasses = useFormStyles();
  const services = useContext(ServiceContext);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    variables: 'There was a problem saving the variables'
  }), []));

  const [loading, setLoading] = useState(false);
  const [variables, setVariables] = useState([]);

  useEffect(() => {
    if (template.variables.length) {
      setVariables(template.variables.map((variable) => ({...variable})));
    } else {
      setVariables([{...EMPTY}]);
    }
  }, [template]);

  const handleNew = useCallback(() => {
    const updated = [...variables];
    updated.push({...EMPTY});
    setVariables(updated);
  }, [variables]);

  const handleUpdate = useCallback((index, field, value) => {
    const updated = [...variables];
    updated[index][field] = value;
    setVariables(updated);
  }, [variables]);

  const handleDelete = useCallback((index) => {
    const updated = [...variables];
    updated.splice(index, 1);
    if (updated.length < 1) {
      updated.push({...EMPTY});
    }
    setVariables(updated);
  }, [variables]);

  const handleSave = useCallback(async () => {
    let valid = true;
    const updated = [...variables]
    const save = [];
    let index = 0;
    for (let {id, title, key, description, default_value} of updated) {
      const errors = {};
      if (!title) {
        errors.title = 'Please enter a title';
      }
      if (!key) {
        errors.key = 'Please enter a key';
      }
      if (Object.keys(errors).length > 0) {
        valid = false;
        updated[index].errors = errors;
      } else {
        save.push({id, title, key, description, default_value});
      }
      index++;
    }
    if (!valid && updated.length === 1 && !updated[0].title && !updated[0].key && !updated[0].description && !updated[0].default_value) {
      valid = true;
    } else {
      setVariables(updated);
    }
    if (valid) {
      setLoading(true);
      services.template.saveTemplate({id: template.id, variables: save})
        .then(saved => {
          setLoading(false);
          onSaved(saved);
        })
        .catch(() => {
          setLoading(false);
          toggleError('variables', true);
        });
    }
  }, [services, toggleError, template, variables, onSaved]);

  useImperativeHandle(ref, () => ({
    save() {
      handleSave().then(() => null).catch(() => null);
    }
  }));

  // noinspection JSValidateTypes
  return (
    <div className={formClasses.form}>
      {loading ? <div className={formClasses.loading}><CircularProgress/></div> : null}
      <div className={classes.container}>
        {variables.map((variable, index) => (
          <div className={classes.variable} key={index}>
            <div className={classes.variableInner}>
              <Variable
                index={index}
                variable={variable}
                onUpdate={handleUpdate}
                errors={{}}
              />
              <SquareButton
                tooltip="Delete Variable"
                icon={<DeleteIcon/>}
                onClick={() => handleDelete(index)}
              />
            </div>
          </div>
        ))}
        <div className={[classes.variable, classes.addNew].join(' ')}>
          <Fab color="primary" variant="extended" onClick={handleNew}>
            <AddIcon className={classes.floatingButtonIcon}/>
            Add New Variable
          </Fab>
        </div>
      </div>
      <ErrorPopup errors={['variables']} messages={errors}/>
    </div>
  );
});

export default TemplateVariablesForm;
