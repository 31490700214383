import React from 'react';
import ContactFields from '../ContactForm/Fields';
import LocationFields from '../ContactLocationForm/Fields';

export default function Fields({contact}) {
  return (
    <>
      <ContactFields
        contact={contact}
        prefix="contact"
        multiplePanes={false}/>
      <LocationFields
        contact={contact}
        prefix="location"
        multiplePanes={false}/>
    </>
  );
}
