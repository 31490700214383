import {prefixWithSeparator, Spinner, SquareButton, useFormStyles} from '@management-ui/core';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  ClickAwayListener,
  InputAdornment,
  Link,
  List,
  ListItem,
  Paper,
  Popper,
  TextField as MatTextField,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {BehaviorSubject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ServiceContext} from '../components/MapServices';

/**
 * A text field to search for and populate addresses for use within the `BaseForm` component. In order for this
 * component to work make sure you have loaded the Google Maps JS SDK with the places library
 * (`<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=API_KEY&libraries=places"></script>`).
 *
 * @module AddressAutocomplete
 *
 * @param {string} name The name for field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {string|null} id The ID of the field
 * @param {boolean} separateNumber A flag to mark whether the address number is separate from line 1
 * @param {object} fieldProps Any additional props for the Material UI [TextField](https://material-ui.com/api/text-field/)
 *
 * @example
 * <AddressAutocomplete
 *   name="address"
 *   prefix={"prefix}
 *   label="Address Lookup"
 *   id="address"
 *   separateNumber={false}
 *   fieldProps={{}}
 * />
 *
 */
const AddressAutocomplete = (
  {
    name,
    prefix = '',
    label,
    id = null,
    separateNumber = false,
    fieldProps = {},
  }
) => {
  const classes = useFormStyles();
  const services = useContext(ServiceContext);
  const {formState: {errors}, setValue} = useFormContext();
  const fullName = `${prefixWithSeparator(prefix)}${name}`

  const [loading, setLoading] = useState(false);
  const subject$ = useRef(new BehaviorSubject(''));
  const anchorRef = useRef();
  const [query, setQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const subscription = subject$.current
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        if (value.trim().length >= 3) {
          setLoading(true);
          services.google.autocomplete(value).then((places) => {
            setLoading(false);
            setOptions(places.map(p => ({id: p['place_id'], title: p.description})));
          }).catch(() => setLoading(false));
        }
      });
    return () => {
      subscription.unsubscribe();
    };
  }, [services]);

  useEffect(() => {
    if (!query || query.length < 3) {
      setOptions([]);
    }
    subject$.current.next(query ? query : '');
  }, [query]);

  useEffect(() => {
    setOpen(options.length > 0);
  }, [options]);

  const handleClear = useCallback(() => {
    setQuery('');
  }, []);

  const handleSelect = useCallback((place) => {
    setLoading(true);
    services.google.getPlace(place.id, separateNumber).then((details) => {
      setLoading(false);
      for (let key of Object.keys(details)) {
        setValue(`${prefixWithSeparator(prefix)}${key}`, details[key]);
      }
      handleClear();
    }).catch(() => setLoading(false));
  }, [services, setValue, prefix, handleClear, separateNumber]);

  let results = null;
  if (options && options.length > 0) {
    results = (
      <List>
        {options.map((option, index) => (
          <ListItem
            key={index}
            button
            component={Link}
            onClick={() => handleSelect(option)}>
            {option.title}
          </ListItem>
        ))}
      </List>
    );
  }

  return (
    <>
      <MatTextField
        {...fieldProps}
        value={`${query ?? ''}`}
        onChange={(e) => setQuery(e.target.value)}
        fullWidth
        error={!!errors[fullName]}
        helperText={!!errors[fullName] ? errors[fullName].message : ''}
        variant="outlined"
        margin="normal"
        id={id ?? fullName}
        label={label}
        ref={anchorRef}
        autoComplete="off"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading ? (
                <Spinner/>
              ) : query ? (
                <SquareButton
                  tooltip="Clear Search"
                  icon={<ClearIcon/>}
                  onClick={handleClear}
                />
              ) : (
                <SquareButton
                  tooltip="Search"
                  icon={<SearchIcon/>}
                  onClick={() => {
                  }}
                  disabled={true}
                />
              )}
            </InputAdornment>
          ),
          fullWidth: true,
        }}
      />
      <Popper
        open={open}
        className={classes.popper}
        anchorEl={anchorRef.current}
        placement="bottom-start"
      >
        <Paper>
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <>
              {results ? (
                results
              ) : (
                <Typography className={classes.noResults}>
                  Sorry no results were found for your query
                </Typography>
              )}
              <img
                className={classes.google}
                src="https://developers.google.com/maps/documentation/images/powered_by_google_on_white.png"
                alt="Powered by Google"
              />
            </>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  )
};

AddressAutocomplete.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  separateNumber: PropTypes.bool,
  fieldProps: PropTypes.object
};

export default AddressAutocomplete;
