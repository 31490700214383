import {Panes, TextField} from '@management-ui/core';
import React from 'react';

export default function Fields({token = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={token}
      prefix={prefix}
      panes={[
        {
          title: 'Link Details',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name for this link'}}
            />,
          ]
        },
        {
          title: '',
          fields: []
        }
      ]}
      title={multiplePanes ? null : 'Link Details'}
    >{children}</Panes>
  );
}
