import {dateTime, DetailColumns, DetailPane, FormDialog, useDetail, useDialogs, useErrors} from '@management-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import {lengthInDays, productType} from '../../../../formatters';
import ProductForm from '../../forms/ProductForm';
import Wrapper from './Wrapper';

const Product = () => {
  const services = useContext(ServiceContext);

  const {openDialogs, toggleDialog} = useDialogs(['edit']);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    product: 'Sorry the product could not be accessed'
  }), []));

  const {
    entity: product,
    setEntity: setProduct,
    loadEntity: loadProduct,
    crumbs,
    loading
  } = useDetail(
    services.product.getProduct,
    false,
    useCallback((product) => [{title: product.title}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => [], []),
    useCallback((displayError) => toggleError('product', displayError), [toggleError])
  );

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setProduct(updated);
    } else if (product?.id) {
      loadProduct(product.id);
    }
  }, [product, loadProduct, setProduct, toggleDialog]);

  return (
    <Wrapper title="Product" loading={loading} product={product} crumbs={crumbs} errors={errors}>
      {product?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Product Details"
              actions={[
                {title: 'Edit Product', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)}
              ]}
              details={[
                {title: 'Title', value: product.title},
                {title: 'Code', value: product.code},
                {title: 'Licence Type', value: product['licence_type'].name},
                {title: 'Product Type', value: productType(product.product_type)},
                {title: 'Unit Price', value: <Currency amount={product.unit_price}/>},
                {title: 'Length', value: lengthInDays(product.length)}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Product"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    render={(props) => (
                      <ProductForm
                        {...props}
                        product={product}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
              ]}
            />,
            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(product.created_at)},
                {title: 'Last Updated', value: dateTime(product.updated_at)}
              ]}
            />,
          ]}/>
        </>
      ) : null}
    </Wrapper>
  );
}

export default Product;
