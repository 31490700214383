import {fullDate} from '@management-ui/core';
import {makeStyles} from '@mui/styles';
import moment from 'moment';
import React, {useCallback, useEffect, useState} from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
    padding: 0,
  },

  ok: {
    color: theme.palette.warning.main,
  },

  ahead: {
    color: theme.palette.success.main,
  },

  behind: {
    color: theme.palette.error.main,
  },
}))

const OK = 0;
const BEHIND = 1;
const AHEAD = 2;

const FollowUp = ({date: inputDate}) => {
  const classes = useStyles();
  const [followUp, setFollowUp] = useState(null);

  useEffect(() => {
    const date = moment(inputDate).startOf('day');
    const today = moment().startOf('day');
    let title;
    let status = OK;
    if (date.isSame(today)) {
      title = 'Today';
    } else if (date.isSame(today.clone().add(1, 'day'))) {
      title = 'Tomorrow';
      status = AHEAD;
    } else if (date.isSame(today.clone().subtract(1, 'day'))) {
      title = 'Yesterday';
    } else if (date.isBefore(today)) {
      title = `${Math.abs(date.diff(today, 'day'))} days ago`;
      status = BEHIND;
    } else {
      title = `In ${date.diff(today, 'day')} days`;
      status = AHEAD;
    }
    setFollowUp({title, status, date});
  }, [inputDate]);

  const getClasses = useCallback((status) => {
    switch (status) {
      case BEHIND:
        return classes.behind;
      case AHEAD:
        return classes.ahead;
      case OK:
        return classes.ok;
      default:
        return '';
    }
  }, [classes]);

  return followUp ? (
    <p className={classes.container}>
      <span>{fullDate(followUp.date)}</span>
      <strong className={getClasses(followUp.status)}>{followUp.title}</strong>
    </p>
  ) : null;
};

export default FollowUp;
