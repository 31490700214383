import {Panes, RelationAutocomplete, TextField} from '@management-ui/core';
import React, {useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';

const Fields = ({message = {}, prefix = ''}) => {
  const services = useContext(ServiceContext);
  return (
    <Panes
      entity={message}
      prefix={prefix}
      panes={[
        {
          title: 'Details',
          fields: [
            <RelationAutocomplete
              valueName="template_id"
              titleName="template_title"
              prefix={prefix}
              label="Template"
              loadOptions={query => (
                services.template.getTemplates({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(template => ({title: template.title, value: template.id})))
                  .catch(() => [])
              )}
              rules={{required: 'Please select a template for this message'}}
            />,
            <TextField
              label="Delay (in days)"
              prefix={prefix}
              rules={{required: 'Please enter a delay of at least 1 day'}}
              name="delay"
            />
          ]
        }
      ]}
      title={null}/>
  );
};

export default Fields;
