import * as qs from 'qs';
import {API} from './API';

export class AffiliateRegistrationService {
  async getTableRegistrations(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getRegistrations(query, page, length);
  }

  async getRegistrations(query, page, length) {
    return (await API.getConnection('id'))
      .get('affiliate-registrations', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(API.handleError);
  }

  async approveRegistration(registration, notify = false) {
    return (await API.getConnection('id'))
      .put(`affiliate-registrations/${registration.id}/approve`, {notify})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async rejectRegistration(registration, notify = false) {
    return (await API.getConnection('id'))
      .put(`affiliate-registrations/${registration.id}/reject`, {notify})
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
