import * as qs from 'qs';
import {API} from './API';

export class CompanyService {
  async getTableCompanies(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getCompanies(query, page, length);
  }

  async getCompanies(query, page, length) {
    return (await API.getConnection())
      .get('companies', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async getCompany(id) {
    return (await API.getConnection())
      .get(`companies/${id}`)
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveCompany(data) {
    let method = 'post';
    let url = 'companies';
    if (data.id) {
      method = 'put';
      url = `companies/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
