import {DataTable, dateTime, SquareButton} from '@management-ui/core';
import {Receipt} from '@mui/icons-material';
import {Grid} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';

const Payments = ({user}) => {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Payments"
      columns={useMemo(() => [
        {title: 'Reference', field: 'reference'},
        {title: 'Amount', field: 'amount', render: data => <Currency amount={data.amount}/>, sorting: false},
        {title: 'Allocated', field: 'allocated', render: data => <Currency amount={data.allocated}/>, sorting: false},
        {
          title: 'Outstanding',
          field: 'outstanding',
          render: data => <Currency amount={data.outstanding}/>,
          sorting: false
        },
        {title: 'Date/Time', field: 'created_at', render: data => dateTime(data.created_at)},
        {
          title: 'Invoice',
          field: 'Invoice',
          sorting: false,
          width: 200,
          headerStyle: {
            textAlign: 'center',
          },
          cellStyle: {
            textAlign: 'center',
          },
          render: (item) => (
            <Grid container alignItems="center" justifyContent="center" onClick={(e) => e.stopPropagation()}>
              <SquareButton
                small={true}
                tooltip="Download Invoice"
                icon={<Receipt/>}
                onClick={() => services.payment.downloadInvoice(item)}
              />
            </Grid>
          )
        }
      ], [services])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.payment.getTablePayments(query, {user: user.id})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, user])}
    />
  );
};

export default Payments;
