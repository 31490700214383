import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const UserForm = forwardRef(({user, isAdmin, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={user}
      type="user"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={useCallback(
        (update) => services.user.id.saveUser({...update, is_admin: isAdmin}),
        [services, isAdmin]
      )}
      onSaved={onSaved}
    />
  );
});

export default UserForm;
