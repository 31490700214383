import {name, useDetail, useDialogs, useErrors} from '@management-ui/core';
import AddCommentIcon from '@mui/icons-material/AddComment';
import {Pagination} from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Actions from './Actions';
import Wrapper from './Wrapper';

export default function Activity({archive = false}) {
  const services = useContext(ServiceContext);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);

  const {errors, toggleError} = useErrors(useMemo(() => ({contact: 'Sorry the contact could not be accessed'}), []));

  const {
    entity: contact,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.contact.getContact,
    archive,
    useCallback((contact) => [
      {
        title: name(contact),
        link: reverse(archive ? routes.archive.contacts.detail : routes.contacts.detail, {id: contact.id})
      },
      {title: 'Activity'}
    ], [archive]),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('contact', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['note']);

  const Block = useCallback(({children}) => (
    <Paper display="flex" justifyContent="center" marginTop={2} padding={2} component={Box}>{children}</Paper>
  ), []);

  return (
    <Wrapper
      title="Contact Activity"
      loading={loading}
      titleControls={archive ? [] : [{icon: <AddCommentIcon/>, onClick: () => toggleDialog('note', true)}]}
      contact={contact}
      crumbs={crumbs}
      errors={errors}
    >
      {contact ? (
        <>
          <Block>
            <Pagination count={pages} page={page} onChange={(e, p) => setPage(p)}/>
          </Block>
          <Block>
            <Actions
              contact={contact}
              setLoading={setLoading}
              openDialogs={openDialogs}
              toggleDialog={toggleDialog}
              toggleError={toggleError}
              page={page}
              length={30}
              updatePageCount={setPages}
            />
          </Block>
          <Block>
            <Pagination count={pages} page={page} onChange={(e, p) => setPage(p)}/>
          </Block>
        </>
      ) : null}
    </Wrapper>
  );
}
