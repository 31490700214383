import {DataTable} from '@management-ui/core';
import {Alert, Snackbar} from '@mui/material';
import {makeStyles} from '@mui/styles';
import * as React from 'react';
import {useContext, useState} from 'react';
import {ServiceContext} from '../../../components/Services';
import AccessKeyForm from '../forms/AccessKeyForm';

const useStyles = makeStyles(theme => ({
  key: {
    fontFamily: theme.typography.fixedWidth
  }
}));

export default function AccessKeys({system}) {
  const classes = useStyles();
  const services = useContext(ServiceContext);

  const [createdOpen, setCreatedOpen] = useState(false);
  const [createdKey, setCreatedKey] = useState({});

  return (
    <>
      <DataTable
        title="Access Keys"
        columns={[
          {title: 'Title', field: 'title'},
          {title: 'Public', field: 'public'},
          {title: 'Active?', field: 'is_active', lookup: {true: 'Yes', false: 'No'}},
        ]}
        loadData={query => new Promise((resolve, reject) => {
          services.accessKey[system].getTableKeys(query)
            .then(response => {
              resolve({
                data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
              });
            }).catch(() => {
            reject();
          });
        })}
        newForm={{
          title: 'Add New Key',
          render: (props) => <AccessKeyForm system={system} keyPair={{}} {...props}/>,
          onSaved: (saved, refresh) => setCreatedKey(saved) || setCreatedOpen(true) || refresh()
        }}
        editForm={{
          render: (keyPair, props) => <AccessKeyForm system={system} keyPair={keyPair} {...props}/>
        }}
      />
      <Snackbar open={createdOpen} onClose={() => setCreatedOpen(false)}>
        <Alert onClose={() => setCreatedOpen(false)} severity="success">
          Your new key has been created, please be sure to copy your private key as it will not be displayed again:
          <br/>
          <p className={classes.key}>
            <strong>Title:</strong>&nbsp;&nbsp;&nbsp;&nbsp;{createdKey.title}<br/>
            <strong>Public:</strong>&nbsp;&nbsp;&nbsp;{createdKey.public}<br/>
            <strong>Private:</strong>&nbsp;&nbsp;{createdKey.private}
          </p>
        </Alert>
      </Snackbar>
    </>
  );
}
