import * as qs from 'qs';
import {API} from './API';

export class RoutineService {
  async getTableRoutines(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getRoutines(query, page, length);
  }

  async getRoutines(query, page, length) {
    return (await API.getConnection())
      .get('routines', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getRoutine(id, archive = false) {
    return (await API.getConnection())
      .get(`routines/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveRoutine(data) {
    let method = 'post';
    let url = 'routines';
    if (data.id) {
      method = 'put';
      url = `routines/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteRoutine(id) {
    return (await API.getConnection())
      .delete(`routines/${id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async restoreRoutine(id) {
    return (await API.getConnection())
      .put(`routines/${id}/restore`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveMessage(routine, data) {
    let method = 'post';
    let url = `routines/${routine.id}/messages`;
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteMessage(routine, id) {
    return (await API.getConnection())
      .delete(`routines/${routine.id}/messages/${id}`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }
}
