import {name, Panes, RelationAutocomplete, Select, TextField} from '@management-ui/core';
import * as validator from 'email-validator';
import React, {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import {resellerName} from '../../../../formatters';
import {useOptions} from '../../../../hooks';
import CompanyForm from '../CompanyForm';
import ContactForm from './index';

export default function Fields({contact = {}, prefix = '', multiplePanes = true, children}) {
  const services = useContext(ServiceContext);
  const agents = useOptions('admins', useCallback((list) => list.map(user => ({
    value: user.id,
    title: name(user)
  })), []));

  const handleReset = useCallback((subject) => {
    if (subject.company) {
      subject.company_id = subject.company.id;
      subject.company_name = subject.company.name;
      delete subject.company;
    }
    if (subject.reseller) {
      subject.reseller_id = subject.reseller.id;
      subject.reseller_name = resellerName(subject.reseller);
      delete subject.reseller;
    }
    if (subject.agent) {
      subject.agent_id = subject.agent.sync_id;
    }

    return subject;
  }, []);

  return (
    <Panes
      entity={contact}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Identification',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
            />,
            <TextField
              name="first_name"
              prefix={prefix}
              label="First Name"
              rules={{required: 'Please enter a first name'}}
            />,
            <TextField
              name="middle_name"
              prefix={prefix}
              label="Middle Name"
            />,
            <TextField
              name="last_name"
              prefix={prefix}
              label="Last Name"
              rules={{required: 'Please enter a last name'}}
            />,
            <RelationAutocomplete
              valueName="company_id"
              titleName="company_name"
              prefix={prefix}
              label="Company"
              loadOptions={query => (
                services.company.getCompanies({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(company => ({title: company.name, value: company.id})))
                  .catch(() => [])
              )}
              addForm={{
                title: 'Add New Company',
                onSaved: (company) => ({title: company.name, value: company.id}),
                render: (props) => (
                  <CompanyForm
                    {...props}
                    type="company"
                    company={{}}
                  />
                )
              }}
            />,
          ]
        },
        {
          title: 'Contact Details',
          fields: [
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
              rules={{
                required: 'Please enter an email address',
                validate: value => {
                  if (value) {
                    return validator.validate(value) ? true : 'Please enter a valid email address';
                  }
                  return true;
                }
              }}
              autoComplete="email"
            />,
            <TextField
              name="phone"
              prefix={prefix}
              label="Telephone Number"
            />,
            <TextField
              name="mobile"
              prefix={prefix}
              label="Mobile Number"
            />,
            <TextField
              name="website"
              prefix={prefix}
              label="Website Address"
            />,
            <RelationAutocomplete
              valueName="reseller_id"
              titleName="reseller_name"
              prefix={prefix}
              label="Reseller"
              loadOptions={query => (
                services.contact.getContacts({filter: {search: query}}, 1, 50)
                  .then(response => response.data.map(reseller => ({
                    title: resellerName(reseller),
                    value: reseller.id
                  })))
                  .catch(() => [])
              )}
              addForm={{
                title: 'Add New Reseller',
                onSaved: (reseller) => ({title: name(reseller), value: reseller.id}),
                render: (props) => (
                  <ContactForm
                    {...props}
                    type="contactReseller"
                    contact={{}}
                  />
                )
              }}
            />,
            <Select
              name="agent_id"
              prefix={prefix}
              options={agents}
              label="Agent"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Contact Details'}
    >{children}</Panes>
  );
}
