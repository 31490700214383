import {DataTable, dateTime} from '@management-ui/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import RoutineForm from '../../forms/RoutineForm';

const Table = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const goToDetail = useCallback((routine) => {
    history.push(reverse(`${archive ? routes.archive.routines.detail : routes.routines.detail}`, {id: routine.id}));
  }, [history, archive]);

  return (
    <DataTable
      title="Routines"
      columns={[
        {title: 'Title', field: 'title'},
        {title: 'Alias', field: 'alias'},
        {title: 'Licence Type', field: 'licence_type', render: data => data['licence_type'] ?? '-'},
        {title: 'Date Created', field: 'created_at', render: data => dateTime(data.created_at)},
        {title: 'Date Modified', field: 'updated_at', render: data => dateTime(data.updated_at)},
        ...(archive ? [{title: 'Archived', field: 'deleted_at', render: data => dateTime(data.deleted_at)}] : [])
      ]}
      actions={{
        before: [], after: archive ? [] : [
          {
            icon: 'check',
            tooltip: 'View Activated Routines',
            isFreeAction: true,
            onClick: () => history.push(routes.routines.activated.index)
          }
        ]
      }}
      loadData={query => new Promise((resolve, reject) => {
        services.routine.getTableRoutines(query, archive ? {archive: 'only'} : {})
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      })}
      onRowClick={goToDetail}
      newForm={archive ? null : {
        title: 'Add New Routine',
        render: (props) => <RoutineForm routine={{}} {...props}/>,
        onSaved: goToDetail
      }}
    />
  );
};

export default Table;
