import {
  ConfirmationDialog,
  dateTime,
  DetailColumns,
  DetailPane,
  FormDialog,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Box, Paper, Typography} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import RoutineForm from '../../forms/RoutineForm';
import Messages from './Messages';
import Wrapper from './Wrapper';

export default function Routine({history, archive = false}) {
  const services = useContext(ServiceContext);

  const {openDialogs, toggleDialog} = useDialogs(['edit']);

  const {errors, toggleError} = useErrors(useMemo(() => ({
    routine: 'Sorry the routine could not be accessed'
  }), []));

  const {
    entity: routine,
    setEntity: setRoutine,
    loadEntity: loadRoutine,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.routine.getRoutine,
    archive,
    useCallback((routine) => [{title: routine.title}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('routine', displayError), [toggleError])
  );

  const handleSaved = useCallback((updated) => {
    toggleDialog('edit', false);
    if (updated) {
      setRoutine(updated);
    } else if (routine?.id) {
      loadRoutine(routine.id);
    }
  }, [routine, loadRoutine, setRoutine, toggleDialog]);

  return (
    <Wrapper title="Routine" loading={loading} routine={routine} crumbs={crumbs} errors={errors}>
      {routine?.id ? (
        <>
          <DetailColumns columns={[

            <DetailPane
              title="Routine Details"
              actions={!routine.archived ? [
                {title: 'Edit Routine', icon: <EditIcon/>, onClick: () => toggleDialog('edit', true)},
                {title: 'Delete Routine', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : [
                {
                  title: 'Restore Routine', icon: <RestoreFromTrashIcon/>, onClick: () => {
                    setLoading(true);
                    services.routine.restoreRoutine(routine.id).then(() => {
                      setLoading(false);
                      history.push(reverse(`${routes.routines.detail}`, {id: routine.id}))
                    }).catch(() => setLoading(false));
                  }
                },
              ]}
              details={[
                {title: 'Title', value: routine.title},
                {title: 'Alias', value: routine.alias},
                {title: 'Licence Type', value: routine['licence_type'] ?? '-'}
              ]}
              dialogs={[
                (props) => (
                  <FormDialog
                    {...props}
                    title="Edit Routine"
                    open={openDialogs.edit ?? false}
                    onClose={() => toggleDialog('edit', false)}
                    maxWidth="sm"
                    render={(props) => (
                      <RoutineForm
                        {...props}
                        routine={routine}
                        onSaved={handleSaved}/>
                    )}
                  />
                ),
                (props) => (
                  <ConfirmationDialog
                    {...props}
                    open={openDialogs.delete ?? false}
                    title="Delete"
                    message={routine ? `Are you sure you want to delete ${routine.title}?` : ''}
                    onClose={confirmed => {
                      toggleDialog('delete', false);
                      if (confirmed) {
                        setLoading(true);
                        services.routine.deleteRoutine(routine.id).then(() => {
                          setLoading(false);
                          history.push(reverse(`${routes.routines.index}`))
                        }).catch(() => setLoading(false));
                      }
                    }}
                  />
                )
              ]}
            />,
            <DetailPane
              title="Admin Details"
              details={[
                {title: 'Created', value: dateTime(routine.created_at)},
                {title: 'Last Updated', value: dateTime(routine.updated_at)},
                ...(routine.archived ? [{title: 'Archived', value: dateTime(routine.deleted_at)}] : [])
              ]}
            />,
          ]}/>

          <Paper component={Box} marginTop={2} overflow="hidden">
            <Box paddingX={2} paddingTop={2}>
              <Typography variant="h6">Messages</Typography>
            </Box>
            <Messages routine={routine} onUpdateRoutine={setRoutine}/>
          </Paper>
        </>
      ) : null}
    </Wrapper>
  );
}
