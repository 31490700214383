import {DataTable, name} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';
import AffiliateRegistrationDialog from '../../components/AffiliateRegistrationDialog';

const Table = forwardRef(({mode = 'pending'}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const [loading, setLoading] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [selected, setSelected] = useState(null);

  useImperativeHandle(ref, () => ({
    refresh() {
      tableRef.current.refresh();
    }
  }));

  const handleSelect = useCallback((registration) => {
    setSelected(registration);
    setShowDialog(true);
  }, []);

  const handleAction = useCallback((action, registration, notify) => {
    setShowDialog(false);
    setLoading(true);
    services.affiliateRegistration[`${action}Registration`](registration, notify).then(() => {
      setLoading(false);
      tableRef.current.refresh();
    }).catch(() => setLoading(false));
  }, [services]);

  return (
    <>
      <DataTable
        title={mode === 'pending' ? 'Pending' : mode === 'approved' ? 'Approved' : 'Rejected'}
        ref={tableRef}
        tableProps={{isLoading: loading}}
        columns={useMemo(() => [
          {title: 'Name', field: 'first_name', render: data => name(data)},
          {title: 'Email', field: 'email'},
          {title: 'Company Name', field: 'company_name'}
        ], [])}
        loadData={useCallback(query => new Promise((resolve, reject) => {
          services.affiliateRegistration.getTableRegistrations(
            query,
            mode === 'pending' ? {pending: '1'} : mode === 'approved' ? {approved: '1'} : {rejected: '1'}
          ).then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
            reject();
          });
        }), [services, mode])}
        onRowClick={handleSelect}
      />
      {selected ? <AffiliateRegistrationDialog
        registration={selected}
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onApprove={(registration, notify) => handleAction('approve', registration, notify)}
        onReject={(registration, notify) => handleAction('reject', registration, notify)}
      /> : null}
    </>
  );
});

export default Table;
