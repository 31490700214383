import {Panes, Select, TextField} from '@management-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import {ServiceContext} from '../../../../components/Services';

export default function Fields({association = {}, type, existing, upgrade, prefix = ''}) {
  const services = useContext(ServiceContext);
  const [types, setTypes] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    services.licenceType.getTypes({}, 1, 50)
      .then(({data}) => {
        setTypes(
          data
            .filter(t => t.id !== type.id && existing.findIndex(e => e.id === t.id) < 0)
            .map(({name: title, id: value}) => ({title, value}))
        );
        setLoaded(true);
      })
      .catch(() => setLoaded(true));
  }, [services, type, existing]);

  return (
    <Panes
      entity={association}
      prefix={prefix}
      panes={[
        {
          title: 'Licence Type to Associate',
          fields: [
            <Select
              name="associate"
              prefix={prefix}
              options={types.length < 1 ? [{title: '', value: '0'}] : types}
              label={loaded ? (types.length > 0 ? 'Select a Licence Type' : 'No Types to Associate') : 'Loading...'}
              fieldProps={{disabled: types.length < 1}}
            />,
            ...(!upgrade ? [
              <TextField
                name="quantity"
                prefix={prefix}
                label="Quantity"
              />
            ] : [])
          ]
        }
      ]}
      title={''}
    />
  );
}
