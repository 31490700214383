import {Breadcrumbs, CachedTabs, useTab} from '@management-ui/core';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import * as React from 'react';
import routes from '../../../../routes';
import AccessKeys from '../../components/AccessKeys';
import Settings from '../../components/Settings';
import Users from './Users';

const TABS = [
  {
    id: 'settings',
    slug: 'settings',
    title: 'Settings',
    icon: <SettingsIcon/>,
    render: (props) => <Settings system="crm" {...props} />
  },
  {
    id: 'accessKeys',
    slug: 'access-keys',
    title: 'Access Keys',
    icon: <DeveloperBoardIcon/>,
    render: (props) => <AccessKeys system="crm" {...props} />
  },
  {
    id: 'users',
    slug: 'users',
    title: 'Users',
    icon: <PeopleIcon/>,
    render: (props) => <Users {...props} />
  },
];

export default function CRM() {
  const {tab, handleTab} = useTab(TABS, routes.crm.tab);
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'CRM'}, {title: TABS.find(t => t.id === tab).title}]}/>
      <CachedTabs tab={tab} onChange={handleTab} tabs={TABS}/>
    </div>
  );
}
