import {Panes, Select, TextField} from '@management-ui/core';
import {AddressAutocomplete} from '@management-ui/maps';
import React, {useCallback} from 'react';
import {useOptions} from '../../../../hooks';

export default function Fields({contact = {}, prefix = '', multiplePanes = true, children}) {
  const countries = useOptions('countries', useCallback((list) => list.map(country => ({
    value: country.id,
    title: `${country.name} (${country.code})`
  })), []));

  const handleReset = useCallback((subject) => {
    if (subject.country) {
      subject.country_id = subject.country.id;
    }
    return subject;
  }, []);

  return (
    <Panes
      entity={contact}
      prefix={prefix}
      onReset={handleReset}
      panes={[
        {
          title: 'Address',
          fields: [
            <AddressAutocomplete
              name="lookup"
              prefix={prefix}
              label="Address Lookup"
              separateNumber={false}
            />,
            <TextField
              name="address1"
              prefix={prefix}
              label="Address Line 1"
            />,
            <TextField
              name="address2"
              prefix={prefix}
              label="Address Line 2"
            />,
            <TextField
              name="address3"
              prefix={prefix}
              label="Address Line 3"
            />,
            <TextField
              name="town"
              prefix={prefix}
              label="Town / City"
            />,
            <TextField
              name="region"
              prefix={prefix}
              label="Region"
            />,
            <TextField
              name="postcode"
              prefix={prefix}
              label="Postcode"
            />,
            <Select
              name="country_id"
              prefix={prefix}
              options={countries}
              label="Country"
            />
          ]
        }
      ]}
      title={multiplePanes ? null : 'Address'}
    >{children}</Panes>
  );
}
