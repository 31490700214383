import React, {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import ActionHolder from '../../components/Actions';

export default function Actions(
  {
    contact,
    setLoading,
    openDialogs,
    toggleDialog,
    toggleError,
    page = 1,
    length = 5,
    updatePageCount
  }) {
  const services = useContext(ServiceContext);

  const loadActivity = useCallback((currentPage, selectedLength) => {
    return services.contact.getContactActivity(
      contact.id,
      contact.archived ? {filter: {archive: 'only'}} : {},
      currentPage,
      selectedLength
    );
  }, [services, contact]);

  const dismissAction = useCallback((action) => {
    return services.contact.dismissAction(action);
  }, [services]);

  return (
    <ActionHolder
      loadActivity={loadActivity}
      dismissAction={dismissAction}
      saveNote={(note, followUp, userID, currentPage, selectedLength) => services.contact.saveComment(contact.id, note, followUp, userID, currentPage, selectedLength)}
      setLoading={setLoading}
      openDialogs={openDialogs}
      toggleDialog={toggleDialog}
      toggleError={toggleError}
      page={page}
      length={length}
      updatePageCount={updatePageCount}
    />
  );
}
