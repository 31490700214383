import {prefixWithSeparator} from '@management-ui/core';
import {FormControl, FormHelperText, Typography} from '@mui/material';
import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {CirclePicker} from 'react-color';
import {Controller, useFormContext} from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  colours: {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
  },
  coloursLabel: {
    margin: `${theme.spacing(2)} 0`,
  }
}));

/**
 * A colour selection field from `react-color` for use within the `BaseForm` component.
 *
 * @module ColourSelector
 *
 * @param {string} name The name for field
 * @param {string} prefix The prefix applied to the form data
 * @param {string} label The label to display on the field
 * @param {?string} id The ID of the field
 * @param {object} rules The validation rules for the field
 * @param {object} fieldProps Any additional props for the Material UI [TextField](https://material-ui.com/api/text-field/)
 *
 * @example
 * <ColourSelector
 *   name="colour"
 *   prefix={prefix}
 *   label="Colour"
 *   id="colour"
 *   rules={{required: 'Please select a colour'}}
 * />
 *
 */
const ColourSelector = (
  {
    name,
    prefix = '',
    label,
    id = null,
    rules = {},
    fieldProps
  }
) => {
  const classes = useStyles();
  const {control, formState: {errors}, watch, setValue} = useFormContext();
  const prefixedName = `${prefixWithSeparator(prefix)}${name}`;
  // noinspection JSCheckFunctionSignatures
  const value = watch(prefixedName, null);
  const valueRef = useRef(null);

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (value !== valueRef.current) {
      valueRef.current = value;
      setSelected(value);
    }
  }, [value]);

  const handleSelect = useCallback((colour) => {
    setSelected(colour?.hex);
    setValue(prefixedName, colour?.hex);
  }, [setValue, prefixedName]);

  return (
    <FormControl
      variant="outlined"
      required={rules && !!rules.required}
      className={classes.colours}
      fullWidth>
      <Typography className={classes.coloursLabel} variant="body2">{label}</Typography>
      <Controller
        name={prefixedName}
        control={control}
        rules={rules}
        render={() => (
          <CirclePicker
            {...fieldProps}
            color={selected ?? '#fff'}
            onChange={handleSelect}
            width={378}
            id={id ?? prefixedName}
            colors={[
              '#2d2151',
              '#995b8e',
              '#2196f3',
              '#03a9f4',
              '#00bcd4',
              '#009688',
              '#4caf50',
              '#8bc34a',
              '#cddc39',
              '#ffeb3b',
              '#ffc107',
              '#ff9800',
              '#ff5722',
              '#f44336',
              '#e91e63',
              '#f089b1',
              '#795548',
              '#607d8b'
            ]}
          />
        )}
      />
      {!!errors[prefixedName] ? <FormHelperText>{errors[prefixedName].message}</FormHelperText> : null}
    </FormControl>
  );
};

ColourSelector.propTypes = {
  name: PropTypes.string,
  prefix: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  rules: PropTypes.object,
  fieldProps: PropTypes.object
};

export default ColourSelector;
