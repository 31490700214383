import {ColourSelector} from '@management-ui/colour';
import {Panes, TextField} from '@management-ui/core';
import React from 'react';

export default function Fields({source = {}, prefix = '', multiplePanes = true, children}) {
  return (
    <Panes
      entity={source}
      prefix={prefix}
      panes={[
        {
          title: 'Source Details',
          fields: [
            <TextField
              name="title"
              prefix={prefix}
              label="Title"
              rules={{required: 'Please enter a title'}}
            />,
            <TextField
              name="description"
              prefix={prefix}
              label="Description"
              multiline
              rows={4}
            />,
          ]
        },
        {
          title: 'Organisation',
          fields: [
            <ColourSelector
              name="colour"
              prefix={prefix}
              label="Colour"
              rules={{required: 'Please select a colour'}}
            />,
          ]
        }
      ]}
      title={multiplePanes ? null : 'Source Details'}
    >{children}</Panes>
  );
}
