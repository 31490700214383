import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useImperativeHandle, useRef} from 'react';
import Fields from './Fields';

const UserProductForm = forwardRef(({excludeProducts = [], index = -1, product, onSaved}, ref) => {
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={{...product, ...(product.product ? {product_id: product.product.id} : {quantity: 1})}}
      type="product"
      fieldsComponent={(props) => <Fields excludeProducts={excludeProducts} {...props} />}
      onSave={useCallback(({id, product_id, product_title, quantity, unit_price, sub_total, single}) => (
        new Promise(resolve => resolve({
          index,
          product: {
            id,
            product_id,
            product_title,
            quantity,
            unit_price,
            sub_total,
            single: !!single,
            accumulated: product.accumulated ?? 0
          }
        }))
      ), [index, product])}
      onSaved={onSaved}
    />
  );
});

export default UserProductForm;
