import {styled} from '@mui/material';
import React from 'react';

const Container = styled('div')(({theme}) => ({
  alignItems: 'center',
  borderBottom: `2px solid ${theme.palette.grey['100']}`,
  borderTop: `2px solid ${theme.palette.grey['100']}`,
  display: 'flex',
  flex: 1,
  padding: `0 ${theme.spacing(2)}`,
  width: '100%',

  '& > span': {
    flex: 0,
    paddingRight: theme.spacing(2),
  }
}));

const Types = styled('ul')(() => ({
  display: 'flex',
  flex: 1,
  listStyle: 'none',
  margin: 0,
  padding: 0,
}));

const Type = styled('li')(({theme}) => ({
  borderLeft: `2px solid ${theme.palette.grey['100']}`,
  color: 'var(--type-colour)',
  cursor: 'pointer',
  fontWeight: '700',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,

  '&:last-of-type': {
    borderRight: `2px solid ${theme.palette.grey['100']}`,
  },

  '&.selected': {
    background: 'var(--type-colour)',
    color: theme.palette.common.white,
  }
}));

const TYPES = [
  {title: 'All', key: 'regular', colour: '#2d2151'},
  {title: 'Direct', key: 'direct', colour: '#f089b1'},
  {title: 'Resellers', key: 'resellers', colour: '#985a8d'},
  {title: 'Affiliates', key: 'affiliates', colour: '#ff9c27'},
];

const Filter = ({type, onChange}) => {
  return (
    <Container>
      <span>Displaying:</span>
      <Types>
        {TYPES.map(({title, key, colour}, index) => (
          <Type
            key={index}
            className={type === key ? 'selected' : ''}
            style={{'--type-colour': colour}}
            onClick={() => type === key ? null : onChange(key)}
          >
            {title}
          </Type>
        ))}
      </Types>
    </Container>
  );
}

export default Filter;
