import {date, dateTime, DetailColumns, DetailPane, FormDialog, name} from '@management-ui/core';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import {useState} from 'react';
import UserForm from '../../forms/UserForm';

const Details = ({user, setUser}) => {
  const [showEdit, setShowEdit] = useState(false);
  return (
    <DetailColumns columns={[
      <DetailPane
        title="User Details"
        actions={[{title: 'Edit User', icon: <EditIcon/>, onClick: () => setShowEdit(true)}]}
        details={[
          {title: 'Name', value: name(user)},
          {title: 'Email', value: user.email},
          {title: 'Subscription', value: user.subscription ?? '-'},
          {title: 'Is active?', value: user.is_active ? 'Yes' : 'No'},
          {title: 'Is admin?', value: user.is_admin ? 'Yes' : 'No'},
          {title: 'Is reseller?', value: user.reseller_since ? `Since ${date(user.reseller_since)}` : 'No'},
          ...(user.reseller_since ? [{title: 'Space', value: user.space}] : []),
          {title: 'Is affiliate?', value: user.affiliate_since ? `Since ${date(user.affiliate_since)}` : 'No'},
        ]}
        dialogs={[
          (props) => (
            <FormDialog
              {...props}
              title="Edit User"
              open={showEdit}
              onClose={() => setShowEdit(false)}
              render={(props) => (
                <UserForm
                  {...props}
                  user={user}
                  onSaved={(saved) => setShowEdit(false) || setUser(saved)}/>
              )}
            />
          )
        ]}
      />,
      <DetailPane
        title="Admin Details"
        details={[
          {title: 'Created', value: dateTime(user.created_at)},
          {title: 'Last Updated', value: dateTime(user.updated_at)}
        ]}
      />,
    ]}/>
  );
}

export default Details;
