import moment from 'moment';
import {reset, setToken, setUser} from '../store/actions/auth';

const axios = require('axios').default;

export class API {

  static connection = {crm: null, id: null};

  static initialise(store) {
    API.store = store;
  }

  static setToken(token) {
    for (let type of Object.keys(API.connection)) {
      if (API.connection[type]) {
        API.connection[type].defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
    }
  }

  static refreshing = false;
  static onRefreshed = [];

  static async getConnection(type = 'crm') {
    return new Promise(async resolve => {
      if (!API.connection[type]) {
        API.connection[type] = axios.create({
          baseURL: `${process.env[`REACT_APP_${type.toUpperCase()}_API_URL`]}/api/`,
          timeout: 10000,
          responseType: 'json',
        });
        API.connection[type].interceptors.request.use(
          config => {
            const requiresToken = [
              'auth/login',
              'auth/refresh',
              'auth/forgot-password',
              'auth/reset-password',
            ].indexOf(config.url) < 0;
            return new Promise(resolve => {
              if (requiresToken) {
                if (API.refreshing) {
                  API.onRefreshed.push(() => API.configureRequest(config, resolve));
                } else {
                  API.configureRequest(config, resolve);
                }
              } else {
                resolve(config);
              }
            });
          },
          error => Promise.reject(error),
        );
      }
      resolve(API.connection[type]);
    });
  }

  static async configureRequest(config, resolve) {
    const done = async () => {
      if (API.refreshing) {
        API.refreshing = false;
        for (let onRefreshed of API.onRefreshed) {
          await onRefreshed().then(() => {
          }).catch(() => {
          });
        }
        API.onRefreshed = [];
      }
      const token = localStorage.getItem('token');
      config.headers.Authorization = `Bearer ${token}`;
      resolve(config);
    };
    const expires = localStorage.getItem('expires');
    if (expires) {
      if (moment(expires).subtract(2, 'minutes').isBefore()) {
        API.refreshing = true;
        API.connection.id
          .post('auth/refresh', {token: localStorage.getItem('refresh')})
          .then(response => {
            const {access_token: access, refresh_token: refresh, expires_in: expires} = response.data;
            API.store.dispatch(setToken(access, refresh, expires));
            done();
          })
          .catch(() => {
            API.store.dispatch(reset());
            done();
          });
      } else {
        await done();
      }
    } else {
      await done();
    }
  }

  static handleError(error) {
    if (error && error.response) {
      if (error.response.status === 403) {
        API.store.dispatch(setUser(null));
        localStorage.setItem('token', '');
      }
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error);
    }
  }

  static getTableParams(params, additional = {}) {
    const query = {};
    const filters = additional;
    if (params.search) {
      filters.search = params.search;
    }
    params.filters.forEach(filter => {
      filters[filter.column.field] = filter.value;
    });
    if (Object.keys(filters).length > 0) {
      query.filter = filters;
    }
    if (params.orderBy) {
      query.sort = `${params.orderDirection === 'desc' ? '-' : ''}${
        params.orderBy.field
      }`;
    }
    return [query, params.page + 1, params.pageSize];
  }
}
