import {date, dateTime, DetailColumns, DetailPane} from '@management-ui/core';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LinkIcon from '@mui/icons-material/Link';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import {Box, Button, Paper, Stack, Typography} from '@mui/material';
import * as React from 'react';
import {useCallback, useContext} from 'react';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import AffiliatePayments from './AffiliatePayments';
import Affiliations from './Affiliations';
import Offers from './Offers';
import Tokens from './Tokens';

const Affiliate = ({user, setUser, loading, setLoading}) => {
  const services = useContext(ServiceContext);

  const handleEnable = useCallback(() => {
    setLoading(true);
    services.user.id.enableAffiliate(user).then(updated => {
      setUser(updated);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [services, user, setUser, setLoading]);

  const handleDonation = useCallback((donor) => {
    setLoading(true);
    services.user.id.saveUser({id: user.id, affiliate_donor: donor}).then(updated => {
      setUser(updated);
      setLoading(false);
    }).catch(() => setLoading(false));
  }, [services, user, setUser, setLoading]);

  const handleStripeAccount = useCallback(() => {
    setLoading(true);
    services.user.id.updateStripeAccount(user, `${window.location.origin}${routes.users.verify}`)
      .then(response => {
        setLoading(false);
        window.location.href = response.redirect;
      })
      .catch(() => setLoading(false));
  }, [services, user, setLoading])

  const handleStripeLogin = useCallback(() => {
    setLoading(true);
    services.user.id.getStripeLogin(user, window.location.href)
      .then(response => {
        setLoading(false);
        window.location.href = response.redirect;
      })
      .catch(() => setLoading(false));
  }, [services, user, setLoading]);

  return (
    <>
      {user.affiliate_since ? (
        <>
          <DetailColumns columns={[
            <DetailPane
              title="Affiliate Details"
              actions={user.affiliate_donor ? [
                {title: 'Disable Donations', icon: <AttachMoneyIcon/>, onClick: () => handleDonation(false)}
              ] : [
                {title: 'Enable Donations', icon: <VolunteerActivismIcon/>, onClick: () => handleDonation(true)},
                ...(user.stripe_account ? [
                  {title: 'Log into Stripe Account', icon: <ExitToAppIcon/>, onClick: handleStripeLogin}
                ] : []),
                {title: 'Connect Stripe Account', icon: <LinkIcon/>, onClick: handleStripeAccount}
              ]}
              details={[
                {title: 'Affiliate Since', value: date(user.affiliate_since)},
                ...(user.affiliate_donor ? [
                  {title: 'Donations Enabled', value: 'Yes'},
                ] : [
                  {title: 'Stripe Account', value: user.stripe_account ?? '-'},
                  {title: 'Payouts Enabled', value: user.stripe_enabled_at ? dateTime(user.stripe_enabled_at) : '-'},
                ])
              ]}
            />,
            <DetailPane
              title="Affiliate Statistics"
              details={[]}
            />,
          ]}/>
          <Paper component={Box} marginTop={2}>
            <Tokens
              user={user}
              onUpdateUser={setUser}
              loading={loading}
            />
          </Paper>
          <Paper component={Box} marginTop={2}>
            <Offers
              user={user}
              onUpdateUser={setUser}
              loading={loading}
            />
          </Paper>
          <Paper component={Box} marginTop={2}>
            <AffiliatePayments
              user={user}
            />
          </Paper>
          <Paper component={Box} marginTop={2}>
            <Affiliations
              user={user}
            />
          </Paper>
        </>
      ) : (
        <Stack spacing={4} direction="column" alignItems="center" paddingTop={2}>
          <Typography variant="h6">
            This user is not an affiliate. Click the button below to enable this functionality on their account.
          </Typography>
          <Stack spacing={2} direction="row">
            <Button variant="contained" onClick={handleEnable}>Enable</Button>
          </Stack>
        </Stack>
      )}

    </>
  );
}

export default Affiliate;
