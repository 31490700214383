import {Panes, prefixWithSeparator, slugify, TextField} from '@management-ui/core';
import React, {useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';

const Fields = ({type = {}, prefix = '', multiplePanes = true, children}) => {
  const {/** @var {function(string): string} */watch, setValue} = useFormContext();
  const name = watch(useMemo(() => `${prefixWithSeparator(prefix)}name`, [prefix]));
  const codeField = useMemo(() => `${prefixWithSeparator(prefix)}code`, [prefix]);

  useEffect(() => {
    if (name && name !== type.name) {
      setValue(codeField, slugify(name ?? ''));
    }
  }, [type, name, codeField, setValue]);

  return (
    <Panes
      entity={type}
      prefix={prefix}
      panes={[
        {
          title: 'Licence Type',
          fields: [
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name for this type'}}
            />,
            <TextField
              name="code"
              prefix={prefix}
              label="Code"
              rules={{required: 'Please enter a unique code for this type'}}
            />,
          ]
        }, {
          title: '',
          fields: []
        },
      ]}
      title={multiplePanes ? null : 'Licence Type'}
    >{children}</Panes>
  );
};

export default Fields;
