import {Paper} from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import {useCallback, useRef} from 'react';
import Licences from '../Licencing/Licences';
import Charges from './Charges';
import Payments from './Payments';
import Products from './Products';

const Licencing = ({user, setUser}) => {
  /** @type {({current: DataTable})} */
  const chargesRef = useRef();
  /** @type {({current: Licences})} */
  const licencesRef = useRef();

  const handleProducts = useCallback((updated) => {
    setUser(updated);
    chargesRef.current.refresh();
    licencesRef.current.refresh();
  }, [setUser]);

  return (
    <>
      <Paper component={Box}>
        <Products
          user={user}
          onUpdateUser={handleProducts}
        />
      </Paper>

      <Paper marginTop={2} component={Box}>
        <Licences
          ref={licencesRef}
          title="Licences"
          user={user}
        />
      </Paper>

      <Paper marginTop={2} component={Box}>
        <Charges
          tableRef={chargesRef}
          user={user}
          onRefresh={() => chargesRef.current.refresh()}
          onRecalculated={() => licencesRef.current.refresh()}
        />
      </Paper>

      <Paper marginTop={2} component={Box}>
        <Payments
          user={user}
        />
      </Paper>
    </>
  );
}

export default Licencing;
