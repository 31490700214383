import {DataTable} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import UserForm from '../../forms/UserForm';
import Filter from './Filter';

const Table = ({isAdmin}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const loaded = useRef('');
  const [type, setType] = useState('regular');
  /** @type {({current: DataTable})} */
  const tableRef = useRef();

  useEffect(() => {
    const state = isAdmin ? 'admin' : 'regular';
    if (loaded.current !== state) {
      if (loaded.current !== '' && tableRef.current) {
        tableRef.current.refresh();
      }
      loaded.current = state;
    }
  }, [isAdmin]);

  const goToDetail = useCallback((user) => {
    let tab = 'detail';
    switch (type) {
      case 'resellers':
        tab = 'reseller';
        break;
      case 'affiliates':
        tab = 'affiliate';
        break;
      default:
        break;
    }
    history.push(reverse(routes.users[tab], {id: user.id}));
  }, [history, type]);

  const handleType = useCallback((changed) => {
    setType(changed);
    if (tableRef.current) {
      tableRef.current.refresh();
    }
  }, []);

  return (
    <DataTable
      ref={tableRef}
      title={isAdmin ? 'Administrators' : 'Users'}
      tableProps={{
        components: {
          Toolbar: (props) => (
            <>
              <MTableToolbar {...props}/>
              {!isAdmin ? <Filter type={type} onChange={handleType}/> : null}
            </>
          )
        }
      }}
      columns={[
        {title: 'Name', field: 'name', render: data => `${data.first_name} ${data.last_name}`},
        {title: 'Email', field: 'email'},
        {title: 'Active', field: 'is_active', lookup: {true: 'Yes', false: 'No'}},
      ]}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        const params = {};
        params[isAdmin ? 'admins' : type] = '1';
        services.user.id.getTableUsers(query, params)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, isAdmin, type])}
      onRowClick={isAdmin ? null : goToDetail}
      newForm={isAdmin || type === 'regular' ? {
        title: `Add New ${isAdmin ? 'Administrator' : 'User'}`,
        render: (props) => <UserForm user={{}} isAdmin={isAdmin} {...props}/>
      } : null}
      editForm={isAdmin ? {
        render: (user, props) => <UserForm user={user} isAdmin={isAdmin} {...props}/>
      } : null}
    />
  );
};

export default Table;
