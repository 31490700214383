import {BaseForm} from '@management-ui/core';
import moment from 'moment';
import * as React from 'react';
import {forwardRef, useContext, useImperativeHandle, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const BookingForm = forwardRef(({booking, onSave, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  return (
    <BaseForm
      ref={formRef}
      entity={booking}
      type="booking"
      fieldsComponent={Fields}
      onSave={({date, start_at, end_at, ...attrs}) => {
        let update = {
          ...attrs,
          date: moment(date).format('YYYY-MM-DD'),
          start_at: moment(start_at).format('HH:mm:ss'),
          end_at: moment(end_at).format('HH:mm:ss')
        };
        if (onSave) {
          update = onSave(update);
        }
        return services.booking.saveBooking(update);
      }}
      onSaved={onSaved}
    />
  );
});

export default BookingForm;
