import * as qs from 'qs';
import {clearCategories} from '../store/actions/options';
import {API} from './API';

export class CategoryService {
  async getTableCategories(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getCategories(query, page, length);
  }

  async getCategories(query, page, length) {
    return (await API.getConnection())
      .get('categories', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        return API.handleError(error);
      });
  }

  async saveCategory(data) {
    let method = 'post';
    let url = 'categories';
    if (data.id) {
      method = 'put';
      url = `categories/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => {
        API.store.dispatch(clearCategories());
        return response.data.data
      })
      .catch(error => API.handleError(error));
  }
}
