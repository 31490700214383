import {DataTable, dateTime} from '@management-ui/core';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';

const AffiliatePayments = ({user}) => {
  const services = useContext(ServiceContext);
  return (
    <DataTable
      title="Payments"
      columns={useMemo(() => [
        {title: 'Reference', field: 'reference'},
        {title: 'Amount', field: 'amount', render: data => <Currency amount={data.amount}/>, sorting: false},
        {title: 'Date/Time', field: 'created_at', render: data => dateTime(data.created_at)},
      ], [])}
      loadData={useCallback(query => new Promise((resolve, reject) => {
        services.user.id.getTableAffiliatePayments(user, query)
          .then(response => {
            resolve({
              data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
            });
          }).catch(() => {
          reject();
        });
      }), [services, user])}
    />
  );
};

export default AffiliatePayments;
